import { createContext, useContext, useState } from "react";

const MobileContext = createContext();

export function MobileProvider({ children }) {
  const [isMobile, setIsMobile] = useState(false);

  return (
    <MobileContext.Provider
      value={{
        isMobile,
        setIsMobile,
      }}
    >
      {children}
    </MobileContext.Provider>
  );
}

export function useMobile() {
  return useContext(MobileContext);
}
