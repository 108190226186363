import React, { useState } from "react";
import AboutCSS from "./About.module.css";
import { useDarkMode } from "contexts/DarkModeContext";

function About(props) {
  const { isDarkMode } = useDarkMode();
  const { info, header } = props;

  return (
    <div
      className={`${AboutCSS["about"]} ${
        isDarkMode ? AboutCSS.darkModeContainer : ""
      }`}
    >
      <>
        <div className={AboutCSS["about-header"]}>{header}</div>
        <div className={AboutCSS["about-bottom"]}>
          <div
            className={
              info.address1
                ? AboutCSS["about-info"]
                : AboutCSS["about-info-alt"]
            }
          >
            {info.address1 && (
              <div className={AboutCSS["about-address"]}>
                <p>{info.address1}</p>
                <p>{info.address2 && info.address2}</p>
                <p>
                  {info.city}, {info.state} {info.zip}
                </p>
                <p className={AboutCSS["about-break"]}>{info.country}</p>
                <p className={AboutCSS["about-break"]}>{info.phone}</p>
                <a
                  className={`${AboutCSS["about-link"]} ${
                    isDarkMode ? AboutCSS.darkModeContainer : ""
                  }`}
                  href={info.website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p>{info.website}</p>
                </a>
              </div>
            )}
            {info.sector && (
              <div className={AboutCSS["about-address"]}>
                <p className={AboutCSS["about-break"]}>Sector: {info.sector}</p>
                <p className={AboutCSS["about-break"]}>
                  Industry: {info.industry}
                </p>
                <p className={AboutCSS["about-break"]}>
                  Full Time Employees: {info.fullTimeEmployees}
                </p>
              </div>
            )}
          </div>

          <div className={AboutCSS["about-description"]}>
            <p>
              {info.longBusinessSummary
                ? info.longBusinessSummary
                : info.description}
            </p>
          </div>
        </div>
      </>
    </div>
  );
}

export default About;
