import React, { useEffect, useState } from "react";

function SummaryTable(props) {
  const [tableData, setTableData] = useState();
  const [frequency, setFrequency] = useState();

  useEffect(() => {
    if (props.type === "summary" && props.data) {
      let timeframe;
      const firstDataKey = Object.keys(props.data)[0];
      if (firstDataKey && props.data[firstDataKey]) {
        timeframe = Object.keys(props.data[firstDataKey])[0];
        setFrequency(timeframe);
      }
      const tableData = Object.keys(props.data).map((key) => ({
        metric: key,
        daily: props.data[key][timeframe],
        annualized: props.data[key].Annualized,
      }));
      setTableData(tableData);
    } else if (props.type === "weight") {
      setTableData(
        Object.keys(props.data).map((key) => ({
          asset: key,
          weight: props.data[key],
        }))
      );
    }
  }, [props]);

  return (
    <table>
      <thead>
        {props.type === "summary" && (
          <tr>
            <th></th>
            <th>{frequency}</th>
            <th>Annualized</th>
          </tr>
        )}
        {props.type === "weight" && (
          <tr>
            <th></th>
            <th>Optimal Weights</th>
          </tr>
        )}
      </thead>
      <tbody>
        {props.type === "summary" &&
          tableData &&
          tableData.map((row, index) => (
            <tr key={index}>
              <th>{row.metric}</th>
              <td>
                {row.metric === "Sharpe Ratio" ||
                row.metric === "Alpha" ||
                row.metric === "Beta"
                  ? row.daily.toFixed(2)
                  : `${(row.daily * 100).toFixed(2)}%`}
              </td>
              <td>
                {row.metric === "Sharpe Ratio" ||
                row.metric === "Alpha" ||
                row.metric === "Beta"
                  ? row.annualized.toFixed(2)
                  : `${(row.annualized * 100).toFixed(2)}%`}
              </td>
            </tr>
          ))}
        {props.type === "weight" &&
          tableData &&
          tableData.map((row, index) => (
            <tr key={index}>
              <th>{row.asset}</th>
              <td>{row.weight.toFixed(2)}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}

export default SummaryTable;
