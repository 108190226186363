import React, { useEffect, useRef } from "react";

import { useDarkMode } from "contexts/DarkModeContext";

import CommunitiesAlertMessageCSS from "./CommunitiesAlertMessage.module.css";

function CommunitiesAlertMessage(props) {
  const { isDarkMode } = useDarkMode();

  const { alertMessage, handleCloseAlert, handleMemberEvent } = props;
  const { firstname, action } = alertMessage;

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          handleCloseAlert();
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div
      className={`${CommunitiesAlertMessageCSS["communities-alert-message"]} ${
        isDarkMode ? CommunitiesAlertMessageCSS.darkModeContainer : ""
      }`}
      ref={wrapperRef}
    >
      <p>
        Are you sure you wish to {action} {firstname}?
      </p>
      <div className={CommunitiesAlertMessageCSS["button-container"]}>
        <button
          onClick={() => handleMemberEvent(alertMessage)}
          className={`${CommunitiesAlertMessageCSS["button-red"]} ${
            isDarkMode ? CommunitiesAlertMessageCSS.darkModeContainer : ""
          }`}
        >
          Yes
        </button>
        <button
          onClick={() => handleCloseAlert()}
          className={`${CommunitiesAlertMessageCSS["button-blue"]} ${
            isDarkMode ? CommunitiesAlertMessageCSS.darkModeContainer : ""
          }`}
        >
          No
        </button>
      </div>
    </div>
  );
}

export default CommunitiesAlertMessage;
