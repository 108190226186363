import "./App.css";
import React, { useEffect, useState } from "react";
import { AppUseProvider, useAppUse } from "contexts/AppUseContext";
import { DarkModeProvider, useDarkMode } from "contexts/DarkModeContext";
import { ColorPreferenceProvider } from "contexts/ColorPreferenceContext";
import { SubTabProvider } from "contexts/SubTabContext";
import { MobileProvider, useMobile } from "contexts/MobileContext";
import { Routes, Route, useNavigate } from "react-router-dom";
import { get } from "services/service";
import Navbar from "components/Navbar/Navbar";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
import Dashboard from "pages/Dashboard";

function App() {
  const { setIsMobile } = useMobile();
  const { setIsUsingApp } = useAppUse();
  const { setIsDarkMode, toggleDarkMode } = useDarkMode();

  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState("portfolio");

  const navigate = useNavigate();

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        // Window is visible, perform check
        loginCheck();
        setIsUsingApp(true);
      } else {
        setIsUsingApp(false);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginCheck = async () => {
    try {
      let response = await get("/users/login-test");
    } catch (err) {
      if (err.response.status === 440 || err.response.status === 400) {
        localStorage.clear();
        navigate("/");
        setIsDarkMode(false);
      }
    }
  };

  useEffect(() => {
    loginCheck(); // Initial check
    const intervalId = setInterval(loginCheck, 1000 * 60 * 15); // Check every 15 minutes
    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const click = (data) => {
    setOpen(data);
  };

  const clack = (data) => {
    setOpen(data);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
    };

    // Set initial isMobile value
    setIsMobile(window.innerWidth <= 480);

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setIsMobile]);

  const updateTab = (newTab) => {
    setTab(newTab);
  };

  return (
    <div className="App">
      <Navbar openlogin={click} tab={tab} />
      <Routes>
        <Route
          path="/"
          element={<Home openlogin={open} closelogin={clack} />}
        />
        {["/dashboard", "/dashboard/:tab"].map((path) => (
          <Route
            path={path}
            key={path}
            element={<Dashboard updateTab={updateTab} />}
          />
        ))}
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default function AppWrapper() {
  return (
    <AppUseProvider>
      <MobileProvider>
        <SubTabProvider>
          <DarkModeProvider>
            <ColorPreferenceProvider>
              <App />
            </ColorPreferenceProvider>
          </DarkModeProvider>
        </SubTabProvider>
      </MobileProvider>
    </AppUseProvider>
  );
}
