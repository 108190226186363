import React from "react";

import { useDarkMode } from "contexts/DarkModeContext";
import { useSubTab } from "contexts/SubTabContext";

import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Stack from "@mui/material/Stack";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import CircularProgress from "@mui/material/CircularProgress";

import AnalyticsCSS from "./Analytics.module.css";
import Searchbar from "components/ui/Searchbar";

function AnalyticsLeft(props) {
  const {
    fetchData,
    currentClick,
    current,
    verify,
    clearTickers,
    tickers,
    handleDelete,
    period,
    handlePeriodChange,
    periodOptions,
    frequency,
    frequencyOptions,
    handleFrequencyChange,
    handleShowFullText,
    showFullText,
    startDate,
    handleStartChange,
    endDate,
    handleEndChange,
    benchmark,
    handleBenchmark,
    rf,
    handleRf,
    error,
    model,
    handleModelChange,
    shorting,
    handleShorting,
    method,
    handleMethod,
    aversion,
    handleAversion,
    weightCheck,
    customWeights,
    handleWeightChange,
    loading,
    alert,
    isMobile,
    handleSubmitClick,
    general,
    portfolio,
  } = props;

  const { isDarkMode } = useDarkMode();
  const { subTab, setSubTab } = useSubTab();

  const formatOptionLabel = (option) => {
    switch (option) {
      case "1d":
        return "D";
      case "3mo":
        return "3M";
      case "6mo":
        return "6M";
      case "custom":
        return "Custom";
      default:
        return option.toUpperCase();
    }
  };

  const formatFrequencyLabel = (frequency) => {
    switch (frequency) {
      case "1d":
        return "Daily";
      case "1wk":
        return "Weekly";
      case "1mo":
        return "Monthly";
      default:
        return frequency.toUpperCase();
    }
  };

  return (
    <div className={AnalyticsCSS["analytics-left"]}>
      {!isMobile && (
        <h2
          className={`${AnalyticsCSS["tab-heading"]} ${
            isDarkMode ? AnalyticsCSS.darkModeContainer : ""
          }`}
        >
          Analytics
        </h2>
      )}
      <div className={AnalyticsCSS["analytics-tab-container"]}>
        <button
          onClick={(e) => {
            e.preventDefault();
            currentClick(e.target.value);
          }}
          className={
            current === "general"
              ? `${AnalyticsCSS["analytics-button-selected"]} ${
                  isDarkMode ? AnalyticsCSS.darkModeContainer : ""
                }`
              : AnalyticsCSS["analytics-button"]
          }
          value="general"
        >
          General
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            currentClick(e.target.value);
          }}
          className={
            current === "portfolio"
              ? `${AnalyticsCSS["analytics-button-selected"]} ${
                  isDarkMode ? AnalyticsCSS.darkModeContainer : ""
                }`
              : AnalyticsCSS["analytics-button"]
          }
          value="portfolio"
        >
          Portfolio
        </button>
        {/* <button
          onClick={(e) => {
            e.preventDefault();
            currentClick(e.target.value);
          }}
          className={
            current === "technical"
              ? `${AnalyticsCSS["analytics-button-selected"]} ${
                  isDarkMode ? AnalyticsCSS.darkModeContainer : ""
                }`
              : AnalyticsCSS["analytics-button"]
          }
          value="technical"
        >
          Technical
        </button> */}
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          fetchData(e);
          handleSubmitClick();
        }}
        className={`${AnalyticsCSS["analytics-form"]} ${
          isDarkMode ? AnalyticsCSS.darkModeContainer : ""
        }`}
      >
        <div className={AnalyticsCSS["form-search-container"]}>
          <Searchbar
            verify={verify}
            placeholderStats={false}
            placeholder={"Symbols"}
            SubmitOnEnter={false}
            disablePrice={true}
          />
          {/* <Button
            style={{
              textTransform: "none",
              backgroundColor: "transparent",
              width: "50px",
              fontFamily: "Roboto",
              fontSize: "14px",
            }}
            disableRipple={true}
            onClick={(e) => {
              e.preventDefault();
              clearTickers();
            }}
          >
            Clear
          </Button> */}
          {!loading &&
            isMobile &&
            ((general && current === "general") ||
              (portfolio && current === "portfolio")) && (
              <span
                className={`${AnalyticsCSS["more-button"]} ${
                  isDarkMode ? AnalyticsCSS.darkModeContainer : ""
                }`}
                onClick={() => {
                  if (
                    (general && current === "general") ||
                    (portfolio && current === "portfolio")
                  ) {
                    setSubTab(!subTab);
                  }
                }}
              >
                View Report
              </span>
            )}
        </div>
        <div className={AnalyticsCSS["chips-container"]}>
          {tickers.map((symbol, index) => (
            <Chip
              key={index}
              label={symbol}
              variant="outlined"
              onDelete={() => handleDelete(index)}
              sx={{
                color: isDarkMode ? "#F0F0F0" : "",
                bgcolor: isDarkMode ? "#272835" : "",
                "& .MuiChip-deleteIcon": {
                  color: isDarkMode ? "#9CA9B9" : "",
                },
                "& .MuiChip-deleteIcon:hover": {
                  color: isDarkMode ? "#C6CFDA" : "",
                },
                borderColor: isDarkMode ? "#9CA9B9" : "",
              }}
            />
          ))}
        </div>
        <div className={AnalyticsCSS["form-container-input"]}>
          <FormControl
            variant="standard"
            sx={{
              width: 90,
              "& .MuiInput-underline:before": {
                borderColor: isDarkMode ? "#9CA9B9" : "",
              },
              "& .MuiInput-underline:hover:before": {
                borderColor: isDarkMode ? "#F0F0F0 !important" : "",
              },
              "& .MuiInput-underline:hover:after": {
                borderColor: isDarkMode ? "#F0F0F0 !important" : "",
              },
              ".MuiSvgIcon-root": {
                fill: isDarkMode ? "#9CA9B9" : "",
              },
            }}
          >
            <InputLabel
              shrink
              sx={{
                display: "flex",
                margin: 0,
                padding: 0,
                color: isDarkMode ? "#9CA9B9" : "",
              }}
              id="demo-simple-select-label"
            >
              Period
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              value={period}
              label="Period"
              variant="standard"
              MenuProps={{
                MenuListProps: {
                  autoFocusItem: null,
                },
                PaperProps: {
                  sx: {
                    color: isDarkMode ? "#F0F0F0" : "",
                    bgcolor: isDarkMode ? "#272835" : "",
                    maxHeight: "30%", // Set the max height here
                    overflow: "auto", // Enable scrolling if the content exceeds the max height
                  },
                },
              }}
              sx={{ color: isDarkMode ? "#F0F0F0" : "" }}
              onClose={() => {
                setTimeout(() => {
                  document.activeElement.blur();
                }, 0);
              }}
              onChange={(e) => handlePeriodChange(e.target.value)}
            >
              {periodOptions.map((option) => (
                <MenuItem value={option} key={option} id="menu-item">
                  {formatOptionLabel(option)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            variant="standard"
            sx={{
              width: 90,
              "& .MuiInput-underline:before": {
                borderColor: isDarkMode ? "#9CA9B9" : "",
              },
              "& .MuiInput-underline:hover:before": {
                borderColor: isDarkMode ? "#F0F0F0 !important" : "",
              },
              "& .MuiInput-underline:hover:after": {
                borderColor: isDarkMode ? "#F0F0F0 !important" : "",
              },
              ".MuiSvgIcon-root": {
                fill: isDarkMode ? "#9CA9B9" : "",
              },
            }}
          >
            <InputLabel
              sx={{
                display: "flex",
                margin: 0,
                padding: 0,
                color: isDarkMode ? "#9CA9B9" : "",
              }}
              id="demo-simple-select-label"
            >
              Frequency
            </InputLabel>
            <Select
              className={AnalyticsCSS["test-input"]}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={frequency}
              label="Frequency"
              variant="standard"
              MenuProps={{
                MenuListProps: {
                  autoFocusItem: null,
                },
                PaperProps: {
                  sx: {
                    color: isDarkMode ? "#F0F0F0" : "",
                    bgcolor: isDarkMode ? "#272835" : "",
                  },
                },
              }}
              sx={{ color: isDarkMode ? "#F0F0F0" : "" }}
              onClose={() => {
                setTimeout(() => {
                  document.activeElement.blur();
                }, 0);
              }}
              onChange={(e) => handleFrequencyChange(e.target.value)}
            >
              {frequencyOptions.map((frequency) => (
                <MenuItem value={frequency} key={frequency} id="menu-item">
                  {formatFrequencyLabel(frequency)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {current === "portfolio" ? (
            <span
              className={`${AnalyticsCSS["more-button-2"]} ${
                isDarkMode ? AnalyticsCSS.darkModeContainer : ""
              }`}
              onClick={() => handleShowFullText(!showFullText)}
            >
              {!showFullText ? "More" : "Less"}
              {!showFullText ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </span>
          ) : (
            <div style={{ width: "64px" }}></div>
          )}
        </div>
        {period === "custom" && (
          <div className={AnalyticsCSS["form-container-input"]}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack direction="row" spacing={5.5} maxWidth={298}>
                <DesktopDatePicker
                  disableFuture
                  label="Start Date"
                  inputFormat="MM/DD/YYYY"
                  value={startDate}
                  onChange={handleStartChange}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      InputLabelProps={{
                        shrink: true,
                        sx: {
                          "&.Mui-focused": {
                            color: isDarkMode ? "#1976d2" : "", // Blue color when focused
                          },
                          color: isDarkMode ? "#9CA9B9" : "",
                        },
                      }}
                      sx={{
                        "& .MuiInput-underline:before": {
                          borderColor: isDarkMode ? "#9CA9B9" : "", // Input underline color
                        },
                        "& .MuiInput-underline:hover:before": {
                          borderColor: isDarkMode ? "#F0F0F0 !important" : "", // Underline color on hover
                        },
                        "& .MuiSvgIcon-root": {
                          fill: isDarkMode ? "#9CA9B9" : "", // Icon color
                        },
                      }}
                    />
                  )}
                  PopperProps={{
                    placement: "bottom-start",
                    sx: {
                      "& .MuiPaper-root": {
                        backgroundColor: isDarkMode ? "#272835" : "",
                        color: isDarkMode ? "#F0F0F0" : "",
                      },
                      "& .MuiSvgIcon-root": {
                        fill: isDarkMode ? "#9CA9B9" : "",
                      },
                      "& .MuiTypography-root": {
                        color: isDarkMode ? "#F0F0F0" : "",
                      },
                      "& .MuiPickersDay-root": {
                        color: isDarkMode ? "#272835" : "",
                        backgroundColor: isDarkMode ? "#9CA9B9" : "",
                      },
                      "& .MuiPickersDay-root:hover": {
                        backgroundColor: isDarkMode ? "#C6CFDA !important" : "",
                      },
                      "& .MuiPickersDay-today": {
                        borderColor: isDarkMode ? "transparent" : "",
                      },
                      "& .Mui-disabled": {
                        color: isDarkMode ? "#9CA9B9 !important" : "",
                        backgroundColor: isDarkMode ? "transparent" : "",
                      },
                    },
                  }}
                  inputProps={{
                    style: { color: isDarkMode ? "#F0F0F0" : "" }, // Text color inside the input field
                  }}
                />{" "}
                <DesktopDatePicker
                  disableFuture
                  minDate={startDate}
                  label="End Date"
                  inputFormat="MM/DD/YYYY"
                  value={endDate}
                  onChange={handleEndChange}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      InputLabelProps={{
                        shrink: true,
                        sx: {
                          "&.Mui-focused": {
                            color: isDarkMode ? "#1976d2" : "", // Blue color when focused
                          },
                          color: isDarkMode ? "#9CA9B9" : "",
                        },
                      }}
                      sx={{
                        "& .MuiInput-underline:before": {
                          borderColor: isDarkMode ? "#9CA9B9" : "", // Input underline color
                        },
                        "& .MuiInput-underline:hover:before": {
                          borderColor: isDarkMode ? "#F0F0F0 !important" : "", // Underline color on hover
                        },
                        "& .MuiSvgIcon-root": {
                          fill: isDarkMode ? "#9CA9B9" : "", // Icon color
                        },
                      }}
                    />
                  )}
                  PopperProps={{
                    placement: "bottom-start",
                    sx: {
                      "& .MuiPaper-root": {
                        backgroundColor: isDarkMode ? "#272835" : "",
                        color: isDarkMode ? "#F0F0F0" : "",
                      },
                      "& .MuiSvgIcon-root": {
                        fill: isDarkMode ? "#9CA9B9" : "",
                      },
                      "& .MuiTypography-root": {
                        color: isDarkMode ? "#F0F0F0" : "",
                      },
                      "& .MuiPickersDay-root": {
                        color: isDarkMode ? "#272835" : "",
                        backgroundColor: isDarkMode ? "#9CA9B9" : "",
                      },
                      "& .MuiPickersDay-root:hover": {
                        backgroundColor: isDarkMode ? "#C6CFDA !important" : "",
                      },
                      "& .MuiPickersDay-today": {
                        borderColor: isDarkMode ? "transparent" : "",
                      },
                      "& .Mui-disabled": {
                        color: isDarkMode ? "#9CA9B9 !important" : "",
                        backgroundColor: isDarkMode ? "transparent" : "",
                      },
                    },
                  }}
                  inputProps={{
                    style: { color: isDarkMode ? "#F0F0F0" : "" }, // Text color inside the input field
                  }}
                />
              </Stack>
            </LocalizationProvider>
          </div>
        )}
        {current === "portfolio" && showFullText === true && (
          <div className={AnalyticsCSS["expanded-options"]}>
            <div className={AnalyticsCSS["form-container-input"]}>
              {/* <FormControl
                variant="standard"
                sx={{
                  width: 105,
                  "& .MuiInput-underline:before": {
                    borderColor: isDarkMode ? "#9CA9B9 !important" : "",
                  },
                  "& .MuiInput-underline:hover:before": {
                    borderColor: isDarkMode ? "#F0F0F0 !important" : "",
                  },
                  "& .MuiInput-underline:hover:after": {
                    borderColor: isDarkMode ? "#F0F0F0 !important" : "",
                  },
                  ".MuiSvgIcon-root": {
                    fill: isDarkMode ? "#9CA9B9" : "",
                  },
                }}
              >
                <InputLabel
                  sx={{
                    display: "flex",
                    margin: 0,
                    padding: 0,
                    color: isDarkMode ? "#9CA9B9" : "",
                  }}
                  id="demo-simple-select-label"
                >
                  Benchmark
                </InputLabel>
                <Select
                  className={AnalyticsCSS["test-input"]}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={benchmark}
                  label="Benchmark"
                  variant="standard"
                  MenuProps={{
                    MenuListProps: {
                      autoFocusItem: null,
                    },
                    PaperProps: {
                      sx: {
                        color: isDarkMode ? "#F0F0F0" : "",
                        bgcolor: isDarkMode ? "#272835" : "",
                      },
                    },
                  }}
                  sx={{ color: isDarkMode ? "#F0F0F0" : "" }}
                  onClose={() => {
                    setTimeout(() => {
                      document.activeElement.blur();
                    }, 0);
                  }}
                  onChange={(e) => handleBenchmark(e.target.value)}
                >
                  <MenuItem value={"^GSPC"} id="menu-item">
                    S&P 500
                  </MenuItem>
                  <MenuItem value={"^DJI"} id="menu-item">
                    Dow Jones
                  </MenuItem>
                  <MenuItem value={"^IXIC"} id="menu-item">
                    Nasdaq
                  </MenuItem>
                </Select>
              </FormControl> */}
              <FormControl
                variant="standard"
                sx={{
                  width: 180,
                  "& .MuiInput-underline:before": {
                    borderColor: isDarkMode ? "#9CA9B9 !important" : "",
                  },
                  "& .MuiInput-underline:hover:before": {
                    borderColor: isDarkMode ? "#F0F0F0 !important" : "",
                  },
                  "& .MuiInput-underline:hover:after": {
                    borderColor: isDarkMode ? "#F0F0F0 !important" : "",
                  },
                  ".MuiSvgIcon-root": {
                    fill: isDarkMode ? "#9CA9B9" : "",
                  },
                }}
              >
                <InputLabel
                  sx={{
                    display: "flex",
                    margin: 0,
                    padding: 0,
                    color: isDarkMode ? "#9CA9B9" : "",
                  }}
                  id="demo-simple-select-label"
                >
                  Risk-Free Rate
                </InputLabel>
                <Select
                  className={AnalyticsCSS["test-input"]}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={rf}
                  label="Risk-Free Rate"
                  variant="standard"
                  MenuProps={{
                    MenuListProps: {
                      autoFocusItem: null,
                    },
                    PaperProps: {
                      sx: {
                        color: isDarkMode ? "#F0F0F0" : "",
                        bgcolor: isDarkMode ? "#272835" : "",
                      },
                    },
                  }}
                  sx={{ color: isDarkMode ? "#F0F0F0" : "" }}
                  onClose={() => {
                    setTimeout(() => {
                      document.activeElement.blur();
                    }, 0);
                  }}
                  onChange={(e) => handleRf(e.target.value)}
                >
                  <MenuItem value={"^IRX"} id="menu-item">
                    13 Week Treasury Bill
                  </MenuItem>
                  <MenuItem value={"^FVX"} id="menu-item">
                    Treasury Yield 5 Years
                  </MenuItem>
                  <MenuItem value={"^TNX"} id="menu-item">
                    Treasury Yield 10 Years
                  </MenuItem>
                  <MenuItem value={"^TYX"} id="menu-item">
                    Treasury Yield 30 Years
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className={AnalyticsCSS["form-container-input"]}>
              <FormControl
                error={error}
                component="fieldset"
                sx={{ marginTop: 1 }}
                variant="standard"
              >
                <FormLabel
                  component="legend"
                  sx={{
                    color: isDarkMode ? "#9CA9B9" : "#606060",
                    "&.Mui-focused": {
                      color: !error ? (isDarkMode ? "#9CA9B9" : "#606060") : "",
                    },
                  }}
                >
                  Performance Metrics
                </FormLabel>
                <FormGroup
                  sx={{
                    color: isDarkMode ? "#F0F0F0" : "",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={model.mpt}
                        disableRipple
                        onChange={(e) => handleModelChange(e)}
                        name="mpt"
                        sx={{
                          color: isDarkMode ? "#9CA9B9" : "",
                        }}
                      />
                    }
                    label="MPT"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={model.capm}
                        disableRipple
                        onChange={(e) => handleModelChange(e)}
                        name="capm"
                        sx={{
                          color: isDarkMode ? "#9CA9B9" : "",
                        }}
                      />
                    }
                    label="CAPM"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={model.custom}
                        disableRipple
                        onChange={(e) => handleModelChange(e)}
                        name="custom"
                        sx={{
                          color: isDarkMode ? "#9CA9B9" : "",
                        }}
                      />
                    }
                    label="Custom"
                  />
                </FormGroup>
                {error && <FormHelperText>Choose one or more</FormHelperText>}
              </FormControl>
              <FormControl sx={{ marginTop: 1, marginRight: 2 }}>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  sx={{
                    color: isDarkMode ? "#9CA9B9" : "#606060",
                    "&.Mui-focused": {
                      color: isDarkMode ? "#9CA9B9" : "#606060",
                    },
                  }}
                >
                  Shorting
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={true}
                  name="radio-buttons-group"
                  value={shorting}
                  onChange={(e) => handleShorting(e.target.value)}
                  sx={{
                    color: isDarkMode ? "#F0F0F0" : "",
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={
                      <Radio
                        sx={{
                          color: isDarkMode ? "#9CA9B9" : "",
                        }}
                      />
                    }
                    label="On"
                  />
                  <FormControlLabel
                    value={false}
                    control={
                      <Radio
                        sx={{
                          color: isDarkMode ? "#9CA9B9" : "",
                        }}
                      />
                    }
                    label="Off"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className={AnalyticsCSS["form-container-input"]}>
              <FormControl
                variant="standard"
                sx={{
                  width: 150,
                  "& .MuiInput-underline:before": {
                    borderColor: isDarkMode ? "#9CA9B9" : "",
                  },
                  // "& .MuiInput-underline:hover:before": {
                  //   borderColor: isDarkMode ? "#F0F0F0" : "",
                  // },
                  ".MuiSvgIcon-root": {
                    fill: isDarkMode ? "#9CA9B9" : "",
                  },
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: isDarkMode ? "#9CA9B9" : "",
                  },
                }}
              >
                <InputLabel
                  sx={{
                    display: "flex",
                    margin: 0,
                    padding: 0,
                    color: isDarkMode ? "#9CA9B9" : "",
                  }}
                  id="demo-simple-select-label"
                >
                  Optimization Method
                </InputLabel>
                <Select
                  className={AnalyticsCSS["test-input"]}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={method}
                  label="Optimization Method"
                  variant="standard"
                  MenuProps={{
                    MenuListProps: {
                      autoFocusItem: null,
                    },
                    PaperProps: {
                      sx: {
                        color: isDarkMode ? "#F0F0F0" : "",
                        bgcolor: isDarkMode ? "#272835" : "",
                      },
                    },
                  }}
                  sx={{ color: isDarkMode ? "#F0F0F0" : "" }}
                  onClose={() => {
                    setTimeout(() => {
                      document.activeElement.blur();
                    }, 0);
                  }}
                  onChange={(e) => handleMethod(e.target.value)}
                  disabled={true}
                >
                  <MenuItem value={"slsqp"} id="menu-item">
                    SLSQP
                  </MenuItem>
                  <MenuItem value={"markowitz"} id="menu-item">
                    Markowitz
                  </MenuItem>
                  {/* <MenuItem value={"ga"} id="menu-item">
                        Genetic Algorithm
                      </MenuItem> */}
                </Select>
              </FormControl>
              <TextField
                id="standard-number"
                label="Risk Aversion (Optional)"
                type="number"
                InputLabelProps={{
                  shrink: true,
                  sx: {
                    color: isDarkMode ? "#9CA9B9" : "", // Label color
                  },
                }}
                sx={{
                  width: 120,
                  "& .MuiInput-underline:before": {
                    borderColor: isDarkMode ? "#9CA9B9 !important" : "",
                  },
                  "& .MuiInput-underline:hover:before": {
                    borderColor: isDarkMode ? "#F0F0F0 !important" : "",
                  },
                  ".MuiSvgIcon-root": {
                    fill: isDarkMode ? "#9CA9B9" : "",
                  },
                }}
                variant="standard"
                value={aversion}
                onChange={(e) => handleAversion(e.target.value)}
                InputProps={{
                  sx: {
                    color: isDarkMode ? "#F0F0F0" : "", // Text color
                  },
                }}
              />
            </div>
            {model.custom && (
              <div className={AnalyticsCSS["form-container-input"]}>
                <TextField
                  error={!weightCheck} // Set error to true when weights don't add up to 1
                  id="weights"
                  type="string"
                  label="Weights"
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                    sx: {
                      color: isDarkMode ? "#9CA9B9" : "", // Label color
                    },
                  }}
                  sx={{
                    maxWidth: 298,
                    "& .MuiInput-underline:before": {
                      borderColor: isDarkMode ? "#9CA9B9 !important" : "",
                    },
                    "& .MuiInput-underline:hover:before": {
                      borderColor: isDarkMode ? "#F0F0F0 !important" : "",
                    },
                    ".MuiSvgIcon-root": {
                      fill: isDarkMode ? "#9CA9B9" : "",
                    },
                  }}
                  InputProps={{
                    style: {
                      color: isDarkMode ? "#F0F0F0" : "", // Change text color here
                    },
                  }}
                  placeholder="Enter comma-separated weights"
                  value={customWeights}
                  onChange={(e) => handleWeightChange(e.target.value)}
                  helperText={
                    !weightCheck &&
                    "Weights must add up to 1 and match number of tickers"
                  }
                />
              </div>
            )}
          </div>
        )}
        <div className={AnalyticsCSS["analytics-button-container"]}>
          <button
            type="submit"
            className={`${AnalyticsCSS["submit-button"]} ${
              isDarkMode ? AnalyticsCSS.darkModeContainer : ""
            }`}
            disabled={loading}
          >
            {loading ? <CircularProgress size={20} /> : "Submit"}
          </button>
        </div>
      </form>
      {alert && (
        <p
          style={{ color: "#F42727" }}
          className={AnalyticsCSS["analytics-status"]}
        >
          Please enter valid information
        </p>
      )}
    </div>
  );
}
export default AnalyticsLeft;
