import React, { useEffect, useRef, memo } from "react";

import { useDarkMode } from "contexts/DarkModeContext";

import AnalyticsCSS from "./Analytics.module.css";

// option 1, original
// function TradingViewWidget(props) {
//   const container = useRef();
//   const { isDarkMode } = useDarkMode();

//   useEffect(() => {
//     const script = document.createElement("script");
//     script.src =
//       "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
//     script.type = "text/javascript";
//     script.async = true;

//     // Set the theme dynamically based on the isDarkMode state
//     script.innerHTML = `
//         {
//           "autosize": true,
//           "symbol": "NASDAQ:AAPL",
//           "interval": "D",
//           "timezone": "Etc/UTC",
//           "theme": "${isDarkMode ? "dark" : "light"}",
//           "style": "1",
//           "locale": "en",
//           "withdateranges": true,
//           "hide_side_toolbar": false,
//           "allow_symbol_change": true,
//           "calendar": false,
//           "support_host": "https://www.tradingview.com"
//         }`;

//     container.current.appendChild(script);

//     // Copy container.current to a variable for use in the cleanup function
//     const currentContainer = container.current;

//     // Cleanup function to remove the script element when the component unmounts
//     return () => {
//       // Check if the script node is a child of the container before removing it
//       if (currentContainer.contains(script)) {
//         currentContainer.removeChild(script);
//       }
//     };
//   }, [isDarkMode]);

//   return (
//     <div
//       className={`${AnalyticsCSS["tradingview-widget-container"]} ${
//         isDarkMode ? AnalyticsCSS.darkModeContainer : ""
//       }`}
//       ref={container}
//     ></div>
//   );
// }

// export default memo(TradingViewWidget);

// option 2 best one yet
function TradingViewWidget(props) {
  const container = useRef();
  const { isDarkMode } = useDarkMode();

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
    script.type = "text/javascript";
    script.async = true;

    container.current.innerHTML = ""; // Clear the old chart

    // Set the theme dynamically based on the isDarkMode state
    script.innerHTML = `
        {
          "autosize": true,
          "symbol": "SPY",
          "interval": "D",
          "timezone": "Etc/UTC",
          "theme": "${isDarkMode ? "dark" : "light"}",
          "style": "1",
          "locale": "en",
          "withdateranges": true,
          "hide_side_toolbar": false,
          "allow_symbol_change": true,
          "calendar": false,
          "support_host": "https://www.tradingview.com"
        }`;

    container.current.appendChild(script);

    // Copy container.current to a variable for use in the cleanup function
    const currentContainer = container.current;

    // Cleanup function to remove the script element when the component unmounts
    return () => {
      // Remove the script element when the component unmounts
      if (currentContainer.contains(script)) {
        currentContainer.removeChild(script);
      }
    };
  }, [isDarkMode]);

  return (
    <div
      className={`${AnalyticsCSS["tradingview-widget-container"]} ${
        isDarkMode ? AnalyticsCSS.darkModeContainer : ""
      }`}
      ref={container}
    ></div>
  );
}

export default memo(TradingViewWidget);
