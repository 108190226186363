import React from "react";
import NavbarCSS from "./Navbar.module.css";
// import logo from "assets/logo2.png";
import defaultImage2 from "assets/default2.png";
import { Link, useNavigate } from "react-router-dom";
import EditProfile from "./EditProfile";
import Settings from "./Settings";
import Contact from "components/Shared/Contact";
import { useDarkMode } from "contexts/DarkModeContext";
import { useSubTab } from "contexts/SubTabContext";
// import logoDark from "assets/logodark.png";
import defaultDark from "assets/defaultdark.png";
import { useMobile } from "contexts/MobileContext";
import unitrade from "assets/unitrade.png";
import unitradedark from "assets/unitradedark.png";
import unitradearrow from "assets/unitradearrow.png";
import unitradearrowdark from "assets/unitradearrowdark.png";
import ArrowBack from "@mui/icons-material/ArrowBackIosNew";

function Navbar(props) {
  const { isDarkMode, setIsDarkMode, toggleDarkMode } = useDarkMode();
  const { isMobile } = useMobile();

  const { subTab, setSubTab } = useSubTab();
  const [showDropdown, setShowDropdown] = React.useState(false);
  const [openEditProfile, setOpenEditProfile] = React.useState(false);
  const [openSettings, setOpenSettings] = React.useState(false);
  const [openContact, setOpenContact] = React.useState(false);

  const { tab } = props;

  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  const useOutsideAlerter = (ref) => {
    React.useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowDropdown(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const showDropIcon = () => {
    setShowDropdown((prevShowDropdown) => !prevShowDropdown);
  };

  const openLogin = () => {
    props.openlogin(true);
  };

  const handleOpenContact = (data) => {
    setOpenContact(data);
    setShowDropdown(false);
  };
  const handleOpenEdit = (data) => {
    setOpenEditProfile(data);
    setShowDropdown(false);
  };

  const handleOpenSettings = (data) => {
    setOpenSettings(data);
    setShowDropdown(false);
  };

  const signOut = () => {
    localStorage.clear();
    setShowDropdown(false);
    navigate("/");
    setIsDarkMode(false);
  };

  const logoClick = () => {
    setSubTab(true);
  };

  return (
    <nav
      className={`${NavbarCSS["navbar"]} ${
        isDarkMode && token ? NavbarCSS.darkModeContainer : ""
      }`}
    >
      <Link to={"#"} className={NavbarCSS.logo} onClick={logoClick}>
        {!subTab && isMobile ? (
          <ArrowBack
            sx={{
              color: isDarkMode && token ? "#F0F0F0" : "#000000",
              height: 34,
              width: 34,
              marginBottom: 0.8,
            }}
          />
        ) : (
          <img
            src={isDarkMode && token ? unitradearrowdark : unitradearrow}
            height="34px"
            width="34px"
            alt="logo"
          />
        )}
        {!isMobile ? (
          <img
            src={isDarkMode ? unitradedark : unitrade}
            height="32px"
            width="100px"
            alt="logo"
          />
        ) : token ? null : (
          <img
            src={isDarkMode ? unitradedark : unitrade}
            height="32px"
            width="100px"
            alt="logo"
          />
        )}
      </Link>
      {isMobile && token && (
        <span
          className={`${NavbarCSS["tab-name"]} ${
            isDarkMode && token ? NavbarCSS.darkModeContainer : ""
          }`}
        >
          {tab.charAt(0).toUpperCase() + tab.slice(1)}
        </span>
      )}
      {token ? (
        <div ref={wrapperRef}>
          <div
            className={NavbarCSS["profile-icon"]}
            onClick={token ? showDropIcon : openLogin}
          >
            <img
              src={isDarkMode ? defaultDark : defaultImage2}
              alt="profile"
              height="38px"
              width="58px"
            />
          </div>
          {showDropdown && (
            <div
              className={`${NavbarCSS["profile-dropdown"]} ${
                isDarkMode && token ? NavbarCSS.darkModeContainer : ""
              }`}
            >
              <div
                className={`${NavbarCSS["dropdown-row"]} ${
                  isDarkMode && token ? NavbarCSS.darkModeContainer : ""
                }`}
                onClick={(e) => handleOpenSettings(true)}
              >
                Settings
              </div>
              <div
                className={`${NavbarCSS["dropdown-row"]} ${
                  isDarkMode && token ? NavbarCSS.darkModeContainer : ""
                }`}
                onClick={(e) => handleOpenEdit(true)}
              >
                Edit Profile
              </div>
              {isMobile && (
                <div
                  className={`${NavbarCSS["dropdown-row"]} ${
                    isDarkMode && token ? NavbarCSS.darkModeContainer : ""
                  }`}
                  onClick={(e) => handleOpenContact(true)}
                >
                  Contact Us
                </div>
              )}
              <div
                style={{ color: "#F42727" }}
                className={`${NavbarCSS["dropdown-row"]} ${
                  isDarkMode && token ? NavbarCSS.darkModeContainer : ""
                }`}
                onClick={signOut}
              >
                Sign Out
              </div>
            </div>
          )}
        </div>
      ) : (
        <button className={NavbarCSS["button-container"]} onClick={openLogin}>
          Sign In
        </button>
      )}

      {openEditProfile || openSettings || openContact ? (
        <div className="faded-background"></div>
      ) : (
        ""
      )}
      {openEditProfile ? <EditProfile handleOpen={handleOpenEdit} /> : ""}
      {openSettings ? <Settings handleOpen={handleOpenSettings} /> : ""}
      {openContact ? <Contact open={handleOpenContact} /> : ""}
    </nav>
  );
}

export default Navbar;
