import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { post } from "services/service";

import { useMobile } from "contexts/MobileContext";
import { useDarkMode } from "contexts/DarkModeContext";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import ResetCSS from "./Reset.module.css";

function Reset(props) {
  const { isDarkMode } = useDarkMode();
  const { isMobile } = useMobile();

  const { open, portfolioId, isUser } = props;

  const [balanceInput, setBalanceInput] = useState(isUser ? 100000 : 1000000);
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          open(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (balanceInput && portfolioId) {
      try {
        if (isUser) {
          const response = await post("/portfolio/reset", {
            portfolioId,
            buyingPower: balanceInput,
          });
          const tempPortfolio = JSON.parse(
            localStorage.getItem("tempPortfolio")
          );

          const newTempPortfolio = {
            accountId: tempPortfolio.accountId,
            portfolios: [],
            ledgers: [],
            portfolioData: [],
          };

          localStorage.setItem(
            "tempPortfolio",
            JSON.stringify(newTempPortfolio)
          );

          navigate("/");
        } else {
          const tempCommunities = JSON.parse(
            localStorage.getItem("tempCommunities")
          );

          const response = await post("/communities/reset", {
            portfolioId,
            communityId: tempCommunities.communityId,
            buyingPower: balanceInput,
          });

          const newTempCommunities = {
            communityId: tempCommunities.communityId,
            communities: [],
            portfolios: [],
            ledgers: [],
            portfolioData: [],
          };

          localStorage.setItem(
            "tempCommunities",
            JSON.stringify(newTempCommunities)
          );
          window.location.reload();
        }

        open(false);
      } catch (error) {
        console.error("Error resetting portfolio:", error);
      }
    } else {
      setError(true);
    }
  };

  const handleBalanceInput = (event) => {
    setError(false);
    const userInput = event.target.value;

    if (
      (userInput === "" || /^[1-9]\d*$/.test(userInput)) &&
      userInput <= 100000000
    ) {
      // If input is empty or a valid positive integer <= 100000000, update the state
      setBalanceInput(userInput);
    }
  };

  return (
    <div
      className={`${ResetCSS["reset"]} ${
        isDarkMode ? ResetCSS.darkModeContainer : ""
      }`}
      ref={wrapperRef}
    >
      <h4>
        Reset
        {isUser ? " Account" : " Fund"}
      </h4>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          "& .MuiTextField-root": {
            marginBottom: "7px",
            width: isMobile ? "260px" : "343px",
          },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="standard-number"
          label={
            <span className={ResetCSS["custom-label-text"]}>
              Initial Balance
            </span>
          }
          type="number"
          InputLabelProps={{
            shrink: true,
            sx: {
              color: isDarkMode ? "#9CA9B9" : "", // Label color
            },
          }}
          FormHelperTextProps={{
            sx: {
              textAlign: "center",
              color: isDarkMode ? "#9CA9B9" : "",
            },
          }}
          sx={{
            width: 120,
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: isDarkMode ? "#9CA9B9" : "",
            },
            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: isDarkMode ? "#F0F0F0" : "", // Border color on hover
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "#1976d2", // Border color when focused
              },
            "& .MuiOutlinedInput-root:hover": {
              "& .MuiOutlinedInput-input": {
                color: isDarkMode ? "#F0F0F0" : "", // Text color on hover
              },
            },
          }}
          InputProps={{
            sx: {
              color: isDarkMode ? "#F0F0F0" : "", // Text color
            },
          }}
          variant="outlined"
          value={balanceInput}
          onChange={handleBalanceInput}
          helperText="All positions and balances will be reset"
          error={error}
        />
        <button
          type="submit"
          className={`${ResetCSS["login-button"]} ${
            isDarkMode ? ResetCSS.darkModeContainer : ""
          }`}
        >
          Confirm
        </button>
      </Box>
    </div>
  );
}

export default Reset;
