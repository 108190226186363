import React, { useEffect, useState, useRef } from "react";
import ResetPasswordCSS from "./ResetPassword.module.css";
import { post } from "services/service";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useDarkMode } from "contexts/DarkModeContext";
import { useColorPreference } from "contexts/ColorPreferenceContext";
import { useMobile } from "contexts/MobileContext";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const Login = (props) => {
  const { isMobile } = useMobile();

  const [username, setUsername] = useState("");
  // const [status, setStatus] = useState("");
  // const [userStatus, setUserStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [trueOtp, setTrueOtp] = useState("");
  const [otp, setOtp] = useState("");
  const [verified, setVerified] = useState(false);
  const [password, setPassword] = useState("");
  const [passStatus, setPassStatus] = useState(false);
  const [timer, setTimer] = useState(30);
  const [resent, setResent] = useState(true);
  const [resetStatus, setResetStatus] = useState("");
  const [currentStep, setCurrentStep] = useState("email");
  const [codeStatus, setCodeStatus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { setIsDarkMode, toggleDarkMode } = useDarkMode();
  const { setIsAlternativeColor, toggleColorPreference } = useColorPreference();

  const navigate = useNavigate();

  const handleOtpChange = (newValue) => {
    setOtp(newValue);
  };

  useEffect(() => {
    if (trueOtp) {
      const otpAsNumber = Number(otp);
      const trueOtpAsNumber = Number(trueOtp);
      if (otpAsNumber === trueOtpAsNumber) {
        setVerified(true);
      } else {
        setVerified(false);
      }
    }
  }, [otp, trueOtp]);

  useEffect(() => {
    if (codeStatus) {
      const timer = setTimeout(() => {
        setCodeStatus(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [codeStatus]);

  // const create = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);

  //   if (!verified) {
  //     setLoading(false);
  //   } else if (trueOtp && verified) {
  //     if (!password || password.length < 8) {
  //       setPassStatus("Password must be 8-characters");
  //       setLoading(false);

  //     } else {
  //       const requestData = {
  //         usernameOrEmail: username,
  //         password: password,
  //       };

  //       try {
  //         const response = await post("/users/update-password", requestData);
  //         localStorage.setItem("token", response.data.token);
  //         localStorage.setItem("tempUser", JSON.stringify(response.data.user));
  //         if (response.data.message) {
  //           localStorage.clear();
  //           setStatus(true);
  //         } else {
  //           props.open(false);
  //           toggleDarkMode(response.data.user.preferences.darkmode);
  //           toggleColorPreference(
  //             response.data.user.preferences.colorpreference
  //           );
  //           navigate("/dashboard/portfolio");
  //         }
  //       } catch (err) {
  //         console.log(err.message);
  //       } finally {
  //         setLoading(false);
  //       }
  //     }
  //   } else {

  //   }
  // };

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          props.open(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const cleanUser = (value) => {
    setResetStatus("");
    setUsername(value.toLowerCase());
  };

  const cleanPass = (value) => {
    setPassStatus(false);
    if (value.length <= 64) {
      setPassword(value);
    }
  };

  useEffect(() => {
    if (trueOtp && resent && timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => {
        clearInterval(countdown);
      };
    } else {
      setTimer(30);
    }

    if (timer === 0) {
      setResent(false);
    }
  }, [trueOtp, resent, timer]);

  const handleResend = () => {
    setResent(true);
    sendEmail();
  };

  const sendEmail = async () => {
    if (username) {
      setLoading(true);
      try {
        const response = await post("/users/reset-password-email", {
          usernameOrEmail: username,
        });
        if (response.data) {
          setTrueOtp(response.data.recoveryCode);
          setCurrentStep("otp");
        }
      } catch (err) {
        setResetStatus(err.response.data.error);
      } finally {
        setLoading(false);
      }
    } else {
      setResetStatus("Please include username or email");
    }
  };

  const checkCode = () => {
    if (verified) {
      setCurrentStep("password");
    } else {
      setCodeStatus(true);
    }
  };

  const resetPassword = async () => {
    if (!password || password.length < 8) {
      setPassStatus(true);
    } else {
      localStorage.clear();
      setLoading(true);

      const requestData = {
        usernameOrEmail: username,
        password: password,
      };

      try {
        const response = await post("/users/update-password", requestData);
        if (response.data) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("tempUser", JSON.stringify(response.data.user));
          setIsDarkMode(response.data.user.preferences.darkmode);
          setIsAlternativeColor(response.data.user.preferences.colorpreference);
          props.open(false);
          navigate("/dashboard/portfolio");
        }
      } catch (err) {
        console.log(err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (currentStep === "email") {
      sendEmail();
    } else if (currentStep === "otp") {
      checkCode();
    } else if (currentStep === "password") {
      resetPassword();
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className={ResetPasswordCSS.reset} ref={wrapperRef}>
      <h4>Reset Password</h4>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: "4.55px", width: "328px" },
          "@media screen and (max-width: 480px)": {
            "& .MuiTextField-root": {
              width: "260px",
            },
          },
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        {currentStep && currentStep === "email" && (
          <TextField
            error={resetStatus ? true : false}
            id="username-login"
            type="string"
            label="Username or Email"
            variant="standard"
            value={username}
            helperText={
              resetStatus
                ? resetStatus
                : "An email will be sent with a verification code"
            }
            onChange={(e) => cleanUser(e.target.value)}
          />
        )}

        {currentStep && currentStep === "otp" && (
          <div style={{ width: "260px" }}>
            <MuiOtpInput
              length={4}
              gap={0}
              spacing={0}
              value={otp}
              onChange={handleOtpChange}
              style={{
                margin: "0 auto",
              }}
            />
            <FormHelperText
              error={true}
              sx={{
                textAlign: "center",
                height: "12px",
                marginTop: "-5px",
                marginBottom: "4.55px",
              }}
            >
              {codeStatus && "Incorrect code"}
            </FormHelperText>
          </div>
        )}
        {currentStep && currentStep === "password" && (
          <div className={ResetPasswordCSS["pass-container"]}>
            <TextField
              error={passStatus ? true : false}
              id="password"
              type={showPassword ? "text" : "password"}
              label="New Password"
              variant="standard"
              style={{
                height: "62px",
                marginBottom: "7.55px",
              }}
              helperText="Must be at least 8-characters"
              value={password}
              onChange={(e) => cleanPass(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      onMouseUp={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        )}
        <button
          type="submit"
          className={
            currentStep === "otp"
              ? ResetPasswordCSS["reset-button-alt"]
              : ResetPasswordCSS["reset-button"]
          }
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={20} />
          ) : currentStep === "otp" ? (
            "Verify Code"
          ) : currentStep === "password" ? (
            "Reset Password"
          ) : (
            "Send Email"
          )}
        </button>
      </Box>
      {currentStep && currentStep === "email" && (
        <p className={ResetPasswordCSS["login"]}>
          Remember your password?{" "}
          <span
            onClick={() => {
              props.form("login");
            }}
            className={ResetPasswordCSS["resend"]}
            style={{ cursor: "pointer" }}
          >
            Log in
          </span>
        </p>
      )}
      {currentStep && currentStep === "otp" && (
        <p className={ResetPasswordCSS["login"]}>
          Did not receive the email?{" "}
          <span
            onClick={handleResend}
            className={ResetPasswordCSS["resend"]}
            style={{
              color: !resent ? "" : "#000000",
              cursor: !resent ? "pointer" : "text",
            }}
          >
            {!resent ? "Resend" : timer}
          </span>
        </p>
      )}
      {currentStep && currentStep === "password" && (
        <span style={{ height: isMobile ? "24px" : "32px" }}></span>
      )}
    </div>
  );
};

export default Login;
