import React, { useState } from "react";

import { useMobile } from "contexts/MobileContext";
import { useSubTab } from "contexts/SubTabContext";
import { useDarkMode } from "contexts/DarkModeContext";
import { useColorPreference } from "contexts/ColorPreferenceContext";
import { createCalculatePriceColor } from "utils/colorUtils";
import { formatNumber } from "utils/formatNumber";

import CircularProgress from "@mui/material/CircularProgress";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import CommunitiesCSS from "./Communities.module.css";
import CommunitiesCreate from "./CommunitiesCreate";

function CommunitiesLeft(props) {
  const { isDarkMode } = useDarkMode();
  const { isMobile } = useMobile();
  const { subTab, setSubTab } = useSubTab();

  const { isAlternativeColor } = useColorPreference();
  const calculateColor = createCalculatePriceColor(
    isDarkMode,
    isAlternativeColor
  );
  const positiveColor = calculateColor(true);
  const negativeColor = calculateColor(false);
  const {
    tempCommunities,
    currentCommunity,
    currentTab,
    currentClick,
    loading,
    communityClick,
    createCommunity,
    searchCommunity,
    searchedCommunity,
  } = props;

  const { communities: allCommunities, portfolioData: allData } =
    tempCommunities;
  const { community: currCommunity, portfolioData: currData } =
    currentCommunity;
  const { main: loadingMain } = loading;

  const [openCommunitiesCreate, setOpenCommunitiesCreate] = useState(false);
  const [accessCode, setAccessCode] = useState("");

  const handleOpenEdit = (data) => {
    setOpenCommunitiesCreate(data);
  };

  const create = (value) => {
    createCommunity(value);
  };

  const handleCodeSearch = (value) => {
    searchCommunity(value);
  };

  return (
    <div className={CommunitiesCSS["communities-left"]}>
      {!isMobile && (
        <h2
          className={`${CommunitiesCSS["tab-heading"]} ${
            isDarkMode ? CommunitiesCSS.darkModeContainer : ""
          }`}
        >
          Communities
        </h2>
      )}
      <div className={CommunitiesCSS["communities-button-container"]}>
        <button
          onClick={(e) => currentClick(e.target.value)}
          className={
            currentTab === "mycommunities"
              ? `${CommunitiesCSS["communities-button-selected"]} ${
                  isDarkMode ? CommunitiesCSS.darkModeContainer : ""
                }`
              : CommunitiesCSS["communities-button"]
          }
          value="mycommunities"
        >
          My Communities
        </button>
        <button
          onClick={(e) => currentClick(e.target.value)}
          className={
            currentTab === "search"
              ? `${CommunitiesCSS["communities-button-selected"]} ${
                  isDarkMode ? CommunitiesCSS.darkModeContainer : ""
                }`
              : CommunitiesCSS["communities-button"]
          }
          value="search"
        >
          Search
        </button>
      </div>
      {currentTab === "mycommunities" && (
        <div className={CommunitiesCSS["list-container"]}>
          {allCommunities.length !== 0 &&
            allCommunities
              .slice()
              .reverse()
              .map((community) => {
                const filteredData = allData.find(
                  (pd) => pd.communityId._id === community._id
                );
                return (
                  <div
                    className={CommunitiesCSS["communities-row-element"]}
                    key={community._id}
                    onClick={() => {
                      communityClick(community._id);
                      setSubTab(!subTab);
                    }}
                    style={
                      currCommunity._id === community._id
                        ? {
                            backgroundColor: isDarkMode ? "#272835" : "#FFFFFF",
                          }
                        : { backgroundColor: "" }
                    }
                  >
                    <div
                      className={
                        CommunitiesCSS["communities-row-subelement-left"]
                      }
                    >
                      <span
                        className={`${CommunitiesCSS["subelement-ticker"]} ${
                          isDarkMode ? CommunitiesCSS.darkModeContainer : ""
                        }`}
                      >
                        {community.ticker}
                      </span>
                      <span
                        className={`${CommunitiesCSS["subelement-shortname"]} ${
                          isDarkMode ? CommunitiesCSS.darkModeContainer : ""
                        }`}
                      >
                        <span> {community.name} </span>
                        {community.members.some(
                          (member) =>
                            member.user._id ===
                              JSON.parse(localStorage.getItem("tempUser"))
                                ._id && member.moderator
                        ) ? (
                          <span>
                            <span id={CommunitiesCSS["separator"]}>
                              {" | "}
                            </span>
                            <span>Moderator</span>
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                    {!loadingMain && Object.keys(currData).length ? (
                      <div
                        className={
                          CommunitiesCSS["communities-row-subelement-right"]
                        }
                      >
                        <span
                          className={`${CommunitiesCSS["subelement-price"]} ${
                            isDarkMode ? CommunitiesCSS.darkModeContainer : ""
                          }`}
                        >
                          {formatNumber(filteredData.portfolioBalance)}
                        </span>
                        <span
                          className={CommunitiesCSS["subelement-change"]}
                          style={
                            filteredData.marketChange > 0
                              ? { color: positiveColor }
                              : filteredData.marketChange < 0
                              ? { color: negativeColor }
                              : { color: "#757D87" }
                          }
                        >
                          {formatNumber(filteredData.marketChange)} (
                          {formatNumber(filteredData.percentChange)}
                          )%
                        </span>
                      </div>
                    ) : (
                      <CircularProgress
                        size={30}
                        style={{ marginRight: "16px" }}
                      />
                    )}
                  </div>
                );
              })}
          {!loadingMain && allCommunities.length === 0 && (
            <p className={CommunitiesCSS["placeholder-text"]}>
              You are not a member of any communities
            </p>
          )}
          {!loadingMain && allCommunities.length < 5 && (
            <AddCircleOutlineIcon
              fontSize="large"
              className={CommunitiesCSS["add-icon"]}
              sx={{
                color: isDarkMode ? "#387ADB" : "#0B5DD7",
                marginTop: 1,
              }}
              onClick={!loadingMain ? () => handleOpenEdit(true) : undefined}
            />
          )}
        </div>
      )}
      {currentTab === "search" && (
        <input
          type="text"
          value={accessCode}
          onChange={(e) => setAccessCode(e.target.value)}
          placeholder="Enter access code"
          className={CommunitiesCSS["code-search"]}
        />
      )}
      {currentTab === "search" && (
        <button
          type="submit"
          className={`${CommunitiesCSS["submit-button"]} ${
            isDarkMode ? CommunitiesCSS.darkModeContainer : ""
          }`}
          disabled={loadingMain}
          onClick={() => handleCodeSearch(accessCode)}
        >
          {loadingMain ? <CircularProgress size={20} /> : "Submit"}
        </button>
      )}
      {openCommunitiesCreate && <div className="faded-background"></div>}
      {openCommunitiesCreate && (
        <CommunitiesCreate
          handleOpen={handleOpenEdit}
          create={create}
          edit={false}
        />
      )}
    </div>
  );
}
export default CommunitiesLeft;
