import React, { useEffect, useState } from "react";
import NewsCSS from "./News.module.css";
import { get } from "services/service";
import { useDarkMode } from "contexts/DarkModeContext";
import { useMobile } from "contexts/MobileContext";
import placeholder from "assets/imgplaceholder.png";

function News(props) {
  const { isDarkMode } = useDarkMode();
  const { isMobile } = useMobile();

  const { news } = props;

  const getHoursAgo = (time) => {
    const timestampInMillis = time * 1000;
    const currentTimestamp = new Date().getTime();
    const difference = currentTimestamp - timestampInMillis;

    if (difference < 60 * 60 * 1000) {
      // If less than an hour, calculate and return minutes ago
      const minutesAgo = Math.floor(difference / (1000 * 60));
      if (minutesAgo === 1) {
        return `${minutesAgo} minute ago`;
      } else return `${minutesAgo} minutes ago`;
    } else if (difference < 24 * 60 * 60 * 1000) {
      // If less than 24 hours, calculate and return hours ago
      const hoursAgo = Math.floor(difference / (1000 * 60 * 60));
      if (hoursAgo === 1) {
        return `${hoursAgo} hour ago`;
      } else {
        return `${hoursAgo} hours ago`;
      }
    } else {
      // If greater than or equal to 24 hours, calculate and return days ago
      const daysAgo = Math.floor(difference / (24 * 60 * 60 * 1000));
      if (daysAgo === 1) {
        return `${daysAgo} day ago`;
      } else {
        return `${daysAgo} days ago`;
      }
    }
  };

  return (
    <div className={NewsCSS.news}>
      {news?.map((item, index) => (
        <a
          href={item.link}
          target="_blank"
          rel="noopener noreferrer"
          className={NewsCSS["news-content"]}
          key={index}
        >
          {!isMobile && (
            <img
              src={
                item?.thumbnail?.resolutions[1]
                  ? item.thumbnail.resolutions[1]?.url
                  : item.thumbnail.resolutions[0]?.url
                  ? item.thumbnail.resolutions[0]?.url
                  : placeholder
              }
              alt="news-pic"
              style={
                isMobile
                  ? {
                      width: "70px",
                      height: "70px",
                      objectFit: "cover",
                    }
                  : {
                      width: "85px",
                      height: "85px",
                      objectFit: "cover",
                    }
              }
            />
          )}
          <div className={NewsCSS["news-text"]}>
            <span
              className={`${NewsCSS["news-title"]} ${
                isDarkMode ? NewsCSS.darkModeContainer : ""
              }`}
            >
              {item.title}
            </span>
            <span className={NewsCSS["hours"]}>
              {item.publisher} - {getHoursAgo(item.providerPublishTime)}
            </span>
          </div>
          {isMobile && (
            <img
              src={
                item?.thumbnail?.resolutions[1]
                  ? item.thumbnail.resolutions[1]?.url
                  : item.thumbnail.resolutions[0]?.url
                  ? item.thumbnail.resolutions[0]?.url
                  : placeholder
              }
              alt="news-pic"
              style={
                isMobile
                  ? {
                      width: "70px",
                      height: "70px",
                      objectFit: "cover",
                    }
                  : {
                      width: "85px",
                      height: "85px",
                      objectFit: "cover",
                    }
              }
            />
          )}
        </a>
      ))}
    </div>
  );
}

export default News;
