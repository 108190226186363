import React, { useState } from "react";

import { useDarkMode } from "contexts/DarkModeContext";

import CommunitiesHeaderCSS from "./CommunitiesHeader.module.css";
import CommunitiesCreate from "./CommunitiesCreate";
import CommunitiesCreatePost from "./CommunitiesCreatePost";
import CommunitiesInvite from "./CommunitiesInvite";

function CommunitiesHeader(props) {
  const { isDarkMode } = useDarkMode();

  const { currentCommunity, userStatus, currentTab } = props;

  const [openCommunitiesCreate, setOpenCommunitiesCreate] = useState(false);
  const [openCommunitiesInvite, setOpenCommunitiesInvite] = useState(false);
  const [openCreatePost, setOpenCreatePost] = useState(false);
  const [openSearchMembers, setOpenSearchMembers] = useState(false);
  const [openContribute, setOpenContribute] = useState(false);

  const handleOpenEdit = (data) => {
    setOpenCommunitiesCreate(data);
  };
  const handleOpenCreatePost = (data) => {
    setOpenCreatePost(data);
  };

  const deleteCommunity = (value) => {
    props.deleteCommunity(value);
  };

  const updateCommunity = (value) => {
    props.updateCommunity(value);
  };

  const handleOpenInvite = (data) => {
    setOpenCommunitiesInvite(data);
  };

  const handleJoin = (value) => {
    props.joinCommunity(value);
  };

  const newPost = (value) => {
    props.newPost(value);
  };

  return (
    <div
      className={`${CommunitiesHeaderCSS["communities-header"]} ${
        isDarkMode ? CommunitiesHeaderCSS.darkModeContainer : ""
      }`}
    >
      <div className={CommunitiesHeaderCSS["upper-header"]}>
        <div
          className={`${CommunitiesHeaderCSS["text-container"]} ${
            isDarkMode ? CommunitiesHeaderCSS.darkModeContainer : ""
          }`}
        >
          {/* <span className={CommunitiesHeaderCSS["edit-text"]}> */}
          <h4>{currentCommunity.community.name}</h4>
          {/* </span> */}
          <p>{currentCommunity.community.description}</p>
          {userStatus.isMod && (
            <span
              className={`${CommunitiesHeaderCSS["edit-text"]} ${
                isDarkMode ? CommunitiesHeaderCSS.darkModeContainer : ""
              }`}
              onClick={() => setOpenCommunitiesCreate(true)}
            >
              Edit
            </span>
          )}
        </div>

        <div className={CommunitiesHeaderCSS["button-container"]}>
          <button
            className={`${
              CommunitiesHeaderCSS[
                userStatus.isMember ? "submit-button" : "submit-button-2"
              ]
            } ${isDarkMode ? CommunitiesHeaderCSS.darkModeContainer : ""}`}
            onClick={() => handleJoin(currentCommunity.community._id)}
            disabled={
              currentCommunity.community.members.length === 1 &&
              userStatus.isMember
            }
          >
            {userStatus.isMember ? "Joined" : "Join"}
          </button>
          <button
            className={`${CommunitiesHeaderCSS["submit-button"]} ${
              isDarkMode ? CommunitiesHeaderCSS.darkModeContainer : ""
            }`}
            onClick={() => handleOpenInvite(true)}
          >
            Invite
          </button>
        </div>
      </div>
      <div
        className={`${CommunitiesHeaderCSS["lower-header"]} ${
          isDarkMode ? CommunitiesHeaderCSS.darkModeContainer : ""
        }`}
      >
        <p>
          {currentCommunity.community.members.length}{" "}
          {currentCommunity.community.members.length === 1
            ? "Member"
            : "Members"}
        </p>
        <button
          className={`${CommunitiesHeaderCSS["submit-button-2"]} ${
            isDarkMode ? CommunitiesHeaderCSS.darkModeContainer : ""
          }`}
          onClick={() => {
            setOpenCreatePost(true);
          }}
          disabled={
            userStatus.isMember && currentTab === "mycommunities" ? false : true
          }
        >
          New Post
        </button>
      </div>

      {openCommunitiesCreate ||
      openCommunitiesInvite ||
      openCreatePost ||
      openContribute ||
      openSearchMembers ? (
        <div className="faded-background"></div>
      ) : (
        ""
      )}
      {openCommunitiesCreate ? (
        <CommunitiesCreate
          handleOpen={handleOpenEdit}
          deleteCommunity={deleteCommunity}
          edit={true}
          currentCommunity={currentCommunity}
          updateCommunity={updateCommunity}
        />
      ) : (
        ""
      )}
      {openCommunitiesInvite ? (
        <CommunitiesInvite
          handleOpen={handleOpenInvite}
          currentCommunity={currentCommunity}
        />
      ) : (
        ""
      )}
      {openCreatePost ? (
        <CommunitiesCreatePost
          handleOpen={handleOpenCreatePost}
          currentCommunity={currentCommunity}
          newPost={newPost}
          edit={false}
        />
      ) : (
        ""
      )}
    </div>
  );
}
export default CommunitiesHeader;
