import React, { useState } from "react";

import { useDarkMode } from "contexts/DarkModeContext";
import { useColorPreference } from "contexts/ColorPreferenceContext";
import { createCalculatePriceColor } from "utils/colorUtils";
import { formatNumber } from "utils/formatNumber";

import CommunitiesFundLeftCSS from "./CommunitiesFundLeft.module.css";
import CommunitiesDiscussionCSS from "./CommunitiesDiscussion.module.css";
import Reset from "../Portfolio/Reset";

function CommunitiesFundLeft(props) {
  const { currentCommunity, userStatus, loading } = props;

  const { portfolio: selectedPortfolio, portfolioData: filteredQuotes } =
    currentCommunity;

  const [openReset, setOpenReset] = useState(false);
  // const [filteredQuotes, setFilteredQuotes] = useState({});

  const { isDarkMode } = useDarkMode();
  const { isAlternativeColor } = useColorPreference();
  const calculateColor = createCalculatePriceColor(
    isDarkMode,
    isAlternativeColor
  );
  const positiveColor = calculateColor(true);
  const negativeColor = calculateColor(false);

  const click = (data) => {
    setOpenReset(data);
  };

  // useEffect(() => {
  //   if (
  //     Object.keys(filteredData).length &&
  //     Object.keys(selectedPortfolio).length
  //   ) {
  //     setFilteredQuotes(filteredData[selectedPortfolio.communityId]);
  //   }
  // }, [filteredData, selectedPortfolio]);

  return (
    <div className={CommunitiesFundLeftCSS["portfolio-left"]}>
      <div className={CommunitiesFundLeftCSS["account-information"]}>
        <h5>{currentCommunity.community.ticker} Fund</h5>
        <h4
          className={`${CommunitiesFundLeftCSS["h4-portfolio"]} ${
            isDarkMode ? CommunitiesFundLeftCSS.darkModeContainer : ""
          }`}
        >
          {!loading.main && filteredQuotes
            ? formatNumber(filteredQuotes.portfolioBalance)
            : ""}
        </h4>

        <div className={CommunitiesFundLeftCSS["quick-price"]}></div>
      </div>
      {!loading.main && filteredQuotes && selectedPortfolio && (
        <div className={CommunitiesFundLeftCSS["account-information"]}>
          <p className={CommunitiesFundLeftCSS["account-value"]}>
            Fund Value (USD)
          </p>
          <div className={CommunitiesFundLeftCSS["quick-price"]}>
            <p
              className={CommunitiesFundLeftCSS["secondary-price"]}
              style={
                filteredQuotes.marketChange > 0
                  ? { color: positiveColor }
                  : filteredQuotes.marketChange < 0
                  ? { color: negativeColor }
                  : { color: "#757D87" }
              }
            >
              {formatNumber(filteredQuotes.marketChange)}
            </p>
            <p
              className={CommunitiesFundLeftCSS["secondary-price"]}
              style={
                filteredQuotes.marketChange > 0
                  ? { color: positiveColor }
                  : filteredQuotes.marketChange < 0
                  ? { color: negativeColor }
                  : { color: "#757D87" }
              }
            >
              {formatNumber(filteredQuotes.percentChange)}%
            </p>
          </div>
          <div
            className={`${CommunitiesFundLeftCSS["account-value-summary"]} ${
              isDarkMode ? CommunitiesFundLeftCSS.darkModeContainer : ""
            }`}
          >
            <p>
              <span>Buying Power</span>
              <span>{formatNumber(selectedPortfolio.buyingPower)}</span>
            </p>
            <p>
              <span>Positions</span>
              <span>{formatNumber(filteredQuotes.totalMarketValue)}</span>
            </p>
            <p>
              <span>Cash</span>
              <span>{formatNumber(selectedPortfolio.cash)}</span>
            </p>
          </div>
          {userStatus.isMod && (
            <span
              className={`${CommunitiesFundLeftCSS["reset-button"]} ${
                isDarkMode ? CommunitiesFundLeftCSS.darkModeContainer : ""
              }`}
              onClick={() => setOpenReset(true)}
            >
              Reset
            </span>
          )}

          {openReset && (
            <Reset
              open={click}
              portfolioId={selectedPortfolio._id}
              isUser={false}
            />
          )}
          {openReset && <div className="faded-background"></div>}
        </div>
      )}
      {loading.main ? (
        <p className={CommunitiesDiscussionCSS["placeholder-text"]}>
          Loading...
        </p>
      ) : (
        ""
      )}
    </div>
  );
}
export default CommunitiesFundLeft;
