import React from "react";

import { useDarkMode } from "contexts/DarkModeContext";
import { useColorPreference } from "contexts/ColorPreferenceContext";
import { createCalculatePriceColor } from "utils/colorUtils";
import { formatNumber } from "utils/formatNumber";

import PortfolioPositionsCSS from "./PortfolioPositions.module.css";

function PortfolioPositions(props) {
  const { loading, currentAccount } = props;

  const {
    portfolio: { positions, cash },
    portfolioData: {
      regularMktPrices,
      assetMktValues,
      positionMarketChange,
      positionPercentChange,
      positionAllocation,
      cashAllocation,
    },
  } = currentAccount;
  const { main: loadingMain } = loading;

  const { isDarkMode } = useDarkMode();
  const { isAlternativeColor } = useColorPreference();
  const calculateColor = createCalculatePriceColor(
    isDarkMode,
    isAlternativeColor
  );
  const positiveColor = calculateColor(true);
  const negativeColor = calculateColor(false);

  const getRegularMarketPrice = (ticker) => {
    return regularMktPrices[ticker] || regularMktPrices[ticker] === 0
      ? regularMktPrices[ticker]
      : "-";
  };

  const getPositionMarketValue = (ticker) => {
    return assetMktValues[ticker] || assetMktValues[ticker] === 0
      ? assetMktValues[ticker]
      : "-";
  };

  const getPositionMarketChange = (ticker) => {
    return positionMarketChange[ticker] || positionMarketChange[ticker] === 0
      ? positionMarketChange[ticker]
      : "-";
  };

  const getPositionMarketPercentage = (ticker) => {
    return positionPercentChange[ticker] || positionPercentChange[ticker] === 0
      ? positionPercentChange[ticker]
      : "-";
  };

  const getAllocation = (ticker) => {
    return positionAllocation[ticker] || positionAllocation[ticker] === 0
      ? positionAllocation[ticker]
      : "-";
  };

  const getPnlColor = (pnl) => {
    if (pnl > 0) {
      return positiveColor;
    } else if (pnl < 0) {
      return negativeColor;
    } else {
      return "#757D87";
    }
  };

  return (
    <div
      className={`${PortfolioPositionsCSS["trade-position"]} ${
        isDarkMode ? PortfolioPositionsCSS.darkModeContainer : ""
      }`}
    >
      <div
        className={`${PortfolioPositionsCSS["trade-position-container"]} ${
          isDarkMode ? PortfolioPositionsCSS.darkModeContainer : ""
        }`}
      >
        <table
          className={`${PortfolioPositionsCSS["positions-table"]} ${
            isDarkMode ? PortfolioPositionsCSS.darkModeContainer : ""
          }`}
        >
          <thead>
            <tr>
              <th>Symbol</th>
              <th>Quantity</th>
              <th>Last Price</th>
              <th>Current Value</th>
              <th>Total Gain/Loss</th>
              <th>Cost Basis</th>
              <th>Allocation</th>
            </tr>
          </thead>
          <tbody>
            {positions.length > 0
              ? positions.map((position) => {
                  const ticker = position.ticker;
                  const name = position.name;
                  const amount = position.amount;
                  const price = getRegularMarketPrice(ticker);
                  const assetMktValue = getPositionMarketValue(ticker);
                  const averagePrice = position.averagePrice;

                  const pnl = getPositionMarketChange(ticker);
                  const pnlPercentage = getPositionMarketPercentage(ticker);
                  const allocation = getAllocation(ticker);
                  return (
                    <tr key={position._id}>
                      <td className={PortfolioPositionsCSS["position-name"]}>
                        <span className={PortfolioPositionsCSS["ticker"]}>
                          {ticker}
                        </span>
                        <span className={PortfolioPositionsCSS["shortname"]}>
                          {name}
                        </span>
                      </td>
                      <td>{formatNumber(amount)}</td>
                      <td>{price !== "-" ? formatNumber(price) : "-"}</td>
                      <td>{formatNumber(assetMktValue)}</td>
                      <td
                        style={{ color: getPnlColor(pnl) }}
                        className={PortfolioPositionsCSS["price"]}
                      >
                        <span>{formatNumber(pnl)}</span>
                        <span>({formatNumber(pnlPercentage)}%)</span>
                      </td>
                      <td>{formatNumber(averagePrice)}</td>
                      <td>{formatNumber(allocation)}%</td>
                    </tr>
                  );
                })
              : null}
            {!loadingMain && positions.length > 0 ? (
              <tr key="cash">
                <td className={PortfolioPositionsCSS["position-name"]}>
                  <span className={PortfolioPositionsCSS["ticker"]}>USD</span>
                  <span className={PortfolioPositionsCSS["shortname"]}>
                    United States Dollar
                  </span>
                </td>
                <td>-</td>
                <td>-</td>
                <td>{formatNumber(cash)}</td>
                <td>-</td>
                <td>-</td>
                <td>{formatNumber(cashAllocation)}%</td>
              </tr>
            ) : null}
          </tbody>
        </table>
        {!loadingMain && !positions.length && (
          <p className={PortfolioPositionsCSS["placeholder-text"]}>
            There are no positions
          </p>
        )}
      </div>
    </div>
  );
}

export default PortfolioPositions;
