// import axios from "axios";
// import { baseUrl } from "./baseUrl";

// export const get = async (route) => {
//   try {
//     let token = localStorage.getItem("token");
//     let response = await axios.get(baseUrl + route, {
//       headers: {
//         Authorization: token,
//       },
//     });
//     return response;
//   } catch (err) {
//     console.error(err.message);
//   }
// };

import axios from "axios";
import { baseUrl, analyticsUrl, analyticsUrl_2 } from "./baseUrl";

export const get = async (route) => {
  let token = localStorage.getItem("token");
  return await axios.get(baseUrl + route, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// export const get_analytics = (route) => {
//   let token = localStorage.getItem("token");
//   return fetch(analyticsUrl + route, {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   });
// };

export function getHistory(string, period, startDate, endDate, interval) {
  return axios.get(
    `${analyticsUrl_2}/history/${string}/period=${period}/start=${startDate}/end=${endDate}/interval=${interval}`
  );
}

export function getHistoryExcel(string, period, startDate, endDate, interval) {
  return axios.get(
    `${analyticsUrl_2}/history_excel/${string}/period=${period}/start=${startDate}/end=${endDate}/interval=${interval}`,
    {
      responseType: "blob",
    }
  );
}
// export function getHome() {
//   return axios.get("http://localhost:8000");
// }

export function getPortfolio(
  string,
  period,
  start,
  end,
  frequency,
  benchmark,
  rf,
  model,
  shorting,
  method,
  aversion,
  weights
) {
  return axios.get(
    `${analyticsUrl_2}/portfolio/${string}/period=${period}/start=${start}/end=${end}/frequency=${frequency}/benchmark=${benchmark}/rf=${rf}/model=${model}/shorting=${shorting}/method=${method}/aversion=${aversion}/weights=${weights}`,
    {
      // /method=${method}/aversion=${aversion}
      // responseType: "blob",
      // headers: {
      //   "Content-Type": "application/vnd.ms-excel",
      //   "Content-Disposition": 'attachment; filename="example.xlsx"',
      // },
    }
  );
}

export const post = async (route, body) => {
  let token = localStorage.getItem("token");
  return await axios.post(baseUrl + route, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const remove = async (route) => {
  let token = localStorage.getItem("token");
  return await axios.delete(baseUrl + route, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
