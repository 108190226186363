import React, { useEffect, useState } from "react";
import ChartCSS from "./Chart.module.css";
import { get } from "services/service";
import { ChartComponent } from "./ChartComponent";
import ChartSwitch from "./ui/ChartSwitch";
import { useDarkMode } from "contexts/DarkModeContext";
import { useColorPreference } from "contexts/ColorPreferenceContext";
import { createCalculatePriceColor } from "utils/colorUtils";
import { formatNumber } from "utils/formatNumber";

function Chart(props) {
  // const [timespan, setTimespan] = useState("1d");

  const { isDarkMode } = useDarkMode();
  const { isAlternativeColor } = useColorPreference();
  const calculateColor = createCalculatePriceColor(
    isDarkMode,
    isAlternativeColor
  );
  const positiveColor = calculateColor(true);
  const negativeColor = calculateColor(false);

  const { placeholderStats, historicalData, timespan, handleTimespanChange } =
    props;

  return (
    <div
      className={`${ChartCSS["chart"]} ${
        isDarkMode ? ChartCSS.darkModeContainer : ""
      }`}
    >
      <div className={ChartCSS["chart-header"]}>
        <div className={ChartCSS["chart-quote"]}>
          <span className={ChartCSS["chart-shortname"]}>
            {placeholderStats.longName}
          </span>
          <div className={ChartCSS["symbol-price"]}>
            <h4
              className={`${ChartCSS["h4-watchlist"]} ${
                isDarkMode ? ChartCSS.darkModeContainer : ""
              }`}
            >
              {placeholderStats.symbol}
            </h4>
            {placeholderStats.regularMarketChange ? (
              <div className={ChartCSS["chart-quick-price"]}>
                <p
                  className={`${ChartCSS["chart-main-price"]} ${
                    isDarkMode ? ChartCSS.darkModeContainer : ""
                  }`}
                >
                  {formatNumber(placeholderStats.regularMarketPrice)}
                </p>
                <p
                  className={ChartCSS["chart-secondary-price"]}
                  style={
                    placeholderStats.regularMarketChange > 0
                      ? { color: positiveColor }
                      : placeholderStats.regularMarketChange < 0
                      ? { color: negativeColor }
                      : { color: "#757D87" }
                  }
                >
                  {formatNumber(placeholderStats.regularMarketChange)}
                </p>
                <p
                  className={ChartCSS["chart-secondary-price"]}
                  style={
                    placeholderStats.regularMarketChange > 0
                      ? { color: positiveColor }
                      : placeholderStats.regularMarketChange < 0
                      ? { color: negativeColor }
                      : { color: "#757D87" }
                  }
                >
                  ({formatNumber(placeholderStats.regularMarketChangePercent)}
                  %)
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* <ChartSwitch onChange={handleSwitchChange} /> */}
      </div>
      <div className={ChartCSS["chart-buttons"]}>
        <button
          onClick={() => {
            handleTimespanChange("1d");
          }}
          style={{
            color: isDarkMode ? "#F0F0F0" : "#000000",
            boxShadow:
              timespan === "1d"
                ? isDarkMode
                  ? "inset 0px -4px 0px 0px #387ADB"
                  : "inset 0px -4px 0px 0px #0B5DD7"
                : "inset 0px 0px 0px 0px #FFFFFF, inset 0px -1px 0px 0px #F4F9FF",
          }}
        >
          D
        </button>
        <button
          onClick={() => {
            handleTimespanChange("1wk");
          }}
          style={{
            color: isDarkMode ? "#F0F0F0" : "#000000",
            boxShadow:
              timespan === "1wk"
                ? isDarkMode
                  ? "inset 0px -4px 0px 0px #387ADB"
                  : "inset 0px -4px 0px 0px #0B5DD7"
                : "inset 0px 0px 0px 0px #FFFFFF, inset 0px -1px 0px 0px #F4F9FF",
          }}
        >
          W
        </button>
        <button
          onClick={() => {
            handleTimespanChange("1mo");
          }}
          style={{
            color: isDarkMode ? "#F0F0F0" : "#000000",
            boxShadow:
              timespan === "1mo"
                ? isDarkMode
                  ? "inset 0px -4px 0px 0px #387ADB"
                  : "inset 0px -4px 0px 0px #0B5DD7"
                : "inset 0px 0px 0px 0px #FFFFFF, inset 0px -1px 0px 0px #F4F9FF",
          }}
        >
          M
        </button>
        <button
          onClick={() => {
            handleTimespanChange("6mo");
          }}
          style={{
            color: isDarkMode ? "#F0F0F0" : "#000000",
            boxShadow:
              timespan === "6mo"
                ? isDarkMode
                  ? "inset 0px -4px 0px 0px #387ADB"
                  : "inset 0px -4px 0px 0px #0B5DD7"
                : "inset 0px 0px 0px 0px #FFFFFF, inset 0px -1px 0px 0px #F4F9FF",
          }}
        >
          6M
        </button>
        <button
          onClick={() => {
            handleTimespanChange("1y");
          }}
          style={{
            color: isDarkMode ? "#F0F0F0" : "#000000",
            boxShadow:
              timespan === "1y"
                ? isDarkMode
                  ? "inset 0px -4px 0px 0px #387ADB"
                  : "inset 0px -4px 0px 0px #0B5DD7"
                : "inset 0px 0px 0px 0px #FFFFFF, inset 0px -1px 0px 0px #F4F9FF",
          }}
        >
          Y
        </button>
      </div>
      {historicalData && (
        <ChartComponent
          // data={isCandlestick ? candlestickData : lineData}
          data={historicalData}
          // type={isCandlestick ? "candlestick" : "area"}
          timespan={timespan}
          type={"area"}
        />
      )}
    </div>
  );
}

export default Chart;
