import React, { useEffect, useState } from "react";

import { useDarkMode } from "contexts/DarkModeContext";

import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import PositionContainerCSS from "./PositionContainer.module.css";
import PortfolioPositions from "./PortfolioPositions";
import PortfolioHistory from "./PortfolioHistory";

function PositionContainer(props) {
  const { isDarkMode } = useDarkMode();

  const {
    currentAccount,
    loading,
    isUser,
    userStatus,
    updatePortfolio,
    removeFromPending,
    updateLedgerComment,
  } = props;
  const { main: loadingMain } = loading;

  const [transactionHistory, setTransactionHistory] = useState(false);
  const [approvedLedger, setApprovedLedger] = useState([]);
  const [pendingLedger, setPendingLedger] = useState([]);

  const viewHistory = () => {
    setTransactionHistory(!transactionHistory);
  };

  useEffect(() => {
    if (
      !loadingMain &&
      userStatus &&
      currentAccount &&
      currentAccount.ledger &&
      currentAccount.ledger.history &&
      !isUser
    ) {
      const approvedHistory = currentAccount.ledger.history.filter(
        (item) => item.approved === true
      );

      let pendingHistory = [];

      if (userStatus.isMod) {
        pendingHistory = currentAccount.ledger.history.filter(
          (item) => item.approved === false
        );
      } else {
        pendingHistory = currentAccount.ledger.history.filter(
          (item) =>
            item.approved === false &&
            item.contributor._id ===
              JSON.parse(localStorage.getItem("tempUser"))._id
        );
      }

      const approvedLedgerData = {
        ...currentAccount,
        ledger: {
          ...currentAccount.ledger,
          history: approvedHistory,
        },
      };

      const pendingLedgerData = {
        ...currentAccount,
        ledger: {
          ...currentAccount.ledger,
          history: pendingHistory,
        },
      };

      setApprovedLedger(approvedLedgerData);
      setPendingLedger(pendingLedgerData);
    }
  }, [currentAccount, loadingMain, isUser, userStatus]);

  return (
    <div
      className={`${PositionContainerCSS["position-container"]} ${
        isDarkMode ? PositionContainerCSS.darkModeContainer : ""
      }`}
      style={{ padding: !isUser && "0px" }}
    >
      <div className={PositionContainerCSS["position-header"]}>
        <h6>{transactionHistory ? "Transactions" : "Positions"}</h6>
        <span onClick={() => viewHistory()}>
          View {transactionHistory ? "Positions" : "Transactions"}{" "}
          <ArrowRightIcon sx={{ margin: -0.2 }} />
        </span>
      </div>
      {transactionHistory ? (
        <>
          {!isUser && <span>Pending</span>}
          {!isUser && (
            <PortfolioHistory
              loading={loadingMain}
              currentAccount={pendingLedger}
              isUser={isUser}
              userStatus={userStatus}
              pending={true}
              updatePortfolio={updatePortfolio}
              removeFromPending={removeFromPending}
              updateLedgerComment={updateLedgerComment}
            />
          )}
          {!isUser && <span style={{ marginTop: "12px" }}>Filled</span>}
          <PortfolioHistory
            loading={loadingMain}
            currentAccount={isUser ? currentAccount : approvedLedger}
            isUser={isUser}
            userStatus={userStatus}
            pending={false}
          />
        </>
      ) : (
        <PortfolioPositions
          loading={loadingMain}
          currentAccount={currentAccount}
        />
      )}
    </div>
  );
}
export default PositionContainer;
