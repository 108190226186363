import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useDarkMode } from "contexts/DarkModeContext";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import PushPinIcon from "@mui/icons-material/PushPin";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";

import CommunitiesThreadCSS from "./CommunitiesThread.module.css";

function CommunitiesThread(props) {
  const { isDarkMode } = useDarkMode();

  const { selectedPost, userStatus } = props;

  const [title, setTitle] = useState("");
  const [titleStatus, setTitleStatus] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionStatus, setDescriptionStatus] = useState(false);
  const [showDropdown, setShowDropdown] = useState(null);

  const navigate = useNavigate();

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          props.handleOpenThread(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const create = async (e) => {
    e.preventDefault();
    if (JSON.parse(localStorage.getItem("tempUser"))._id) {
      try {
        if (!description) {
          setDescriptionStatus("Please enter a valid reply");
        } else {
          const newReply = {
            postId: selectedPost._id,
            description: description,
            action: "reply",
          };
          props.updatePost(newReply);
          setDescription("");
        }
      } catch (err) {
        console.log(err.message);
      }
    } else {
      navigate("/");
    }
  };

  const cleanDescription = (value) => {
    setDescriptionStatus(false);
    if (value.length <= 280) {
      setDescription(value);
    }
  };

  const showDropIcon = (postId) => {
    setShowDropdown((prevDropdown) => {
      return prevDropdown === postId ? null : postId;
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const moreRefs = document.querySelectorAll(".more-ref");
      const isClickedInsideMoreRef = Array.from(moreRefs).some((ref) =>
        ref.contains(event.target)
      );
      if (!isClickedInsideMoreRef) {
        showDropIcon("");
      }
    };

    document.body.addEventListener("click", handleClickOutside);

    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`${CommunitiesThreadCSS["communities-create"]} ${
        isDarkMode ? CommunitiesThreadCSS.darkModeContainer : ""
      }`}
      ref={wrapperRef}
    >
      <div
        key={selectedPost._id}
        className={`${CommunitiesThreadCSS["main-post-item"]} ${
          isDarkMode ? CommunitiesThreadCSS.darkModeContainer : ""
        }`}
      >
        <span className={CommunitiesThreadCSS["user-and-edit"]}>
          <p
            className={`${CommunitiesThreadCSS["post-username"]} ${
              isDarkMode ? CommunitiesThreadCSS.darkModeContainer : ""
            }`}
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={
              selectedPost.creator === null
                ? { color: isDarkMode ? "#F0F0F0" : "#000000" }
                : null
            }
          >
            {selectedPost.creator !== null
              ? `${selectedPost.creator.firstname} ${selectedPost.creator.lastname}`
              : "[deleted]"}
          </p>
          <span className={CommunitiesThreadCSS["pin-more"]}>
            {selectedPost.pinned && <PushPinIcon />}
            <div className={CommunitiesThreadCSS["more-ref"]}>
              <div>
                <MoreHorizIcon
                  sx={{
                    cursor: "pointer",
                    color: "#757D87",
                    "&:hover": { color: isDarkMode ? "#387ADB" : "#0B5DD7" },
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    showDropIcon(selectedPost._id);
                  }}
                />
              </div>
              {showDropdown === selectedPost._id && (
                <div
                  className={`${CommunitiesThreadCSS["more-dropdown"]} ${
                    isDarkMode ? CommunitiesThreadCSS.darkModeContainer : ""
                  }`}
                >
                  {userStatus.isMod && (
                    <div
                      className={`${CommunitiesThreadCSS["dropdown-row"]} ${
                        isDarkMode ? CommunitiesThreadCSS.darkModeContainer : ""
                      }`}
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent event from bubbling up
                        props.handleEvent(selectedPost._id, "pin");
                      }}
                    >
                      {selectedPost.pinned ? "Unpin" : "Pin"}
                    </div>
                  )}
                  {selectedPost.creator._id ===
                    JSON.parse(localStorage.getItem("tempUser"))._id && (
                    <div
                      className={`${CommunitiesThreadCSS["dropdown-row"]} ${
                        isDarkMode ? CommunitiesThreadCSS.darkModeContainer : ""
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        props.handleOpenEditPost(true, selectedPost, "main");
                        setShowDropdown(false);
                        props.handleOpenThread();
                      }}
                    >
                      Edit
                    </div>
                  )}
                  {(selectedPost.creator._id ===
                    JSON.parse(localStorage.getItem("tempUser"))._id ||
                    userStatus.isMod) && (
                    <div
                      className={`${CommunitiesThreadCSS["dropdown-row"]} ${
                        isDarkMode ? CommunitiesThreadCSS.darkModeContainer : ""
                      }`}
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent event from bubbling up
                        props.handleAlertMessage(false, null, "delete");
                        setShowDropdown(false);
                        props.handleOpenThread();
                      }}
                    >
                      Delete
                    </div>
                  )}
                  <div
                    className={`${CommunitiesThreadCSS["dropdown-row"]} ${
                      isDarkMode ? CommunitiesThreadCSS.darkModeContainer : ""
                    }`}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent event from bubbling up
                      props.handleAlertMessage(false, null, "report");
                      setShowDropdown(false);
                      props.handleOpenThread();
                    }}
                  >
                    Report
                  </div>
                </div>
              )}
            </div>
          </span>
        </span>

        <p className={CommunitiesThreadCSS["post-date"]}>
          {new Date(parseInt(selectedPost.date) * 1000).toLocaleString()}
        </p>
        <p className={CommunitiesThreadCSS["post-title"]}>
          {selectedPost.title}
        </p>
        <p className={CommunitiesThreadCSS["post-description"]}>
          {selectedPost.description}
        </p>
        <span className={CommunitiesThreadCSS["icon-group"]}>
          <span className={CommunitiesThreadCSS["icon-and-number"]}>
            <ThumbUpIcon
              sx={{
                cursor: "pointer",
                color: props.isPostLikedByCurrentUser(selectedPost)
                  ? isDarkMode
                    ? "#387ADB"
                    : "#0B5DD7"
                  : "#757D87",
                "&:hover": { color: isDarkMode ? "#387ADB" : "#0B5DD7" },
              }}
              onClick={(e) => {
                e.stopPropagation(); // Prevent event from bubbling up
                props.handleEvent(selectedPost._id, "like");
              }}
            />

            <p>{selectedPost.likes !== 0 ? selectedPost.likes : ""}</p>
          </span>
          <span className={CommunitiesThreadCSS["icon-and-number"]}>
            <ChatBubbleIcon
              sx={{
                cursor: "pointer",
                color: "#757D87",
                "&:hover": { color: isDarkMode ? "#387ADB" : "#0B5DD7" },
              }}
            />
            <p>
              {selectedPost.replies.length !== 0
                ? selectedPost.replies.length
                : ""}
            </p>
          </span>
        </span>
      </div>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: "4.55px", width: "328px" },
          "@media screen and (max-width: 480px)": {
            "& .MuiTextField-root": {
              width: "260px",
            },
          },
        }}
        noValidate
        autoComplete="off"
        onSubmit={create}
      >
        <TextField
          id="description"
          type="string"
          InputLabelProps={{
            shrink: false,
            sx: {
              color: isDarkMode ? "#9CA9B9" : "", // Label color
            },
          }}
          placeholder={`Reply to ${
            selectedPost.creator !== null
              ? selectedPost.creator.firstname
              : "[deleted]"
          }`}
          sx={{
            width: 141,
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: isDarkMode ? "#9CA9B9" : "",
            },
            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: isDarkMode ? "#F0F0F0" : "", // Border color on hover
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "#1976d2", // Border color when focused
              },
            "& .MuiOutlinedInput-root:hover": {
              "& .MuiOutlinedInput-input": {
                color: isDarkMode ? "#F0F0F0" : "", // Text color on hover
              },
            },
          }}
          InputProps={{
            style: {
              height: "86px",
            },
            sx: {
              color: isDarkMode ? "#F0F0F0" : "", // Text color
            },
          }}
          variant="outlined"
          multiline
          rows={3}
          value={description}
          onChange={(e) => cleanDescription(e.target.value)}
          error={descriptionStatus ? true : false}
        />
        <span className={CommunitiesThreadCSS["word-count"]}>
          {description.length} / 280
        </span>

        <div className={CommunitiesThreadCSS["signup-bottom"]}>
          <button
            type="submit"
            className={`${CommunitiesThreadCSS["signup-button"]} ${
              isDarkMode ? CommunitiesThreadCSS.darkModeContainer : ""
            }`}
          >
            Reply
          </button>
        </div>
      </Box>
      {selectedPost.replies &&
        selectedPost.replies
          .slice()
          .reverse()
          .map((reply) => (
            <div
              key={reply._id}
              className={`${CommunitiesThreadCSS["post-item"]} ${
                isDarkMode ? CommunitiesThreadCSS.darkModeContainer : ""
              }`}
            >
              <span className={CommunitiesThreadCSS["user-and-edit"]}>
                <p
                  className={`${CommunitiesThreadCSS["post-username"]} ${
                    isDarkMode ? CommunitiesThreadCSS.darkModeContainer : ""
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  style={
                    reply.creator === null
                      ? { color: isDarkMode ? "#F0F0F0" : "#000000" }
                      : null
                  }
                >
                  {reply.creator !== null
                    ? `${reply.creator.firstname} ${reply.creator.lastname}`
                    : "[deleted]"}
                </p>
                <div className={CommunitiesThreadCSS["more-ref"]}>
                  <div>
                    <MoreHorizIcon
                      sx={{
                        cursor: "pointer",
                        color: "#757D87",
                        "&:hover": {
                          color: isDarkMode ? "#387ADB" : "#0B5DD7",
                        },
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        showDropIcon(reply._id);
                      }}
                    />
                  </div>
                  {showDropdown === reply._id && (
                    <div
                      className={`${CommunitiesThreadCSS["more-dropdown"]} ${
                        isDarkMode ? CommunitiesThreadCSS.darkModeContainer : ""
                      }`}
                    >
                      {reply.creator._id ===
                        JSON.parse(localStorage.getItem("tempUser"))._id && (
                        <div
                          className={`${CommunitiesThreadCSS["dropdown-row"]} ${
                            isDarkMode
                              ? CommunitiesThreadCSS.darkModeContainer
                              : ""
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            props.handleOpenEditPost(true, reply, "reply");
                            setShowDropdown(false);
                            props.handleOpenThread();
                          }}
                        >
                          Edit
                        </div>
                      )}
                      {(reply.creator._id ===
                        JSON.parse(localStorage.getItem("tempUser"))._id ||
                        userStatus.isMod) && (
                        <div
                          className={`${CommunitiesThreadCSS["dropdown-row"]} ${
                            isDarkMode
                              ? CommunitiesThreadCSS.darkModeContainer
                              : ""
                          }`}
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent event from bubbling up
                            props.handleAlertMessage(true, reply._id, "delete");
                            setShowDropdown(false);
                            props.handleOpenThread();
                          }}
                        >
                          Delete
                        </div>
                      )}
                      <div
                        className={`${CommunitiesThreadCSS["dropdown-row"]} ${
                          isDarkMode
                            ? CommunitiesThreadCSS.darkModeContainer
                            : ""
                        }`}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent event from bubbling up
                          props.handleAlertMessage(true, reply._id, "report");
                          setShowDropdown(false);
                          props.handleOpenThread();
                        }}
                      >
                        Report
                      </div>
                    </div>
                  )}
                </div>
              </span>
              <p className={CommunitiesThreadCSS["post-date"]}>
                {new Date(parseInt(reply.date) * 1000).toLocaleString()}
              </p>
              <p className={CommunitiesThreadCSS["post-description"]}>
                {reply.description}
              </p>
              <span className={CommunitiesThreadCSS["icon-group"]}>
                <span className={CommunitiesThreadCSS["icon-and-number"]}>
                  <ThumbUpIcon
                    sx={{
                      cursor: "pointer",
                      color: props.isPostLikedByCurrentUser(reply)
                        ? isDarkMode
                          ? "#387ADB"
                          : "#0B5DD7"
                        : "#757D87",
                      "&:hover": { color: isDarkMode ? "#387ADB" : "#0B5DD7" },
                    }}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent event from bubbling up
                      props.handleReplyEvent(
                        selectedPost._id,
                        reply._id,
                        "like"
                      );
                    }}
                  />
                  <p>{reply.likes !== 0 ? reply.likes : ""}</p>
                </span>{" "}
              </span>
            </div>
          ))}
    </div>
  );
}

export default CommunitiesThread;
