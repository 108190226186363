import React from "react";
import EditProfileCSS from "./EditProfile.module.css";
import { get, post, remove } from "services/service";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useDarkMode } from "contexts/DarkModeContext";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useMobile } from "contexts/MobileContext";

function EditProfile(props) {
  const { isDarkMode, setIsDarkMode } = useDarkMode();
  const { isMobile } = useMobile();

  const [firstname, setFirstname] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [firstnameStatus, setFirstnameStatus] = React.useState("");
  const [lastnameStatus, setLastnameStatus] = React.useState("");
  const [userStatus, setUserStatus] = React.useState("");
  const [emailStatus, setEmailStatus] = React.useState("");
  const [passStatus, setPassStatus] = React.useState("");
  const [confirmStatus, setConfirmStatus] = React.useState("");
  const [showPassFields, setShowPassFields] = React.useState(false);
  const [areYouSure, setAreYouSure] = React.useState(false);

  const navigate = useNavigate();

  const create = async (e) => {
    e.preventDefault();
    try {
      if (
        !firstname ||
        !lastname ||
        !username ||
        !email ||
        (password && password.length < 4) ||
        (password && password !== confirmPassword)
      ) {
        if (!firstname) {
          setFirstnameStatus("Please include first name");
        }
        if (!lastname) {
          setLastnameStatus("Please include last name");
        }
        if (!username) {
          setUserStatus("Please include username");
        }
        if (!email) {
          setEmailStatus("Please include email");
        }
        if (password && password.length < 4) {
          setPassStatus("Password must be 8-characters");
        }
        if (password && password !== confirmPassword) {
          setConfirmStatus("Passwords do not match");
        }
      } else {
        const requestData = {
          firstname: firstname,
          lastname: lastname,
          username: username,
          email: email,
        };

        if (password) {
          requestData.password = password;
        }

        let response = await post("/users/update", requestData);

        if (response.data.username !== localStorage.getItem("username")) {
          localStorage.setItem("username", response.data.username);
        }

        if (response.data.email !== localStorage.getItem("email")) {
          localStorage.setItem("email", response.data.email);
        }
        props.handleOpen(false);
      }
    } catch (err) {
      console.log(err.message);
      if (err.response.data.includes("E11000" && "username")) {
        setUserStatus("Username already taken");
      } else if (err.response.data.includes("E11000" && "email")) {
        setEmailStatus("Email already taken");
      } else {
        console.log("Something went wrong");
        return;
      }
    }
  };

  const useOutsideAlerter = (ref) => {
    React.useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          props.handleOpen(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef);

  const cleanFirstname = (value) => {
    setFirstnameStatus("");
    if (value.length <= 50) {
      setFirstname(value);
    }
  };

  const cleanLastname = (value) => {
    setLastnameStatus("");
    if (value.length <= 50) {
      setLastname(value);
    }
  };

  const cleanEmail = (value) => {
    setEmailStatus("");
    setEmail(value.toLowerCase());
  };

  const cleanUser = (value) => {
    setUserStatus("");
    if (value.length <= 15) {
      setUsername(value.toLowerCase());
    }
  };

  const cleanPass = (value) => {
    setPassStatus("");
    if (value.length <= 64) {
      setPassword(value);
    }
  };

  const cleanConfirm = (value) => {
    setConfirmStatus("");
    if (value.length <= 64) {
      setConfirmPassword(value);
    }
  };

  const fetchProfile = async () => {
    let response = await get("/users/my-profile");
    setFirstname(response.data.firstname);
    setLastname(response.data.lastname);
    setUsername(response.data.username);
    setEmail(response.data.email);
  };

  React.useEffect(() => {
    fetchProfile();
  }, []);

  const handleShowPassFields = () => {
    setShowPassFields(!showPassFields);
    if (showPassFields === false) {
      setPassword("");
      setConfirmPassword("");
    }
  };

  const deleteUser = async (e) => {
    e.preventDefault();
    try {
      let response = await remove("/users/delete-user");
      localStorage.clear();
      props.handleOpen(false);
      navigate("/");
      setIsDarkMode(false);
    } catch (error) {
      console.error("Error deleting user:", error);
      // Handle the error, show a message to the user, etc.
    }
  };

  return (
    <div
      className={
        !showPassFields
          ? `${EditProfileCSS["edit-profile"]} ${
              isDarkMode ? EditProfileCSS.darkModeContainer : ""
            }`
          : `${EditProfileCSS["edit-profile-expanded"]} ${
              isDarkMode ? EditProfileCSS.darkModeContainer : ""
            }`
      }
      ref={wrapperRef}
    >
      <h4>Edit Profile</h4>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: "4.55px", width: "328px" },
          "@media screen and (max-width: 480px)": {
            "& .MuiTextField-root": {
              width: "260px", // Width for screens with max-width 900px or less
            },
          },
        }}
        noValidate
        autoComplete="off"
        onSubmit={create}
      >
        <span>
          <TextField
            error={firstnameStatus ? true : false}
            id="first-name"
            type="string"
            label="First Name"
            variant="standard"
            style={{
              width: isMobile ? "120px" : "153.5px",
              height: isMobile ? "54px" : "72px",
              marginRight: "10px",
            }}
            InputLabelProps={{
              shrink: true,
              sx: {
                color: isDarkMode ? "#9CA9B9" : "", // Label color
              },
            }}
            sx={{
              "& .MuiInput-underline:before": {
                borderColor: isDarkMode ? "#9CA9B9 !important" : "",
              },
              "& .MuiInput-underline:hover:before": {
                borderColor: isDarkMode ? "#F0F0F0 !important" : "",
              },
              ".MuiSvgIcon-root": {
                fill: isDarkMode ? "#9CA9B9" : "",
              },
            }}
            InputProps={{
              sx: {
                color: isDarkMode ? "#F0F0F0" : "", // Text color
              },
            }}
            helperText={firstnameStatus && !isMobile ? firstnameStatus : " "}
            value={firstname}
            onChange={(e) => cleanFirstname(e.target.value)}
          />
          <TextField
            error={lastnameStatus ? true : false}
            id="last-name"
            type="string"
            label="Last Name"
            variant="standard"
            style={{
              width: isMobile ? "120px" : "153.5px",
              height: isMobile ? "54px" : "72px",
              marginLeft: "10px",
            }}
            InputLabelProps={{
              shrink: true,
              sx: {
                color: isDarkMode ? "#9CA9B9" : "", // Label color
              },
            }}
            sx={{
              "& .MuiInput-underline:before": {
                borderColor: isDarkMode ? "#9CA9B9 !important" : "",
              },
              "& .MuiInput-underline:hover:before": {
                borderColor: isDarkMode ? "#F0F0F0 !important" : "",
              },
              ".MuiSvgIcon-root": {
                fill: isDarkMode ? "#9CA9B9" : "",
              },
            }}
            InputProps={{
              sx: {
                color: isDarkMode ? "#F0F0F0" : "", // Text color
              },
            }}
            helperText={lastnameStatus && !isMobile ? lastnameStatus : " "}
            value={lastname}
            onChange={(e) => cleanLastname(e.target.value)}
          />
        </span>
        <TextField
          error={emailStatus ? true : false}
          id="email"
          type="email"
          label="Email"
          variant="standard"
          style={{
            height: isMobile ? "54px" : "72px",
          }}
          InputLabelProps={{
            shrink: true,
            sx: {
              color: isDarkMode ? "#9CA9B9" : "", // Label color
            },
          }}
          sx={{
            "& .MuiInput-underline:before": {
              borderColor: isDarkMode ? "#9CA9B9 !important" : "",
            },
            "& .MuiInput-underline:hover:before": {
              borderColor: isDarkMode ? "#F0F0F0 !important" : "",
            },
            ".MuiSvgIcon-root": {
              fill: isDarkMode ? "#9CA9B9" : "",
            },
          }}
          InputProps={{
            sx: {
              color: isDarkMode ? "#F0F0F0" : "", // Text color
            },
          }}
          helperText={
            !isMobile
              ? emailStatus
                ? emailStatus
                : ""
              : emailStatus !== "Please include email"
              ? emailStatus
              : " "
          }
          value={email}
          onChange={(e) => cleanEmail(e.target.value)}
        />
        <TextField
          error={userStatus ? true : false}
          id="username"
          type="string"
          label="Username"
          variant="standard"
          style={{
            height: isMobile ? "54px" : "72px",
          }}
          InputLabelProps={{
            shrink: true,
            sx: {
              color: isDarkMode ? "#9CA9B9" : "", // Label color
            },
          }}
          sx={{
            "& .MuiInput-underline:before": {
              borderColor: isDarkMode ? "#9CA9B9 !important" : "",
            },
            "& .MuiInput-underline:hover:before": {
              borderColor: isDarkMode ? "#F0F0F0 !important" : "",
            },
            ".MuiSvgIcon-root": {
              fill: isDarkMode ? "#9CA9B9" : "",
            },
          }}
          InputProps={{
            sx: {
              color: isDarkMode ? "#F0F0F0" : "", // Text color
            },
          }}
          helperText={
            !isMobile
              ? userStatus
                ? userStatus
                : ""
              : userStatus !== "Please include username"
              ? userStatus
              : " "
          }
          value={username}
          onChange={(e) => cleanUser(e.target.value)}
        />
        <div className={EditProfileCSS["edit-password-header"]}>
          <span className="edit-normal-text">Password</span>

          <span
            onClick={handleShowPassFields}
            className={EditProfileCSS["edit-password-click"]}
          >
            {showPassFields ? "Close" : "Edit"}
          </span>
        </div>
        {!showPassFields && (
          <div className={EditProfileCSS["edit-password-fields"]}>
            <span className={EditProfileCSS["edit-normal-text"]}>
              ************
            </span>
          </div>
        )}
        {showPassFields && (
          <TextField
            error={passStatus ? true : false}
            id="password"
            type="password"
            label="New Password"
            variant="standard"
            style={{
              height: isMobile ? "62px" : "72px",
            }}
            InputLabelProps={{
              shrink: true,
              sx: {
                color: isDarkMode ? "#9CA9B9" : "", // Label color
              },
            }}
            sx={{
              "& .MuiInput-underline:before": {
                borderColor: isDarkMode ? "#9CA9B9 !important" : "",
              },
              "& .MuiInput-underline:hover:before": {
                borderColor: isDarkMode ? "#F0F0F0 !important" : "",
              },
              ".MuiSvgIcon-root": {
                fill: isDarkMode ? "#9CA9B9" : "",
              },
            }}
            InputProps={{
              sx: {
                color: isDarkMode ? "#F0F0F0" : "", // Text color
              },
            }}
            FormHelperTextProps={{
              sx: {
                color: isDarkMode ? "#9CA9B9" : "",
              },
            }}
            helperText="Must be 8-characters"
            value={password}
            onChange={(e) => cleanPass(e.target.value)}
          />
        )}
        {showPassFields && (
          <TextField
            error={confirmStatus ? true : false}
            id="confirm-password"
            type="password"
            label="Confirm New Password"
            variant="standard"
            style={{
              height: isMobile ? "62px" : "72px",
            }}
            InputLabelProps={{
              shrink: true,
              sx: {
                color: isDarkMode ? "#9CA9B9" : "", // Label color
              },
            }}
            sx={{
              "& .MuiInput-underline:before": {
                borderColor: isDarkMode ? "#9CA9B9 !important" : "",
              },
              "& .MuiInput-underline:hover:before": {
                borderColor: isDarkMode ? "#F0F0F0 !important" : "",
              },
              ".MuiSvgIcon-root": {
                fill: isDarkMode ? "#9CA9B9" : "",
              },
            }}
            InputProps={{
              sx: {
                color: isDarkMode ? "#F0F0F0" : "", // Text color
              },
            }}
            helperText={confirmStatus ? confirmStatus : " "}
            value={confirmPassword}
            onChange={(e) => cleanConfirm(e.target.value)}
          />
        )}
        <div className={EditProfileCSS["signup-bottom"]}>
          <div>
            {areYouSure ? (
              <div className={EditProfileCSS["suretext"]}>
                <p id={EditProfileCSS["no-pointer"]}>Are you sure?</p>
                <p
                  className={`${EditProfileCSS["red-text"]} ${
                    isDarkMode ? EditProfileCSS.darkModeContainer : ""
                  }`}
                  onClick={deleteUser}
                >
                  Yes
                </p>
                <p
                  className={`${EditProfileCSS["blue-text"]} ${
                    isDarkMode ? EditProfileCSS.darkModeContainer : ""
                  }`}
                  onClick={() => setAreYouSure(false)}
                >
                  No
                </p>
              </div>
            ) : (
              <div className={EditProfileCSS["suretext"]}>
                <p
                  className={`${EditProfileCSS["red-text-2"]} ${
                    isDarkMode ? EditProfileCSS.darkModeContainer : ""
                  }`}
                  onClick={() => setAreYouSure(true)}
                >
                  Delete profile
                </p>
              </div>
            )}
          </div>
          <button
            type="submit"
            className={`${EditProfileCSS["signup-button"]} ${
              isDarkMode ? EditProfileCSS.darkModeContainer : ""
            }`}
          >
            Update
          </button>
        </div>
      </Box>
    </div>
  );
}

export default EditProfile;
