import React, { useState, useRef, useEffect } from "react";
import SignupCSS from "./Signup.module.css";
import { useNavigate } from "react-router-dom";
import { post } from "services/service";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useMobile } from "contexts/MobileContext";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { MuiOtpInput } from "mui-one-time-password-input";
import CircularProgress from "@mui/material/CircularProgress";
import FormHelperText from "@mui/material/FormHelperText";

function Signup(props) {
  const { isMobile } = useMobile();

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [firstnameStatus, setFirstnameStatus] = useState("");
  const [lastnameStatus, setLastnameStatus] = useState("");
  const [userStatus, setUserStatus] = useState("");
  const [emailStatus, setEmailStatus] = useState("");
  const [passStatus, setPassStatus] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [trueOtp, setTrueOtp] = useState("");
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(30);
  const [resent, setResent] = useState(true);
  const [verified, setVerified] = useState(false);
  const [codeStatus, setCodeStatus] = useState(false);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (trueOtp) {
      const otpAsNumber = Number(otp);
      const trueOtpAsNumber = Number(trueOtp);
      if (otpAsNumber === trueOtpAsNumber) {
        setVerified(true);
      } else {
        setVerified(false);
      }
    }
  }, [otp, trueOtp]);

  useEffect(() => {
    if (codeStatus) {
      const timer = setTimeout(() => {
        setCodeStatus(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [codeStatus]);

  const create = async (e) => {
    e.preventDefault();
    if (verified) {
      localStorage.clear();
      setLoading(true);
      try {
        let response = await post("/users/signup", {
          firstname: firstname,
          lastname: lastname,
          username: username,
          email: email,
          password: password,
        });
        if (response.data) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("tempUser", JSON.stringify(response.data.user));
          props.open(false);
          navigate("/dashboard/portfolio");
        }
      } catch (err) {
        console.log(err.message);
        setCodeStatus(true);
      } finally {
        setLoading(false);
      }
    } else {
      setCodeStatus(true);
    }
  };

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          props.open(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const cleanFirstname = (value) => {
    setFirstnameStatus("");
    if (value.length <= 50) {
      setFirstname(value);
    }
  };

  const cleanLastname = (value) => {
    setLastnameStatus("");
    if (value.length <= 50) {
      setLastname(value);
    }
  };

  const cleanEmail = (value) => {
    setEmailStatus("");
    setEmail(value.toLowerCase());
  };

  const cleanUser = (value) => {
    setUserStatus("");
    // Regular expression to allow only alphanumeric characters and underscores
    const regex = /^[a-zA-Z0-9_.]+$/;
    if (value === "" || (value.length <= 15 && regex.test(value))) {
      setUsername(value.toLowerCase());
    }
  };

  const cleanPass = (value) => {
    setPassStatus("");
    if (value.length <= 64) {
      setPassword(value);
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const signupCheck = async (e) => {
    if (e) {
      e.preventDefault();
    }
    //// REMOVE LATER ////
    // if (username !== "miguel" && username !== "luis") {
    //   setUserStatus("signup is currently limited");
    //   return;
    // }
    //////////////////////
    let hasError = false;
    if (!firstname) {
      setFirstnameStatus("Please include first name");
      hasError = true;
    }
    if (!lastname) {
      setLastnameStatus("Please include last name");
      hasError = true;
    }
    if (!username || username === "[deleted]") {
      setUserStatus("Please include username");
      hasError = true;
    }
    if (username.length < 4) {
      setUserStatus("Must be at least 4-characters");
      hasError = true;
    }
    if (!email || !email.includes("@")) {
      setEmailStatus("Please include email");
      hasError = true;
    }
    if (password.length < 8) {
      setPassStatus("Must be at least 8-characters");
      hasError = true;
    }
    if (!hasError) {
      setLoading(true);
      try {
        const response = await post("/users/signup-check", {
          firstname: firstname,
          lastname: lastname,
          username: username,
          email: email,
          password: password,
        });
        if (response.data && response.status === 200) {
          setTrueOtp(response.data.verificationCode);
        }
      } catch (err) {
        if (err.response.data.error.toLowerCase().includes("email")) {
          setEmailStatus(err.response.data.error);
        } else if (err.response.data.error.toLowerCase().includes("username")) {
          setUserStatus(err.response.data.error);
        } else {
          setUserStatus("Something went wrong");
        }
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const handleOtpChange = (newValue) => {
    setOtp(newValue);
  };

  useEffect(() => {
    if (trueOtp && resent && timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => {
        clearInterval(countdown);
      };
    } else {
      setTimer(30);
    }

    if (timer === 0) {
      setResent(false);
    }
  }, [trueOtp, resent, timer]);

  const handleResend = () => {
    setResent(true);
    signupCheck();
  };

  return (
    <div
      className={SignupCSS.signup}
      ref={wrapperRef}
      style={{ top: trueOtp ? (isMobile ? "" : "223px") : "" }}
    >
      {!trueOtp ? <h4>Create your Account</h4> : <h4>Verify your email</h4>}
      {!trueOtp ? (
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": {
              m: "4.55px",
              width: "328px",
            },
            "@media screen and (max-width: 480px)": {
              "& .MuiTextField-root": {
                width: "260px", // Width for screens with max-width 900px or less
              },
            },
          }}
          noValidate
          autoComplete="off"
          onSubmit={signupCheck}
        >
          <span className={SignupCSS["textfield-container"]}>
            <TextField
              error={firstnameStatus ? true : false}
              id="first-name"
              type="string"
              label="First Name"
              variant="standard"
              style={{
                width: isMobile ? "120px" : "153.5px",
                height: isMobile ? "54px" : "62px",
                marginRight: "10px",
              }}
              helperText={firstnameStatus && !isMobile ? firstnameStatus : " "}
              value={firstname}
              onChange={(e) => cleanFirstname(e.target.value)}
            />
            <TextField
              error={lastnameStatus ? true : false}
              id="last-name"
              type="string"
              label="Last Name"
              variant="standard"
              style={{
                width: isMobile ? "120px" : "153.5px",
                height: isMobile ? "54px" : "62px",
                marginLeft: "10px",
              }}
              helperText={lastnameStatus && !isMobile ? lastnameStatus : " "}
              value={lastname}
              onChange={(e) => cleanLastname(e.target.value)}
            />
          </span>
          <TextField
            error={emailStatus ? true : false}
            id="email"
            type="email"
            label="Email"
            variant="standard"
            style={{
              height: isMobile ? "54px" : "62px",
            }}
            helperText={
              !isMobile
                ? emailStatus
                  ? emailStatus
                  : ""
                : emailStatus !== "Please include email"
                ? emailStatus
                : " "
            }
            value={email}
            onChange={(e) => cleanEmail(e.target.value)}
          />
          <TextField
            error={userStatus ? true : false}
            id="username"
            type="string"
            label="Username"
            variant="standard"
            style={{
              height: isMobile ? "54px" : "62px",
            }}
            helperText={
              !isMobile
                ? userStatus
                  ? userStatus
                  : ""
                : userStatus !== "Please include username"
                ? userStatus
                : " "
            }
            value={username}
            onChange={(e) => cleanUser(e.target.value)}
          />
          <TextField
            error={passStatus ? true : false}
            id="password"
            type={showPassword ? "text" : "password"}
            label="Password"
            variant="standard"
            style={{
              height: "62px",
            }}
            helperText="Must be at least 8-characters"
            value={password}
            onChange={(e) => cleanPass(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    onMouseUp={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div className={SignupCSS["signup-bottom"]}>
            <p>
              Already have an account?
              <span
                onClick={() => {
                  props.form("login");
                }}
              >
                {" "}
                Log in
              </span>
            </p>

            <button
              type="submit"
              className={SignupCSS["signup-button"]}
              disabled={loading}
            >
              {loading ? <CircularProgress size={20} /> : "Submit"}
            </button>
          </div>
        </Box>
      ) : (
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": {
              m: "4.55px",
              width: "328px",
            },
            "@media screen and (max-width: 480px)": {
              "& .MuiTextField-root": {
                width: "260px",
              },
            },
          }}
          noValidate
          autoComplete="off"
          onSubmit={create}
        >
          <div style={{ width: "260px" }}>
            <MuiOtpInput
              length={4}
              gap={0}
              spacing={0}
              value={otp}
              onChange={handleOtpChange}
              style={{
                margin: "0 auto",
                borderColor: "white",
              }}
            />
            <FormHelperText
              error={true}
              sx={{
                textAlign: "center",
                height: "12px",
                marginTop: "-5px",
              }}
            >
              {codeStatus && "Incorrect code"}
            </FormHelperText>
          </div>
          <button
            type="submit"
            className={SignupCSS["reset-button-alt"]}
            disabled={loading}
          >
            {loading ? <CircularProgress size={20} /> : "Verify Account"}
          </button>
          <p className={SignupCSS["login"]}>
            An email has been sent with a verification code
            <br></br>
            Did not receive the email?{" "}
            <span
              onClick={handleResend}
              className={SignupCSS["resend"]}
              style={{
                color: !resent ? "" : "#000000",
                cursor: !resent ? "pointer" : "text",
              }}
            >
              {!resent ? "Resend" : timer}
            </span>
          </p>
        </Box>
      )}
    </div>
  );
}

export default Signup;
