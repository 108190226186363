import React, { useEffect, useState } from "react";
import { getHistoryExcel } from "services/service";

import { useDarkMode } from "contexts/DarkModeContext";
import { formatNumber } from "utils/formatNumber";

import DownloadIcon from "@mui/icons-material/Download";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import GeneralReportCSS from "./GeneralReport.module.css";

function GeneralReport(props) {
  const { isDarkMode } = useDarkMode();
  const [currentTable, setCurrentTable] = useState();
  const [loading, setLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState("");
  const [sortColumn, setSortColumn] = useState("");

  useEffect(() => {
    setCurrentTable(Object.keys(props.general)[0]);
  }, [props.general]);

  const keys = ["Date", "Open", "High", "Low", "Close", "Adj Close", "Volume"];

  async function fetchExcelReport(
    e,
    tickers,
    period,
    frequency,
    startDate,
    endDate
  ) {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await getHistoryExcel(
        tickers,
        period,
        startDate,
        endDate,
        frequency
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "unitrade_report.xlsx");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error:", error);
    }

    setLoading(false);
  }

  const handleSort = (column) => {
    if (column === "Date") {
      if (sortColumn === "Date") {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      } else {
        setSortColumn("Date");
        setSortOrder("asc");
      }
    } else {
      if (sortColumn === column) {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      } else {
        setSortColumn(column);
        setSortOrder("asc");
      }
    }
  };

  let sortedData = [];

  if (props && props.general && props.general[currentTable]) {
    sortedData = props.general[currentTable]["Date"]
      .map((date, index) => ({
        date,
        values: keys.reduce((acc, key) => {
          acc[key] = props.general[currentTable][key][index];
          return acc;
        }, {}),
      }))
      .sort((a, b) => {
        if (sortColumn === "Date") {
          const aValue = new Date(a.date);
          const bValue = new Date(b.date);

          if (sortOrder === "asc") {
            return aValue - bValue;
          } else if (sortOrder === "desc") {
            return bValue - aValue;
          }
        } else {
          const aValue = a.values[sortColumn];
          const bValue = b.values[sortColumn];

          if (sortOrder === "asc") {
            return aValue - bValue;
          } else if (sortOrder === "desc") {
            return bValue - aValue;
          }
        }

        return 0;
      });
  }

  return (
    props.general[currentTable] && (
      <div
        className={`${GeneralReportCSS["report"]} ${
          isDarkMode ? GeneralReportCSS.darkModeContainer : ""
        }`}
      >
        {/* <div className={GeneralReportCSS["report-container"]}> */}
        <div className={GeneralReportCSS["report-top"]}>
          <h4
            className={`${GeneralReportCSS["h4-watchlist"]} ${
              isDarkMode ? GeneralReportCSS.darkModeContainer : ""
            }`}
          >
            Reports
          </h4>
          <button
            className={`${GeneralReportCSS["report-download"]} ${
              isDarkMode ? GeneralReportCSS.darkModeContainer : ""
            }`}
            disabled={loading}
            onClick={(e) =>
              fetchExcelReport(
                e,
                props.currentReportParams.tickers,
                props.currentReportParams.period,
                props.currentReportParams.frequency,
                props.currentReportParams.startDate,
                props.currentReportParams.endDate
              )
            }
          >
            {loading ? (
              <CircularProgress size={30} />
            ) : (
              <DownloadIcon
                fontSize="large"
                sx={{ color: isDarkMode ? "#F0F0F0" : "" }}
              />
            )}
          </button>
        </div>
        <div className={GeneralReportCSS["analytics-tab-header-container"]}>
          <button
            className={`${GeneralReportCSS["analytics-tab-header"]} ${
              isDarkMode ? GeneralReportCSS.darkModeContainer : ""
            }`}
            style={{
              boxShadow: isDarkMode
                ? "inset 0px -4px 0px 0px #387ADB"
                : "inset 0px -4px 0px 0px #0B5DD7",
            }}
          >
            Historical Data
          </button>
        </div>
        <div className={GeneralReportCSS["analytics-stock-header-container"]}>
          {Object.keys(props.general).map((ticker) => (
            <button
              className={`${GeneralReportCSS["analytics-stock-header"]} ${
                isDarkMode ? GeneralReportCSS.darkModeContainer : ""
              }`}
              onClick={() => setCurrentTable(ticker)}
              key={ticker}
              style={{
                color:
                  currentTable === ticker
                    ? isDarkMode
                      ? "#387ADB"
                      : "#0B5DD7"
                    : "",
              }}
            >
              {ticker}
            </button>
          ))}
        </div>

        <div
          className={`${GeneralReportCSS["table-container-hist"]} ${
            isDarkMode ? GeneralReportCSS.darkModeContainer : ""
          }`}
        >
          <table>
            <thead>
              <tr>
                {keys.map((key) => (
                  <th key={key} onClick={() => handleSort(key)}>
                    <span
                      className={`${GeneralReportCSS["report-header"]} ${
                        isDarkMode ? GeneralReportCSS.darkModeContainer : ""
                      }`}
                    >
                      {key}
                      <div className={GeneralReportCSS["arrow-container"]}>
                        {sortColumn === key && sortOrder === "asc" && (
                          <ArrowDropUpIcon />
                        )}
                        {sortColumn === key && sortOrder === "desc" && (
                          <ArrowDropDownIcon />
                        )}
                      </div>
                    </span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {sortedData.map((row) => (
                <tr
                  key={row.date}
                  className={`${GeneralReportCSS["report-table-row"]} ${
                    isDarkMode ? GeneralReportCSS.darkModeContainer : ""
                  }`}
                >
                  {keys.map((key) => {
                    if (
                      ["Open", "High", "Low", "Close", "Adj Close"].includes(
                        key
                      )
                    ) {
                      return <td key={key}>{formatNumber(row.values[key])}</td>;
                    } else {
                      return <td key={key}>{row.values[key]}</td>;
                    }
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* </div> */}
      </div>
    )
  );
}

export default GeneralReport;
