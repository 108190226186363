import React, { useState, useEffect, useRef } from "react";

import { useMobile } from "contexts/MobileContext";
import { useDarkMode } from "contexts/DarkModeContext";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import CommunitiesCreateCSS from "./CommunitiesCreate.module.css";

function CommunitiesCreate(props) {
  const { isDarkMode } = useDarkMode();
  const { isMobile } = useMobile();

  const [communityName, setCommunityName] = useState(
    props.edit === true && props.currentCommunity.community.name
      ? props.currentCommunity.community.name
      : ""
  );
  const [communityTicker, setCommunityTicker] = useState(
    props.edit === true && props.currentCommunity.community.ticker
      ? props.currentCommunity.community.ticker
      : ""
  );
  const [communityNameStatus, setCommunityNameStatus] = useState("");
  const [communityTickerStatus, setCommunityTickerStatus] = useState("");
  const [description, setDescription] = useState(
    props.edit === true && props.currentCommunity.community.description
      ? props.currentCommunity.community.description
      : ""
  );
  const [descriptionStatus, setDescriptionStatus] = useState(false);
  const [publicCommunity, setPublicCommunity] = useState(false);
  const [areYouSure, setAreYouSure] = useState(false);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          props.handleOpen(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const create = async (e) => {
    e.preventDefault();
    try {
      if (!communityName) {
        setCommunityNameStatus("Please enter a valid name");
      } else if (!communityTicker || communityTicker.length > 4) {
        setCommunityTickerStatus("Please enter a valid ticker");
      } else {
        if (props.edit === true) {
          const updatedCommunity = {
            id: props.currentCommunity.community._id,
            name: communityName,
            ticker: communityTicker,
            description: description,
          };
          props.updateCommunity(updatedCommunity);
        } else {
          const newCommunity = {
            name: communityName,
            ticker: communityTicker,
            description: description,
            public: publicCommunity,
          };
          props.create(newCommunity);
        }
        props.handleOpen(false);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const cleanCommunityName = (value) => {
    setCommunityNameStatus("");
    if (value.length <= 20) {
      setCommunityName(value);
    }
  };

  const cleanCommunityTicker = (value) => {
    setCommunityTickerStatus("");
    if (value.length <= 4) {
      setCommunityTicker(value.toUpperCase());
    }
  };

  const cleanDescription = (value) => {
    setDescriptionStatus(false);
    if (value.length <= 120) {
      setDescription(value);
    }
  };

  const handlePublicCommunity = (value) => {
    setPublicCommunity(value);
  };

  const deleteCommunity = async (e) => {
    e.preventDefault();
    try {
      props.deleteCommunity(props.currentCommunity.community._id);
      props.handleOpen(false);
    } catch (error) {
      console.error("Error deleting Community:", error);
    }
  };

  return (
    <div
      className={`${CommunitiesCreateCSS["communities-create"]} ${
        isDarkMode ? CommunitiesCreateCSS.darkModeContainer : ""
      }`}
      ref={wrapperRef}
    >
      <h4>{props.edit === true ? "Edit Community" : "Create Community"}</h4>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: "4.55px", width: "328px" },
          "@media screen and (max-width: 480px)": {
            "& .MuiTextField-root": {
              width: "260px", // Width for screens with max-width 900px or less
            },
          },
        }}
        noValidate
        autoComplete="off"
        onSubmit={create}
      >
        <TextField
          error={communityNameStatus ? true : false}
          id="first-name"
          type="string"
          label="Community Name"
          variant="standard"
          style={{
            height: isMobile ? "54px" : "72px",
          }}
          InputLabelProps={{
            shrink: true,
            sx: {
              color: isDarkMode ? "#9CA9B9" : "", // Label color
            },
          }}
          sx={{
            "& .MuiInput-underline:before": {
              borderColor: isDarkMode ? "#9CA9B9 !important" : "",
            },
            "& .MuiInput-underline:hover:before": {
              borderColor: isDarkMode ? "#F0F0F0 !important" : "",
            },
            ".MuiSvgIcon-root": {
              fill: isDarkMode ? "#9CA9B9" : "",
            },
          }}
          InputProps={{
            sx: {
              color: isDarkMode ? "#F0F0F0" : "", // Text color
            },
          }}
          helperText={
            communityNameStatus && !isMobile ? communityNameStatus : " "
          }
          value={communityName}
          onChange={(e) => cleanCommunityName(e.target.value)}
        />
        <TextField
          error={communityTickerStatus ? true : false}
          id="last-name"
          type="string"
          label="Community Ticker"
          variant="standard"
          style={{
            height: isMobile ? "54px" : "72px",
          }}
          InputLabelProps={{
            shrink: true,
            sx: {
              color: isDarkMode ? "#9CA9B9" : "", // Label color
            },
          }}
          sx={{
            "& .MuiInput-underline:before": {
              borderColor: isDarkMode ? "#9CA9B9 !important" : "",
            },
            "& .MuiInput-underline:hover:before": {
              borderColor: isDarkMode ? "#F0F0F0 !important" : "",
            },
            ".MuiSvgIcon-root": {
              fill: isDarkMode ? "#9CA9B9" : "",
            },
          }}
          InputProps={{
            sx: {
              color: isDarkMode ? "#F0F0F0" : "", // Text color
            },
          }}
          helperText={
            communityTickerStatus && !isMobile ? communityTickerStatus : " "
          }
          value={communityTicker}
          onChange={(e) => cleanCommunityTicker(e.target.value)}
        />
        <TextField
          id="description"
          label={
            <span className={CommunitiesCreateCSS["custom-label-text"]}>
              Description
            </span>
          }
          type="string"
          InputLabelProps={{
            shrink: true,
            sx: {
              color: isDarkMode ? "#9CA9B9" : "", // Label color
            },
          }}
          sx={{
            width: 141,
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: isDarkMode ? "#9CA9B9" : "",
            },
            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: isDarkMode ? "#F0F0F0" : "", // Border color on hover
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "#1976d2", // Border color when focused
              },
            "& .MuiOutlinedInput-root:hover": {
              "& .MuiOutlinedInput-input": {
                color: isDarkMode ? "#F0F0F0" : "", // Text color on hover
              },
            },
          }}
          InputProps={{
            style: {
              height: "86px",
            },
            sx: {
              color: isDarkMode ? "#F0F0F0" : "", // Text color
            },
          }}
          variant="outlined"
          multiline
          rows={3}
          value={description}
          onChange={(e) => cleanDescription(e.target.value)}
          error={descriptionStatus ? true : false}
        />

        <div
          className={
            props.edit === true
              ? CommunitiesCreateCSS["signup-bottom-alt"]
              : CommunitiesCreateCSS["signup-bottom"]
          }
        >
          {/* <FormControl sx={{ marginTop: 1, marginRight: 2 }}>
            <FormLabel
              id="demo-radio-buttons-group-label"
              sx={{
                color: isDarkMode ? "#9CA9B9" : "#606060",
                "&.Mui-focused": {
                  color: isDarkMode ? "#9CA9B9" : "#606060",
                },
              }}
            >
              Community Type
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={false}
              name="radio-buttons-group"
              value={publicCommunity}
              onChange={(e) => handlePublicCommunity(e.target.value)}
              sx={{
                color: isDarkMode ? "#F0F0F0" : "",
              }}
            >
              <FormControlLabel
                value={true}
                control={
                  <Radio
                    sx={{
                      color: isDarkMode ? "#9CA9B9" : "",
                    }}
                  />
                }
                label="Public"
              />
              <FormControlLabel
                value={false}
                control={
                  <Radio
                    sx={{
                      color: isDarkMode ? "#9CA9B9" : "",
                    }}
                  />
                }
                label="Private"
              />
            </RadioGroup>
          </FormControl> */}
          {props.edit === true && (
            <div>
              {areYouSure ? (
                <div className={CommunitiesCreateCSS["suretext"]}>
                  <p id={CommunitiesCreateCSS["no-pointer"]}>Are you sure?</p>
                  <p
                    className={`${CommunitiesCreateCSS["red-text"]} ${
                      isDarkMode ? CommunitiesCreateCSS.darkModeContainer : ""
                    }`}
                    onClick={deleteCommunity}
                  >
                    Yes
                  </p>
                  <p
                    className={`${CommunitiesCreateCSS["blue-text"]} ${
                      isDarkMode ? CommunitiesCreateCSS.darkModeContainer : ""
                    }`}
                    onClick={() => setAreYouSure(false)}
                  >
                    No
                  </p>
                </div>
              ) : (
                <div className={CommunitiesCreateCSS["suretext"]}>
                  <p
                    className={`${CommunitiesCreateCSS["red-text-2"]} ${
                      isDarkMode ? CommunitiesCreateCSS.darkModeContainer : ""
                    }`}
                    onClick={() => setAreYouSure(true)}
                  >
                    Delete Community
                  </p>
                </div>
              )}
            </div>
          )}
          <button
            type="submit"
            className={`${CommunitiesCreateCSS["signup-button"]} ${
              isDarkMode ? CommunitiesCreateCSS.darkModeContainer : ""
            }`}
          >
            {props.edit === true ? "Update" : "Create"}
          </button>
        </div>
      </Box>
    </div>
  );
}

export default CommunitiesCreate;
