import React, { useState } from "react";

import { useMobile } from "contexts/MobileContext";
import { useSubTab } from "contexts/SubTabContext";
import { useDarkMode } from "contexts/DarkModeContext";
import { useColorPreference } from "contexts/ColorPreferenceContext";
import { createCalculatePriceColor } from "utils/colorUtils";
import { formatNumber } from "utils/formatNumber";

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import PortfolioCSS from "./Portfolio.module.css";
import Reset from "./Reset";

function PortfolioLeft(props) {
  const { isMobile } = useMobile();
  const { subTab, setSubTab } = useSubTab();
  const { isDarkMode } = useDarkMode();
  const { isAlternativeColor } = useColorPreference();
  const calculateColor = createCalculatePriceColor(
    isDarkMode,
    isAlternativeColor
  );
  const positiveColor = calculateColor(true);
  const negativeColor = calculateColor(false);

  const {
    tempPortfolio,
    currentAccount: {
      portfolio: { _id: portfolioId, buyingPower, cash },
      portfolioData: {
        totalMarketValue,
        portfolioBalance,
        marketChange,
        percentChange,
      },
    },
    handleAccountChange,
  } = props;

  const [openReset, setOpenReset] = useState(false);

  const click = (data) => {
    setOpenReset(data);
  };

  return (
    <div className={PortfolioCSS["portfolio-left"]}>
      {!isMobile && (
        <h2
          className={`${PortfolioCSS["tab-heading"]} ${
            isDarkMode ? PortfolioCSS.darkModeContainer : ""
          }`}
        >
          Portfolio
        </h2>
      )}
      {tempPortfolio.portfolios.length > 0 && (
        <div className={PortfolioCSS["account-information"]}>
          <FormControl
            sx={{
              marginBottom: 2,
              width: "100%",
              "& .MuiInput-underline:before": {
                borderColor: isDarkMode ? "#9CA9B9" : "",
              },
              "& .MuiInput-underline:hover:before": {
                borderColor: isDarkMode ? "#F0F0F0 !important" : "",
              },
              "& .MuiInput-underline:hover:after": {
                borderColor: isDarkMode ? "#F0F0F0 !important" : "",
              },
              ".MuiSvgIcon-root": {
                fill: isDarkMode ? "#9CA9B9" : "",
              },
            }}
          >
            <Select
              value={portfolioId}
              onChange={handleAccountChange}
              variant="standard"
              MenuProps={{
                MenuListProps: {
                  autoFocusItem: null,
                },
                PaperProps: {
                  sx: {
                    color: isDarkMode ? "#F0F0F0" : "",
                    bgcolor: isDarkMode ? "#272835" : "",
                  },
                },
              }}
              sx={{ color: isDarkMode ? "#F0F0F0" : "" }}
              onClose={() => {
                setTimeout(() => {
                  document.activeElement.blur();
                }, 0);
              }}
            >
              {tempPortfolio.portfolios.map((portfolio) => (
                <MenuItem key={portfolio._id} value={portfolio._id}>
                  <h5>{portfolio.name}</h5>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <h4
            className={`${PortfolioCSS["h4-portfolio"]} ${
              isDarkMode ? PortfolioCSS.darkModeContainer : ""
            }`}
          >
            {formatNumber(portfolioBalance)}
          </h4>

          <p className={PortfolioCSS["account-value"]}>Account Value (USD)</p>

          <div className={PortfolioCSS["quick-price"]}>
            <p
              className={PortfolioCSS["secondary-price"]}
              style={
                marketChange > 0
                  ? { color: positiveColor }
                  : marketChange < 0
                  ? { color: negativeColor }
                  : { color: "#757D87" }
              }
            >
              {formatNumber(marketChange)}
            </p>
            <p
              className={PortfolioCSS["secondary-price"]}
              style={
                marketChange > 0
                  ? { color: positiveColor }
                  : marketChange < 0
                  ? { color: negativeColor }
                  : { color: "#757D87" }
              }
            >
              {formatNumber(percentChange)}%
            </p>
          </div>

          <div
            className={`${PortfolioCSS["account-value-summary"]} ${
              isDarkMode ? PortfolioCSS.darkModeContainer : ""
            }`}
          >
            <p>
              <span>Buying Power</span>
              <span>{formatNumber(buyingPower)}</span>
            </p>
            <p>
              <span>Positions</span>
              <span>{formatNumber(totalMarketValue)}</span>
            </p>
            <p>
              <span>Cash</span>
              <span>{formatNumber(cash)}</span>
            </p>
          </div>
          <div className={PortfolioCSS["reset-div"]}>
            <span
              className={`${PortfolioCSS["reset-button"]} ${
                isDarkMode ? PortfolioCSS.darkModeContainer : ""
              }`}
              onClick={() => setOpenReset(true)}
            >
              Reset
            </span>
            {isMobile && (
              <span
                className={`${PortfolioCSS["detail-button"]} ${
                  isDarkMode ? PortfolioCSS.darkModeContainer : ""
                }`}
                onClick={() => setSubTab(!subTab)}
              >
                Detail
              </span>
            )}
          </div>
          {openReset && portfolioId && (
            <Reset open={click} portfolioId={portfolioId} isUser={true} />
          )}
          {openReset && <div className="faded-background"></div>}
        </div>
      )}
    </div>
  );
}
export default PortfolioLeft;
