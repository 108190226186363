import React, { useEffect, useState } from "react";
import WatchlistCSS from "./Watchlist.module.css";
import { useDarkMode } from "contexts/DarkModeContext";
import { useColorPreference } from "contexts/ColorPreferenceContext";
import { createCalculatePriceColor } from "utils/colorUtils";
import Searchbar from "components/ui/Searchbar";
import star from "assets/star.png";
import starselected from "assets/starselected.png";
import stardark from "assets/stardark.png";
import starselecteddark from "assets/starselecteddark.png";
import { useMobile } from "contexts/MobileContext";
import { formatNumber } from "utils/formatNumber";
import CircularProgress from "@mui/material/CircularProgress";
import { useSubTab } from "contexts/SubTabContext";

function WatchlistLeft(props) {
  const {
    tempWatchlist,
    verify,
    updateTickerList,
    currentClick,
    watchlistClick,
    loading,
    placeholderStats,
    currentList,
  } = props;

  const { current, placeholder, watchlist } = tempWatchlist;

  const {
    main: loadingMain,
    update: loadingUpdate,
    // watchlist: loadingWatchlist,
  } = loading;

  const { isMobile } = useMobile();
  const { subTab, setSubTab } = useSubTab();
  const { isDarkMode } = useDarkMode();
  const { isAlternativeColor } = useColorPreference();
  const calculateColor = createCalculatePriceColor(
    isDarkMode,
    isAlternativeColor
  );
  const positiveColor = calculateColor(true);
  const negativeColor = calculateColor(false);

  return (
    <div className={WatchlistCSS["watchlist-left"]}>
      {!isMobile && (
        <h2
          className={`${WatchlistCSS["tab-heading"]} ${
            isDarkMode ? WatchlistCSS.darkModeContainer : ""
          }`}
        >
          Watchlist
        </h2>
      )}
      <div className={WatchlistCSS["watchlist-search"]}>
        <Searchbar
          verify={verify}
          placeholderStats={placeholderStats}
          placeholder={placeholder}
          submitOnEnter={true}
          loading={loadingMain || loadingUpdate}
        />
        {loadingMain || loadingUpdate ? (
          <CircularProgress
            size={22}
            style={{ marginLeft: 20, marginTop: 8 }}
          />
        ) : (
          <img
            onClick={() => {
              if (!loadingMain || !loadingUpdate) {
                updateTickerList(placeholder, true);
              }
            }}
            src={
              watchlist &&
              Array.isArray(watchlist) &&
              watchlist.some((item) => item.symbol.includes(placeholder))
                ? isDarkMode
                  ? starselecteddark
                  : starselected
                : isDarkMode
                ? stardark
                : star
            }
            height="40px"
            width="40px"
            // height="32.36px"
            // width="33.11px"
            alt="star"
          />
        )}
        {!loadingMain && !loadingUpdate && isMobile && (
          <span
            className={`${WatchlistCSS["more-button"]} ${
              isDarkMode ? WatchlistCSS.darkModeContainer : ""
            }`}
            onClick={() => setSubTab(!subTab)}
          >
            View More
          </span>
        )}
      </div>
      <div className={WatchlistCSS["watchlist-button-container"]}>
        <button
          onClick={(e) => currentClick(e.target.value)}
          className={
            current === "watchlist"
              ? `${WatchlistCSS["watchlist-button-selected"]} ${
                  isDarkMode ? WatchlistCSS.darkModeContainer : ""
                }`
              : WatchlistCSS["watchlist-button"]
          }
          value="watchlist"
        >
          Watchlist
        </button>
        <button
          onClick={(e) => currentClick(e.target.value)}
          className={
            current === "recent"
              ? `${WatchlistCSS["watchlist-button-selected"]} ${
                  isDarkMode ? WatchlistCSS.darkModeContainer : ""
                }`
              : WatchlistCSS["watchlist-button"]
          }
          value="recent"
        >
          Recent
        </button>
      </div>
      <div className={WatchlistCSS["list-container"]}>
        {currentList && currentList && Array.isArray(currentList)
          ? currentList
              .slice()
              .reverse()
              .map((item) => (
                <div
                  className={WatchlistCSS["watchlist-row-element"]}
                  key={item.symbol}
                  onClick={() => watchlistClick(item)}
                  style={
                    placeholder === item.symbol
                      ? {
                          backgroundColor: isDarkMode ? "#272835" : "#FFFFFF",
                        }
                      : { backgroundColor: "" }
                  }
                >
                  <div
                    className={WatchlistCSS["watchlist-row-subelement-left"]}
                  >
                    <span
                      className={`${WatchlistCSS["subelement-ticker"]} ${
                        isDarkMode ? WatchlistCSS.darkModeContainer : ""
                      }`}
                    >
                      {item.symbol}
                    </span>
                    <span className={WatchlistCSS["subelement-shortname"]}>
                      {item.longName}
                    </span>
                  </div>
                  <div
                    className={WatchlistCSS["watchlist-row-subelement-right"]}
                  >
                    <span
                      className={`${WatchlistCSS["subelement-price"]} ${
                        isDarkMode ? WatchlistCSS.darkModeContainer : ""
                      }`}
                    >
                      {formatNumber(item.regularMarketPrice)}
                    </span>
                    <span
                      className={WatchlistCSS["subelement-change"]}
                      style={
                        item.regularMarketChange > 0
                          ? { color: positiveColor }
                          : item.regularMarketChange < 0
                          ? { color: negativeColor }
                          : { color: "#757D87" }
                      }
                    >
                      {formatNumber(item.regularMarketChange)} (
                      {formatNumber(item.regularMarketChangePercent)}%)
                    </span>
                  </div>
                </div>
              ))
          : ""}
        {!loadingMain &&
        currentList &&
        Array.isArray(currentList) &&
        (!currentList || currentList.length === 0) &&
        current === "watchlist" ? (
          <p>Your watchlist is empty</p>
        ) : (
          ""
        )}
        {!loadingMain &&
        currentList &&
        Array.isArray(currentList) &&
        (!currentList || currentList.length === 0) &&
        current === "recent" ? (
          <p>Your recent searches will appear here</p>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
export default WatchlistLeft;
