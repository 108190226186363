import React, { useEffect, useState } from "react";

import { useDarkMode } from "contexts/DarkModeContext";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import CommunitiesMembersCSS from "./CommunitiesMembers.module.css";
import CommunitiesDiscussionCSS from "./CommunitiesDiscussion.module.css";
import CommunitiesAlertUserMessage from "./CommunitiesAlertUserMessage";

function CommunitiesMembers(props) {
  const { currentCommunity, userStatus } = props;
  const { isDarkMode } = useDarkMode();

  const [showDropdown, setShowDropdown] = useState(null);
  const [alertMessage, setAlertMessage] = useState({
    open: false,
    firstname: "",
    action: "",
    userId: "",
    communityId: "",
  });

  // Sort members so that moderators appear first
  const sortedMembers = currentCommunity.community.members.sort((a, b) => {
    if (a.moderator && !b.moderator) {
      return -1;
    } else if (!a.moderator && b.moderator) {
      return 1;
    } else {
      return 0;
    }
  });

  const showDropIcon = (memberId) => {
    setShowDropdown((prevDropdown) => {
      return prevDropdown === memberId ? null : memberId;
    });
  };

  const handleOpenAlert = (firstname, action, userId, communityId) => {
    setAlertMessage({
      open: true,
      firstname: firstname,
      action: action,
      userId: userId,
      communityId: communityId,
    });
  };

  const handleCloseAlert = () => {
    setAlertMessage({
      open: false,
      firstname: "",
      action: "",
      userId: "",
      communityId: "",
    });
  };

  const handleMemberEvent = (message) => {
    const data = {
      communityId: message.communityId,
      memberId: message.userId,
      action: message.action,
    };
    props.handleMemberEvent(data);
    // setShowDropdown(null);
    setAlertMessage({
      open: false,
      firstname: "",
      action: "",
      userId: "",
      communityId: "",
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const moreRefs = document.querySelectorAll(".more-ref");
      const isClickedInsideMoreRef = Array.from(moreRefs).some((ref) =>
        ref.contains(event.target)
      );
      if (!isClickedInsideMoreRef) {
        showDropIcon("");
      }
    };

    document.body.addEventListener("click", handleClickOutside);

    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className={CommunitiesMembersCSS["communities-members"]}>
      {sortedMembers.map((member) => (
        <div
          key={member.user._id}
          className={`${CommunitiesMembersCSS["member-item"]} ${
            isDarkMode ? CommunitiesMembersCSS.darkModeContainer : ""
          }`}
        >
          <span
            className={`${CommunitiesMembersCSS["member-item-top"]} ${
              isDarkMode ? CommunitiesMembersCSS.darkModeContainer : ""
            }`}
          >
            <p>
              {member.user.firstname} {member.user.lastname}
            </p>
            {member.user._id !==
              JSON.parse(localStorage.getItem("tempUser"))._id && (
              <div className={CommunitiesDiscussionCSS["more-ref"]}>
                <div>
                  <MoreHorizIcon
                    sx={{
                      cursor: "pointer",
                      color: "#757D87",
                      "&:hover": {
                        color: isDarkMode ? "#387ADB" : "#0B5DD7",
                      },
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (userStatus.isMember) {
                        showDropIcon(member.user._id);
                      }
                    }}
                  />
                </div>
                {showDropdown === member.user._id && (
                  <div
                    className={`${CommunitiesDiscussionCSS["more-dropdown"]} ${
                      isDarkMode
                        ? CommunitiesDiscussionCSS.darkModeContainer
                        : ""
                    }`}
                  >
                    {" "}
                    <div
                      className={`${CommunitiesDiscussionCSS["dropdown-row"]} ${
                        isDarkMode
                          ? CommunitiesDiscussionCSS.darkModeContainer
                          : ""
                      }`}
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent event from bubbling up
                        handleOpenAlert(
                          member.user.firstname,
                          "report",
                          member.user._id,
                          currentCommunity.community._id
                        );
                        setShowDropdown(null);
                      }}
                    >
                      Report
                    </div>
                    {userStatus.isMod && (
                      <div
                        className={`${
                          CommunitiesDiscussionCSS["dropdown-row"]
                        } ${
                          isDarkMode
                            ? CommunitiesDiscussionCSS.darkModeContainer
                            : ""
                        }`}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent event from bubbling up
                          // handleMemberEvent(member.user._id, "moderate");
                          if (userStatus.isMod) {
                            handleMemberEvent({
                              communityId: currentCommunity.community._id,
                              userId: member.user._id,
                              action: "moderate",
                            });
                            // handleOpenAlert(
                            //   member.user.firstname,
                            //   "moderate",
                            //   member.user._id,
                            //   currentCommunity.community._id
                            // );
                            setShowDropdown(null);
                          }
                        }}
                      >
                        {member.moderator ? "Unmod" : "Mod"}
                      </div>
                    )}
                    {userStatus.isMod && (
                      <div
                        className={`${
                          CommunitiesDiscussionCSS["dropdown-row"]
                        } ${
                          isDarkMode
                            ? CommunitiesDiscussionCSS.darkModeContainer
                            : ""
                        }`}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent event from bubbling up
                          if (userStatus.isMod) {
                            handleOpenAlert(
                              member.user.firstname,
                              "kick",
                              member.user._id,
                              currentCommunity.community._id
                            );
                            setShowDropdown(null);
                          }
                        }}
                      >
                        Kick
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </span>
          {member.moderator && (
            <span className={CommunitiesMembersCSS["moderator-label"]}>
              Moderator
            </span>
          )}
        </div>
      ))}
      {alertMessage.open ? <div className="faded-background"></div> : ""}
      {alertMessage.open &&
        alertMessage.userId &&
        alertMessage.firstname &&
        alertMessage.action &&
        alertMessage.communityId && (
          <CommunitiesAlertUserMessage
            alertMessage={alertMessage}
            handleCloseAlert={handleCloseAlert}
            handleMemberEvent={handleMemberEvent}
          />
        )}
    </div>
  );
}

export default CommunitiesMembers;
