import React from "react";

import PortfolioCSS from "./Portfolio.module.css";
import PortfolioChart from "./PortfolioChart";
import PositionContainer from "./PositionContainer";

function PortfolioRight(props) {
  const { currentAccount, loading } = props;

  const {
    portfolio: { balanceHistory },
  } = currentAccount;

  return (
    <div className={PortfolioCSS["portfolio-right"]}>
      <PortfolioChart balanceHistory={balanceHistory} />
      <PositionContainer
        currentAccount={currentAccount}
        loading={loading}
        isUser={true}
      />
    </div>
  );
}
export default PortfolioRight;
