import React, { useState } from "react";

import { useDarkMode } from "contexts/DarkModeContext";

import MatrixTableCSS from "./MatrixTable.module.css";

function MatrixTable(props) {
  const { isDarkMode } = useDarkMode();

  const [highlightedRow, setHighlightedRow] = useState(null);
  const [highlightedColumn, setHighlightedColumn] = useState(null);

  const stockNames = Object.keys(props.data);
  const desiredWidth = stockNames.length * 1.5;

  const handleCellHover = (rowIndex, columnIndex) => {
    setHighlightedRow(rowIndex);
    setHighlightedColumn(columnIndex);
  };

  const resetHighlight = () => {
    setHighlightedRow(null);
    setHighlightedColumn(null);
  };

  return (
    <table
      className={`${MatrixTableCSS["matrix-table"]} ${
        isDarkMode ? MatrixTableCSS.darkModeContainer : ""
      }`}
      style={{ width: `${desiredWidth}rem` }}
    >
      <thead>
        <tr>
          <th></th>
          {stockNames.map((stockName, columnIndex) => (
            <th
              key={stockName}
              className={`${MatrixTableCSS["matrix-table-header"]} ${
                highlightedColumn === columnIndex
                  ? MatrixTableCSS["highlighted"]
                  : ""
              } ${isDarkMode ? MatrixTableCSS.darkModeContainer : ""}`}
            >
              {stockName}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {stockNames.map((stockName, rowIndex) => (
          <tr key={stockName}>
            <th
              className={`${MatrixTableCSS["matrix-table-header"]} ${
                highlightedRow === rowIndex ? MatrixTableCSS["highlighted"] : ""
              } ${isDarkMode ? MatrixTableCSS.darkModeContainer : ""}`}
            >
              {stockName}
            </th>
            {stockNames.map((otherStockName, columnIndex) => (
              <td
                key={otherStockName}
                className={`${MatrixTableCSS["matrix-table-content"]} ${
                  highlightedRow === rowIndex ||
                  highlightedColumn === columnIndex
                    ? MatrixTableCSS["highlighted"]
                    : ""
                } ${isDarkMode ? MatrixTableCSS.darkModeContainer : ""}`}
                onMouseEnter={() => handleCellHover(rowIndex, columnIndex)}
                onMouseLeave={resetHighlight}
              >
                {props.data[stockName][otherStockName] !== 1
                  ? props.data[stockName][otherStockName].toFixed(6)
                  : props.data[stockName][otherStockName]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default MatrixTable;
