import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "components/Dashboard/Sidebar/Sidebar";
import Watchlist from "components/Dashboard/Watchlist/Watchlist";
import { useParams } from "react-router-dom";
import Portfolio from "components/Dashboard/Portfolio/Portfolio";
import Analytics from "components/Dashboard/Analytics/Analytics";
import Trade from "components/Dashboard/Trade/Trade";
import Communities from "components/Dashboard/Communities/Communities";
import Contact from "components/Shared/Contact";
import { useDarkMode } from "contexts/DarkModeContext";

function Dashboard(props) {
  const { isDarkMode } = useDarkMode();
  const params = useParams();
  const navigate = useNavigate();
  const tabList = [
    "portfolio",
    "watchlist",
    "analytics",
    "trade",
    "communities",
    "contact",
  ];

  const token = localStorage.getItem("token");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!tabList.includes(params.tab)) {
      navigate("/dashboard/portfolio");
    }
    if (!token) {
      navigate("/");
    }
  }, []);

  const click = (data) => {
    setIsOpen(data);
  };

  useEffect(() => {
    props.updateTab(params.tab);
  }, [params.tab]);

  if (token) {
    return (
      <div className={`dashboard ${isDarkMode ? "darkModeContainer" : ""}`}>
        <Sidebar tab={params.tab} open={click} />
        <div className="dashboard-content-container">
          {params.tab === "portfolio" && <Portfolio />}
          {params.tab === "watchlist" && <Watchlist />}
          {params.tab === "analytics" && <Analytics />}
          {params.tab === "trade" && <Trade />}
          {params.tab === "communities" && <Communities />}
          {isOpen ? <div className="faded-background"></div> : ""}
          {isOpen ? <Contact open={click} /> : ""}
        </div>
      </div>
    );
  }

  return null;
}

export default Dashboard;
