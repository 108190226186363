import React from "react";
import ContactCSS from "./Contact.module.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { post } from "services/service";
import CircularProgress from "@mui/material/CircularProgress";
import { useDarkMode } from "contexts/DarkModeContext";
import { useNavigate } from "react-router-dom";
import { useMobile } from "contexts/MobileContext";

function Contact(props) {
  const { isDarkMode } = useDarkMode();
  const { isMobile } = useMobile();

  const [subject, setSubject] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [subjectStatus, setSubjectStatus] = React.useState(false);
  const [messageStatus, setMessageStatus] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const useOutsideAlerter = (ref) => {
    React.useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          props.open(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = JSON.parse(localStorage.getItem("tempUser"));
    const username = user.username;
    const email = user.email;
    if (user) {
      if (!subject || !message) {
        if (!subject) {
          setSubjectStatus(true);
        }
        if (!message) {
          setMessageStatus(true);
        }
      } else {
        try {
          setLoading(true);
          const response = await post("/users/send-email", {
            username: username,
            email: email,
            subject: subject,
            message: message,
          });
          setSubject("");
          setMessage("");
          props.open(false);
        } catch (error) {
          console.error("Error sending email:", error);
          props.open(false);
        }
        setLoading(false);
      }
    } else {
      navigate("/");
    }
  };

  const cleanSubject = (value) => {
    setSubjectStatus(false);
    setSubject(value);
  };

  const cleanMessage = (value) => {
    setMessageStatus(false);
    setMessage(value);
  };

  return (
    <div
      className={`${ContactCSS["contact"]} ${
        isDarkMode ? ContactCSS.darkModeContainer : ""
      }`}
      ref={wrapperRef}
    >
      <h4>Contact Us</h4>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          "& .MuiTextField-root": {
            m: "0px 0px 16px 0px",
            width: isMobile ? "260px" : "343px",
          },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="subject"
          type="string"
          label="Subject"
          variant="standard"
          InputLabelProps={{
            shrink: true,
            sx: {
              color: isDarkMode ? "#9CA9B9" : "", // Label color
            },
          }}
          sx={{
            width: 120,
            "& .MuiInput-underline:before": {
              borderColor: isDarkMode ? "#9CA9B9 !important" : "",
            },
            "& .MuiInput-underline:hover:before": {
              borderColor: isDarkMode ? "#F0F0F0 !important" : "",
            },
            ".MuiSvgIcon-root": {
              fill: isDarkMode ? "#9CA9B9" : "",
            },
          }}
          InputProps={{
            sx: {
              color: isDarkMode ? "#F0F0F0" : "", // Text color
            },
          }}
          placeholder="Enter subject"
          value={subject}
          onChange={(e) => cleanSubject(e.target.value)}
          error={subjectStatus ? true : false}
        />
        <TextField
          id="message"
          label={
            <span className={ContactCSS["custom-label-text"]}>Message</span>
          }
          type="string"
          InputLabelProps={{
            shrink: true,
            sx: {
              color: isDarkMode ? "#9CA9B9" : "", // Label color
            },
          }}
          sx={{
            width: 141,
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: isDarkMode ? "#9CA9B9" : "",
            },
            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: isDarkMode ? "#F0F0F0" : "", // Border color on hover
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "#1976d2", // Border color when focused
              },
            "& .MuiOutlinedInput-root:hover": {
              "& .MuiOutlinedInput-input": {
                color: isDarkMode ? "#F0F0F0" : "", // Text color on hover
              },
            },
          }}
          InputProps={{
            style: {
              height: "86px",
            },
            sx: {
              color: isDarkMode ? "#F0F0F0" : "", // Text color
            },
          }}
          variant="outlined"
          multiline
          rows={3}
          value={message}
          onChange={(e) => cleanMessage(e.target.value)}
          error={messageStatus ? true : false}
        />
        <button
          type="submit"
          className={`${ContactCSS["login-button"]} ${
            isDarkMode ? ContactCSS.darkModeContainer : ""
          }`}
          disabled={loading}
        >
          {loading ? <CircularProgress size={20} /> : "Submit"}
        </button>
      </Box>
    </div>
  );
}

export default Contact;
