import React, { useState } from "react";
import ChartSwitchCSS from "./ChartSwitch.module.css";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import line from "assets/line.svg";
import candlestick from "assets/candlestick.svg";
import { useDarkMode } from "contexts/DarkModeContext";

const StyledChartSwitch = styled(Switch)(({ theme, isDarkMode }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(4px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(24px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url(${candlestick})`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#ACB4BE" : "#ACB4BE",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor:
      theme.palette.mode === "dark"
        ? "#0B5DD7"
        : isDarkMode
        ? "#1C1C28"
        : "#000000",
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url(${line})`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#ACB4BE" : "#ACB4BE",
    borderRadius: 20 / 2,
  },
}));

const ChartSwitch = ({ onChange }) => {
  const { isDarkMode } = useDarkMode();

  const [isCandlestick, setIsCandlestick] = useState(true);

  const handleChange = (event) => {
    const newChecked = event.target.checked;
    setIsCandlestick(newChecked);
    onChange(newChecked); // Invoke the callback with the updated value
  };

  return (
    <div className={ChartSwitchCSS["chart-switch"]}>
      <StyledChartSwitch
        disableRipple
        checked={isCandlestick}
        onChange={handleChange}
        isDarkMode={isDarkMode}
      />
    </div>
  );
};

export default ChartSwitch;
