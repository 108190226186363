import React from "react";

import CommunitiesFundCSS from "./CommunitiesFund.module.css";
import CommunitiesFundLeft from "./CommunitiesFundLeft";
import CommunitiesFundRight from "./CommunitiesFundRight";

function CommunitiesFund(props) {
  const {
    userStatus,
    currentCommunity,
    loading,
    updatePortfolio,
    removeFromPending,
    updateLedgerComment,
  } = props;

  return (
    <div className={CommunitiesFundCSS["communities-fund"]}>
      {currentCommunity?.community && (
        <CommunitiesFundLeft
          currentCommunity={currentCommunity}
          userStatus={userStatus}
          loading={loading}
        />
      )}
      <CommunitiesFundRight
        currentCommunity={currentCommunity}
        updatePortfolio={updatePortfolio}
        loading={loading}
        removeFromPending={removeFromPending}
        userStatus={userStatus}
        updateLedgerComment={updateLedgerComment}
      />
    </div>
  );
}
export default CommunitiesFund;
