import React, { useEffect, useState } from "react";
import TradeHistoryCSS from "./TradeHistory.module.css";
import { useDarkMode } from "contexts/DarkModeContext";
import { formatNumber } from "utils/formatNumber";
import PortfolioPositionsCSS from "components/Dashboard/Portfolio/PortfolioPositions.module.css";
import PortfolioHistoryCSS from "components/Dashboard/Portfolio/PortfolioHistory.module.css";

function TradeHistory(props) {
  const { accountLedger, loading } = props;
  const { isDarkMode } = useDarkMode();

  const [filteredLedger, setFilteredLedger] = useState([]);

  const getActionColor = (action) => {
    if (action === "Buy") {
      return isDarkMode ? "#87CD9D" : "#068B03";
    } else if (action === "Sell") {
      return isDarkMode ? "#C54242" : "#D20B0B";
    }
    return "";
  };

  console.log(filteredLedger);
  useEffect(() => {
    const approvedLedger = accountLedger.filter(
      (item) => item.approved === true || !("approved" in item)
    );
    setFilteredLedger(approvedLedger);
  }, [accountLedger]);

  return (
    <div
      className={`${PortfolioHistoryCSS["trade-history"]} ${
        isDarkMode ? PortfolioHistoryCSS.darkModeContainer : ""
      }`}
    >
      <div className={PortfolioHistoryCSS["trade-history-container"]}>
        <table
          className={`${PortfolioHistoryCSS["positions-table"]} ${
            isDarkMode ? PortfolioHistoryCSS.darkModeContainer : ""
          }`}
        >
          <thead>
            <tr>
              <th>Symbol</th>
              <th>Action</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {filteredLedger.length > 0
              ? filteredLedger
                  .slice()
                  .reverse()
                  .map((item) => (
                    <tr key={item._id}>
                      <td className={PortfolioHistoryCSS["position-name"]}>
                        <span className={PortfolioHistoryCSS["ticker"]}>
                          {item.ticker}
                        </span>
                        <span className={PortfolioHistoryCSS["shortname"]}>
                          {item.name}
                        </span>
                      </td>
                      <td style={{ color: getActionColor(item.action) }}>
                        {item.action}
                      </td>
                      <td>{formatNumber(item.amount)}</td>
                      <td>{formatNumber(item.price)}</td>
                      <td>
                        <div className={PortfolioHistoryCSS["date"]}>
                          {new Date(item.date * 1000).toLocaleDateString()}
                        </div>
                        <div className={PortfolioHistoryCSS["shortname"]}>
                          {new Date(item.date * 1000).toLocaleTimeString()}
                        </div>
                      </td>
                    </tr>
                  ))
              : null}
          </tbody>
        </table>
        {!loading && !filteredLedger.length ? (
          <p className={PortfolioPositionsCSS["placeholder-text"]}>
            There are no orders
          </p>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default TradeHistory;
