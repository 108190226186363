import React from "react";

import CommunitiesFundRightCSS from "./CommunitiesFundRight.module.css";
import CommunitiesPositions from "./CommunitiesPositions";
import CommunitiesHistory from "./CommunitiesHistory";
import PositionContainer from "../Portfolio/PositionContainer";

function CommunitiesFundRight(props) {
  const {
    currentCommunity,
    loading,
    userStatus,
    updatePortfolio,
    removeFromPending,
    updateLedgerComment,
  } = props;

  return (
    <div className={CommunitiesFundRightCSS["portfolio-right"]}>
      <PositionContainer
        currentAccount={currentCommunity}
        loading={loading}
        isUser={false}
        userStatus={userStatus}
        updatePortfolio={updatePortfolio}
        removeFromPending={removeFromPending}
        updateLedgerComment={updateLedgerComment}
      />
    </div>
  );
}
export default CommunitiesFundRight;
