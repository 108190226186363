import { createContext, useContext, useState } from "react";

const SubTabContext = createContext();

export function SubTabProvider({ children }) {
  const [subTab, setSubTab] = useState(true);

  return (
    <SubTabContext.Provider
      value={{
        subTab,
        setSubTab,
      }}
    >
      {children}
    </SubTabContext.Provider>
  );
}

export function useSubTab() {
  return useContext(SubTabContext);
}
