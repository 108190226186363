import React, { useMemo, useState, useEffect } from "react";

import { useDarkMode } from "contexts/DarkModeContext";

import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PushPinIcon from "@mui/icons-material/PushPin";

import CommunitiesDiscussionCSS from "./CommunitiesDiscussion.module.css";
import CommunitiesAlertMessage from "./CommunitiesAlertMessage";
import CommunitiesThread from "./CommunitiesThread";
import CommunitiesCreatePost from "./CommunitiesCreatePost";

function CommunitiesDiscussion(props) {
  const { isDarkMode } = useDarkMode();

  const { currentCommunity, userStatus, loading, posts, updateReply } = props;

  const [openEditPost, setOpenEditPost] = useState({
    open: false,
    content: null,
    branch: null,
  });
  const [openAlertMessage, setOpenAlertMessage] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showDropdown, setShowDropdown] = useState(null);
  const [selectedPost, setSelectedPost] = useState(null);
  const [openThread, setOpenThread] = useState(false);
  const [isReply, setIsReply] = useState(false);
  const [replyId, setReplyId] = useState("");
  const [discussionPlaceholder, setDiscussionPlaceholder] = useState(false);

  const combinedPosts = useMemo(() => {
    if (posts) {
      const pinnedPosts = posts.filter((post) => post.pinned);
      const otherPosts = posts.filter((post) => !post.pinned);

      const reversedPinnedPosts = pinnedPosts.reverse();
      const reversedOtherPosts = otherPosts.reverse();
      return [...reversedPinnedPosts, ...reversedOtherPosts];
    }
  }, [posts]);

  const handleEvent = (postId, action) => {
    const data = {
      postId: postId,
      action: action,
    };
    props.handleEvent(data);
    setShowDropdown(null);
  };

  const handleReplyEvent = (postId, replyId, action) => {
    const data = {
      postId: postId,
      replyId: replyId,
      action: action,
    };
    props.handleReplyEvent(data);
    // setShowReplyDropdown(null);
  };

  const isPostLikedByCurrentUser = (post) => {
    return post.likedBy.includes(
      JSON.parse(localStorage.getItem("tempUser"))._id
    );
  };

  const showDropIcon = (postId) => {
    setShowDropdown((prevDropdown) => {
      return prevDropdown === postId ? null : postId;
    });
  };

  const handleAlertMessage = (isReply, replyId, message) => {
    setOpenAlertMessage(true);
    setIsReply(isReply);
    setReplyId(replyId);
    setAlertMessage(message);
  };

  const handleOpenAlert = () => {
    setOpenAlertMessage(false);
  };

  const handleOpenThread = () => {
    setOpenThread(false);
  };

  const handleOpenEditPost = (open, content, branch) => {
    setOpenEditPost({
      open: open,
      content: content,
      branch: branch,
    });
  };

  const updatePost = (data) => {
    props.updatePost(data);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const moreRefs = document.querySelectorAll(".more-ref");
      const isClickedInsideMoreRef = Array.from(moreRefs).some((ref) =>
        ref.contains(event.target)
      );
      if (!isClickedInsideMoreRef) {
        showDropIcon("");
      }
    };

    document.body.addEventListener("click", handleClickOutside);

    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (selectedPost && posts) {
      const foundPost = posts.find((post) => post._id === selectedPost._id);
      if (foundPost) {
        setSelectedPost(foundPost);
      }
    }
  }, [posts]);

  useEffect(() => {
    if (!loading.posts && (!combinedPosts || combinedPosts.length === 0)) {
      setDiscussionPlaceholder(true);
    }
  }, [loading.posts, combinedPosts]);

  return (
    <div
      className={`${CommunitiesDiscussionCSS["communities-discussion"]} ${
        isDarkMode ? CommunitiesDiscussionCSS.darkModeContainer : ""
      }`}
    >
      {combinedPosts.length > 0 ? (
        combinedPosts.map((post) => (
          <div
            key={post._id}
            className={`${CommunitiesDiscussionCSS["post-item"]} ${
              isDarkMode ? CommunitiesDiscussionCSS.darkModeContainer : ""
            }`}
            onClick={() => {
              if (userStatus.isMember) {
                setOpenThread(true);
                setSelectedPost(post);
              }
            }}
          >
            <span className={CommunitiesDiscussionCSS["user-and-edit"]}>
              <p
                className={`${CommunitiesDiscussionCSS["post-username"]} ${
                  isDarkMode ? CommunitiesDiscussionCSS.darkModeContainer : ""
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                style={
                  post.creator === null
                    ? { color: isDarkMode ? "#F0F0F0" : "#000000" }
                    : null
                }
              >
                {post.creator !== null
                  ? `${post.creator.firstname} ${post.creator.lastname}`
                  : "[deleted]"}
              </p>
              <span className={CommunitiesDiscussionCSS["pin-more"]}>
                {post.pinned && <PushPinIcon />}
                <div className={CommunitiesDiscussionCSS["more-ref"]}>
                  <div>
                    <MoreHorizIcon
                      sx={{
                        cursor: "pointer",
                        color: "#757D87",
                        "&:hover": {
                          color: isDarkMode ? "#387ADB" : "#0B5DD7",
                        },
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (userStatus.isMember) {
                          showDropIcon(post._id);
                        }
                      }}
                    />
                  </div>
                  {showDropdown === post._id && (
                    <div
                      className={`${
                        CommunitiesDiscussionCSS["more-dropdown"]
                      } ${
                        isDarkMode
                          ? CommunitiesDiscussionCSS.darkModeContainer
                          : ""
                      }`}
                    >
                      {userStatus.isMod && (
                        <div
                          className={`${
                            CommunitiesDiscussionCSS["dropdown-row"]
                          } ${
                            isDarkMode
                              ? CommunitiesDiscussionCSS.darkModeContainer
                              : ""
                          }`}
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent event from bubbling up
                            handleEvent(post._id, "pin");
                          }}
                        >
                          {post.pinned ? "Unpin" : "Pin"}
                        </div>
                      )}
                      {post.creator?._id ===
                        JSON.parse(localStorage.getItem("tempUser"))._id && (
                        <div
                          className={`${
                            CommunitiesDiscussionCSS["dropdown-row"]
                          } ${
                            isDarkMode
                              ? CommunitiesDiscussionCSS.darkModeContainer
                              : ""
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (userStatus.isMember) {
                              handleOpenEditPost(true, post, "main");
                              setShowDropdown(false);
                              setSelectedPost(post);
                            }
                          }}
                        >
                          Edit
                        </div>
                      )}
                      {(post.creator?._id ===
                        JSON.parse(localStorage.getItem("tempUser"))._id ||
                        userStatus.isMod) && (
                        <div
                          className={`${
                            CommunitiesDiscussionCSS["dropdown-row"]
                          } ${
                            isDarkMode
                              ? CommunitiesDiscussionCSS.darkModeContainer
                              : ""
                          }`}
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent event from bubbling up
                            if (userStatus.isMember) {
                              handleAlertMessage(false, null, "delete");
                              setShowDropdown(false);
                              setSelectedPost(post);
                            }
                          }}
                        >
                          Delete
                        </div>
                      )}
                      <div
                        className={`${
                          CommunitiesDiscussionCSS["dropdown-row"]
                        } ${
                          isDarkMode
                            ? CommunitiesDiscussionCSS.darkModeContainer
                            : ""
                        }`}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent event from bubbling up
                          if (userStatus.isMember) {
                            handleAlertMessage(false, null, "report");
                            setShowDropdown(false);
                            setSelectedPost(post);
                          }
                        }}
                      >
                        Report
                      </div>
                    </div>
                  )}
                </div>
              </span>
            </span>

            <p className={CommunitiesDiscussionCSS["post-date"]}>
              {new Date(parseInt(post.date) * 1000).toLocaleString()}
            </p>
            <p className={CommunitiesDiscussionCSS["post-title"]}>
              {post.title}
            </p>
            <p className={CommunitiesDiscussionCSS["post-description"]}>
              {post.description}
            </p>
            <span className={CommunitiesDiscussionCSS["icon-group"]}>
              <span className={CommunitiesDiscussionCSS["icon-and-number"]}>
                <ThumbUpIcon
                  sx={{
                    cursor: "pointer",
                    color: isPostLikedByCurrentUser(post)
                      ? isDarkMode
                        ? "#387ADB"
                        : "#0B5DD7"
                      : "#757D87",
                    "&:hover": { color: isDarkMode ? "#387ADB" : "#0B5DD7" },
                  }}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent event from bubbling up
                    if (userStatus.isMember) {
                      handleEvent(post._id, "like");
                    }
                  }}
                />

                <p>{post.likes !== 0 ? post.likes : ""}</p>
              </span>
              <span className={CommunitiesDiscussionCSS["icon-and-number"]}>
                <ChatBubbleIcon
                  sx={{
                    cursor: "pointer",
                    color: "#757D87",
                    "&:hover": { color: isDarkMode ? "#387ADB" : "#0B5DD7" },
                  }}
                />
                <p>{post.replies.length !== 0 ? post.replies.length : ""}</p>
              </span>
            </span>
          </div>
        ))
      ) : discussionPlaceholder ? (
        <span className={CommunitiesDiscussionCSS["placeholder-text"]}>
          There are no posts
        </span>
      ) : (
        ""
      )}

      {openEditPost.open || openAlertMessage || openThread ? (
        <div className="faded-background"></div>
      ) : (
        ""
      )}
      {openAlertMessage && selectedPost && (
        <CommunitiesAlertMessage
          message={alertMessage}
          handleOpenAlert={handleOpenAlert}
          postId={selectedPost._id}
          handleEvent={handleEvent}
          isReply={isReply}
          replyId={replyId}
          handleReplyEvent={handleReplyEvent}
        />
      )}
      {openThread && selectedPost && (
        <CommunitiesThread
          userStatus={userStatus}
          // isModerator={isModerator}
          selectedPost={selectedPost}
          handleOpenThread={handleOpenThread}
          handleEvent={handleEvent}
          // userId={userId}
          handleAlertMessage={handleAlertMessage}
          isPostLikedByCurrentUser={isPostLikedByCurrentUser}
          handleOpenEditPost={handleOpenEditPost}
          updatePost={updatePost}
          handleReplyEvent={handleReplyEvent}
        />
      )}
      {openEditPost.open && (
        <CommunitiesCreatePost
          edit={true}
          openEditPost={openEditPost}
          selectedPost={selectedPost}
          updatePost={updatePost}
          updateReply={updateReply}
        />
      )}
    </div>
  );
}

export default CommunitiesDiscussion;
