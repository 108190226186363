import React, { useState } from "react";

import { useDarkMode } from "contexts/DarkModeContext";

import AnalyticsPerformanceCSS from "./AnalyticsPerformance.module.css";
import { ChartComponent } from "components/ChartComponent";

function AnalyticsPerformance(props) {
  const { isDarkMode } = useDarkMode();
  const [currentTable, setCurrentTable] = useState(Object.keys(props.data)[0]);

  function formatDataForChart(data) {
    const formattedData = data.Date.map((date, index) => ({
      time: Date.parse(date) / 1000,
      value: 1 + data["Cumulative Returns"][index],
    }));

    return formattedData;
  }

  return (
    <div className={AnalyticsPerformanceCSS["analytics-performance"]}>
      <div
        className={AnalyticsPerformanceCSS["analytics-stock-header-container"]}
      >
        {Object.keys(props.data).map((ticker) => (
          <button
            className={AnalyticsPerformanceCSS["analytics-stock-header"]}
            onClick={() => setCurrentTable(ticker)}
            key={ticker}
            style={{
              color:
                currentTable === ticker
                  ? isDarkMode
                    ? "#387ADB"
                    : "#0B5DD7"
                  : "",
            }}
          >
            {ticker}
          </button>
        ))}
      </div>
      {props.data[currentTable] && (
        <div
          className={`${AnalyticsPerformanceCSS["chart"]} ${
            isDarkMode ? AnalyticsPerformanceCSS.darkModeContainer : ""
          }`}
        >
          <ChartComponent
            data={formatDataForChart(props.data[currentTable])}
            type={"area"}
          />
        </div>
      )}
    </div>
  );
}

export default AnalyticsPerformance;
