import React, { createContext, useContext, useState, useEffect } from "react";
import { post } from "services/service";

const DarkModeContext = createContext();

export const DarkModeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const tempUser = JSON.parse(localStorage.getItem("tempUser"));
    if (tempUser) {
      const savedDarkMode = tempUser.preferences.darkmode;
      setIsDarkMode(savedDarkMode);
    }
  }, []);

  const toggleDarkMode = () => {
    const newDarkMode = !isDarkMode;
    setIsDarkMode(newDarkMode);

    const tempUser = JSON.parse(localStorage.getItem("tempUser"));
    if (tempUser) {
      updatePreference(newDarkMode);
      tempUser.preferences.darkmode = newDarkMode;
      localStorage.setItem("tempUser", JSON.stringify(tempUser));
    }
  };

  const updatePreference = async (value) => {
    try {
      const token = localStorage.getItem("token");
      if (token) {
        const requestData = {
          preferences: {
            darkmode: value,
          },
        };
        const response = await post("/users/update", requestData);
        if (response.status === 200) {
          // Successfully updated the preference
        } else {
          // Handle errors
          console.error("Failed to update the dark mode preference");
        }
      } else {
        return;
      }
    } catch (err) {
      console.error("Error updating preference:", err);
    }
  };

  return (
    <DarkModeContext.Provider
      value={{ isDarkMode, setIsDarkMode, toggleDarkMode }}
    >
      {children}
    </DarkModeContext.Provider>
  );
};

export const useDarkMode = () => {
  return useContext(DarkModeContext);
};
