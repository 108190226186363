export const createCalculatePriceColor = (isDarkMode, isAlternativeColor) => {
  return (isPositive) => {
    if (isDarkMode) {
      return isAlternativeColor
        ? isPositive
          ? "#C54242" // Dark mode, alternative color, positive
          : "#87CD9D" // Dark mode, alternative color, negative
        : isPositive
        ? "#87CD9D" // Dark mode, regular color, positive
        : "#C54242"; // Dark mode, regular color, negative
    } else {
      return isAlternativeColor
        ? isPositive
          ? "#F42727" // Light mode, alternative color, positive
          : "#068B03" // Light mode, alternative color, negative
        : isPositive
        ? "#068B03" // Light mode, regular color, positive
        : "#F42727"; // Light mode, regular color, negative
    }
  };
};
