import React, { useState } from "react";
import TradeCSS from "./Trade.module.css";
import { useDarkMode } from "contexts/DarkModeContext";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import Searchbar from "components/ui/Searchbar";
import { useColorPreference } from "contexts/ColorPreferenceContext";
// import { usePortfolioNames } from "contexts/PortfolioNamesContext";
import { createCalculatePriceColor } from "utils/colorUtils";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import { formatNumber } from "utils/formatNumber";
import { useMobile } from "contexts/MobileContext";
import { useSubTab } from "contexts/SubTabContext";

function TradeLeft({
  marketStatus,
  tempTrade,
  placeholderStats,
  verify,
  placeOrder,
  loading,
  handleAmountChange,
  handleActionChange,
  handleAccountChange,
  portfoliosLedgers,
  currentAccount,
}) {
  const { placeholder, action, orderType, amount } = tempTrade;
  const {
    main: loadingMain,
    search: loadingSearch,
    button: loadingButton,
  } = loading;

  const { isMobile } = useMobile();
  const { subTab, setSubTab } = useSubTab();
  const { isDarkMode } = useDarkMode();
  const { isAlternativeColor } = useColorPreference();
  //finish portfolio names!!!!!!
  // const { portfolioNames } = usePortfolioNames();
  const calculateColor = createCalculatePriceColor(
    isDarkMode,
    isAlternativeColor
  );
  const positiveColor = calculateColor(true);
  const negativeColor = calculateColor(false);

  return (
    <div className={TradeCSS["trade-left"]}>
      {!isMobile && (
        <h2
          className={`${TradeCSS["tab-heading"]} ${
            isDarkMode ? TradeCSS.darkModeContainer : ""
          }`}
        >
          Trade
        </h2>
      )}
      <FormControl
        sx={{
          marginBottom: 2,
          // marginLeft: 0,
          width: "100%",
          "& .MuiInput-underline:before": {
            borderColor: isDarkMode ? "#9CA9B9" : "",
          },
          "& .MuiInput-underline:hover:before": {
            borderColor: isDarkMode ? "#F0F0F0 !important" : "",
          },
          "& .MuiInput-underline:hover:after": {
            borderColor: isDarkMode ? "#F0F0F0 !important" : "",
          },
          ".MuiSvgIcon-root": {
            fill: isDarkMode ? "#9CA9B9" : "",
          },
        }}
      >
        <Select
          value={currentAccount.portfolio._id}
          onChange={handleAccountChange}
          variant="standard"
          MenuProps={{
            MenuListProps: {
              autoFocusItem: null,
            },
            PaperProps: {
              sx: {
                color: isDarkMode ? "#F0F0F0" : "",
                bgcolor: isDarkMode ? "#272835" : "",
                maxHeight: "50%", // Set the max height here
                overflow: "auto", // Enable scrolling if the content exceeds the max height
              },
            },
          }}
          sx={{ color: isDarkMode ? "#F0F0F0" : "" }}
          onClose={() => {
            setTimeout(() => {
              document.activeElement.blur();
            }, 0);
          }}
        >
          {portfoliosLedgers.communities.portfolios.length && (
            <h5
              style={{
                marginLeft: "8px",
                color: isDarkMode ? "#C6CFDA" : "#757D87",
              }}
            >
              Portfolio
            </h5>
          )}
          {portfoliosLedgers.user.portfolios.map((portfolio) => (
            <MenuItem key={portfolio._id} value={portfolio._id}>
              <h5>{portfolio.name}</h5>
            </MenuItem>
          ))}
          {portfoliosLedgers.communities.portfolios.length && (
            <h5
              style={{
                marginLeft: "8px",
                color: isDarkMode ? "#C6CFDA" : "#757D87",
              }}
            >
              Communities
            </h5>
          )}
          {portfoliosLedgers.communities.portfolios.length &&
            portfoliosLedgers.communities.portfolios
              .slice()
              .reverse()
              .map((portfolio) => (
                <MenuItem key={portfolio._id} value={portfolio._id}>
                  <h5>{portfolio.communityId.name}</h5>
                </MenuItem>
              ))}
        </Select>
      </FormControl>
      <span
        className={TradeCSS["market-message"]}
        style={{
          color: marketStatus.isMarketOpen
            ? isDarkMode
              ? "#87CD9D"
              : "#068B03"
            : isDarkMode
            ? "#D98A17"
            : "#D7840B",
        }}
      >
        <AccessAlarmIcon style={{ marginRight: "4px" }} />
        {marketStatus.remainingTime ? marketStatus.remainingTime : ""}
      </span>
      <form
        className={`${TradeCSS["trade-options"]} ${
          isDarkMode ? TradeCSS.darkModeContainer : ""
        }`}
        onSubmit={(e) => {
          e.preventDefault();
          placeOrder(e);
        }}
      >
        <div className={TradeCSS["trade-searchbar-container"]}>
          <Searchbar
            verify={verify}
            placeholderStats={placeholderStats}
            placeholder={placeholder}
            submitOnEnter={false}
            loading={loadingSearch}
          />
          {!loadingSearch && isMobile && placeholder && (
            <span
              className={`${TradeCSS["more-button"]} ${
                isDarkMode ? TradeCSS.darkModeContainer : ""
              }`}
              onClick={() => {
                if (placeholder) {
                  setSubTab(!subTab);
                }
              }}
            >
              View More
            </span>
          )}
        </div>

        <div className={TradeCSS["bid-ask"]}>
          <span
            className={`${TradeCSS["bid"]} ${
              isDarkMode ? TradeCSS.darkModeContainer : ""
            }`}
            style={{
              color: isDarkMode ? "#272835" : positiveColor,
              background: isDarkMode
                ? positiveColor
                : `linear-gradient(0deg, rgba(255, 255, 255, 0.88), rgba(255, 255, 255, 0.88)), ${positiveColor}`,
            }}
          >
            {!loadingMain &&
              !loadingSearch &&
              placeholderStats.bid &&
              `Bid ${formatNumber(placeholderStats.bid)}`}
          </span>
          <span
            className={`${TradeCSS["spread"]} ${
              isDarkMode ? TradeCSS.darkModeContainer : ""
            }`}
          >
            {placeholderStats.ask &&
              placeholderStats.bid &&
              formatNumber(
                Math.abs(placeholderStats.ask - placeholderStats.bid)
              )}
          </span>
          <span
            className={`${TradeCSS["ask"]} ${
              isDarkMode ? TradeCSS.darkModeContainer : ""
            }`}
            style={{
              color: isDarkMode ? "#272835" : negativeColor,
              background: isDarkMode
                ? negativeColor
                : `linear-gradient(0deg, rgba(255, 255, 255, 0.88), rgba(255, 255, 255, 0.88)), ${negativeColor}`,
            }}
          >
            {!loadingMain &&
              !loadingSearch &&
              placeholderStats.bid &&
              `Ask ${formatNumber(placeholderStats.ask)}`}
          </span>
        </div>
        <div className={TradeCSS["trade-options-row"]}>
          <FormControl
            sx={{
              width: 141,
              "& .MuiInput-underline:hover:before": {
                borderColor: isDarkMode ? "#F0F0F0" : "",
              },
              "& .MuiInput-underline:hover:after": {
                borderColor: isDarkMode ? "#F0F0F0" : "",
              },
              pointerEvents: "auto", // Enable hover events
              "&:hover": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: isDarkMode ? "#F0F0F0 !important" : "", // Border color on hover
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: isDarkMode ? "#9CA9B9" : "", // Default border color
              },
              ".MuiSvgIcon-root": {
                fill: isDarkMode ? "#9CA9B9" : "",
              },
            }}
          >
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                color: isDarkMode ? "#9CA9B9" : "",
              }}
            >
              <span className={TradeCSS["custom-label-text"]}>Action</span>
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={action}
              label="Action"
              MenuProps={{
                MenuListProps: {
                  autoFocusItem: null,
                },
                PaperProps: {
                  sx: {
                    color: isDarkMode ? "#F0F0F0" : "",
                    bgcolor: isDarkMode ? "#272835" : "",
                  },
                },
              }}
              sx={{
                color: isDarkMode ? "#F0F0F0" : "",
              }}
              onClose={() => {
                setTimeout(() => {
                  document.activeElement.blur();
                }, 0);
              }}
              onChange={(e) => handleActionChange(e.target.value)}
            >
              <MenuItem value={"Buy"}>Buy</MenuItem>
              <MenuItem value={"Sell"}>Sell</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            sx={{
              width: 141,
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: isDarkMode ? "#9CA9B9" : "", // Default border color
              },
              "& .MuiInputBase-root.Mui-disabled": {
                "& > fieldset": {
                  borderColor: isDarkMode ? "#9CA9B9" : "", // Default border color
                },
              },
              ".MuiSvgIcon-root": {
                fill: isDarkMode ? "#9CA9B9" : "",
              },
            }}
          >
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                color: isDarkMode ? "#9CA9B9" : "",
              }}
            >
              <span className={TradeCSS["custom-label-text"]}>Type</span>
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={orderType}
              label="type"
              MenuProps={{
                MenuListProps: {
                  autoFocusItem: null,
                },
                PaperProps: {
                  sx: {
                    color: isDarkMode ? "#F0F0F0" : "",
                    bgcolor: isDarkMode ? "#272835" : "",
                  },
                },
              }}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: isDarkMode ? "#9CA9B9" : "",
                },
              }}
              disabled
              onChange={(e) => handleActionChange(e.target.value)}
            >
              <MenuItem value={"Market"}>Market</MenuItem>
              <MenuItem value={"Limit"}>Limit</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className={TradeCSS["trade-options-row"]}>
          <TextField
            id="standard-number"
            label={
              <span className={TradeCSS["custom-label-text"]}>Quantity</span>
            }
            type="number"
            InputLabelProps={{
              shrink: true,
              sx: {
                color: isDarkMode ? "#9CA9B9" : "", // Label color
              },
            }}
            sx={{
              width: 141,
              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: isDarkMode ? "#9CA9B9" : "",
              },
              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: isDarkMode ? "#F0F0F0" : "", // Border color on hover
                },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#1976d2", // Border color when focused
                },
              "& .MuiOutlinedInput-root:hover": {
                "& .MuiOutlinedInput-input": {
                  color: isDarkMode ? "#F0F0F0" : "", // Text color on hover
                },
              },
            }}
            InputProps={{
              sx: {
                color: isDarkMode ? "#F0F0F0" : "", // Text color
              },
            }}
            variant="outlined"
            value={amount}
            onChange={(e) => handleAmountChange(e.target.value)}
          />

          <FormControl
            sx={{
              width: 141,
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: isDarkMode ? "#9CA9B9" : "", // Default border color
              },
              "& .MuiInputBase-root.Mui-disabled": {
                "& > fieldset": {
                  borderColor: isDarkMode ? "#9CA9B9" : "", // Default border color
                },
              },
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: isDarkMode ? "#9CA9B9" : "",
              },
              "& .MuiTypography-root": {
                color: isDarkMode ? "#9CA9B9" : "",
              },
            }}
          >
            <InputLabel
              sx={{
                color: isDarkMode ? "#9CA9B9" : "",
              }}
            >
              <span className={TradeCSS["custom-label-text"]}>Price</span>
            </InputLabel>
            <OutlinedInput
              // startAdornment={
              // <InputAdornment position="start">$</InputAdornment>
              // }
              label="Price"
              disabled
              value={
                !loadingMain &&
                !loadingSearch &&
                placeholderStats.regularMarketPrice
                  ? formatNumber(placeholderStats.regularMarketPrice)
                  : ""
              }
            />
          </FormControl>
        </div>
        <div className={TradeCSS["trade-options-row"]}>
          <button
            type="submit"
            className={`${TradeCSS["submit-button"]} ${
              isDarkMode ? TradeCSS.darkModeContainer : ""
            }`}
            disabled={loadingMain || loadingSearch || loadingButton}
          >
            {loadingMain || loadingSearch || loadingButton ? (
              <CircularProgress size={20} />
            ) : (
              "Place Order"
            )}
          </button>
        </div>
        <div className={TradeCSS["trade-options-row"]}>
          <div className={TradeCSS["trade-totals"]}>
            <div className={TradeCSS["trade-totals-left"]}>
              <div
                className={`${TradeCSS["trade-totals-row"]} ${
                  isDarkMode ? TradeCSS.darkModeContainer : ""
                }`}
              >
                <span>Total (est.)</span>
                {!loadingMain && !loadingSearch && (
                  <span>
                    {placeholder &&
                    amount &&
                    placeholderStats.regularMarketPrice
                      ? formatNumber(
                          (
                            placeholderStats.regularMarketPrice * amount
                          ).toFixed(2)
                        )
                      : "0"}
                  </span>
                )}
              </div>
              <div
                className={`${TradeCSS["trade-totals-row"]} ${
                  isDarkMode ? TradeCSS.darkModeContainer : ""
                }`}
              >
                <span>Buying Power</span>
                {!loadingMain && (
                  <span>
                    {currentAccount &&
                      currentAccount.portfolio.buyingPower &&
                      formatNumber(currentAccount.portfolio.buyingPower)}
                  </span>
                )}
              </div>
            </div>

            <div className={TradeCSS["trade-totals-right"]}>
              <div
                className={`${TradeCSS["trade-totals-row"]} ${
                  isDarkMode ? TradeCSS.darkModeContainer : ""
                }`}
              >
                <span>Current Position</span>
                {!loadingMain && !loadingSearch && (
                  <span>
                    {currentAccount &&
                    currentAccount.portfolio.positions &&
                    placeholder &&
                    formatNumber(
                      currentAccount.portfolio.positions.find(
                        (position) => position.ticker === placeholder
                      )?.amount || 0
                    )
                      ? formatNumber(
                          currentAccount.portfolio.positions.find(
                            (position) => position.ticker === placeholder
                          )?.amount || 0
                        )
                      : 0}
                  </span>
                )}
              </div>
              <div
                className={`${TradeCSS["trade-totals-row"]} ${
                  isDarkMode ? TradeCSS.darkModeContainer : ""
                }`}
              >
                <span>Position After Trade</span>
                {!loadingMain && !loadingSearch && (
                  <span>
                    {currentAccount &&
                    currentAccount.portfolio.positions &&
                    placeholder
                      ? action === "Buy"
                        ? formatNumber(
                            (currentAccount.portfolio.positions.find(
                              (position) => position.ticker === placeholder
                            )?.amount || 0) + amount
                          )
                        : formatNumber(
                            (currentAccount.portfolio.positions.find(
                              (position) => position.ticker === placeholder
                            )?.amount || 0) - amount
                          )
                      : 0}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
export default TradeLeft;
