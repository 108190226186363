import React, { useEffect, useState } from "react";
import { getHistoryExcel } from "services/service";

import { useDarkMode } from "contexts/DarkModeContext";

import PortfolioReportCSS from "./PortfolioReport.module.css";
import AnalyticsComponents from "./AnalyticsComponents";
import AnalyticsPortfolio from "./AnalyticsPortfolio";
import AnalyticsPerformance from "./AnalyticsPerformance";
import AnalyticsMore from "./AnalyticsMore";

function PortfolioReport(props) {
  const { isDarkMode } = useDarkMode();

  const [currentTable, setCurrentTable] = useState();
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState("Components");
  const [tabs, setTabs] = useState([]);
  const [trueKeys, setTrueKeys] = useState();

  useEffect(() => {
    setCurrentTable(Object.keys(props.portfolio.Components)[0]);
    setCurrentTab(Object.keys(props.portfolio)[0]);
    setTabs(Object.keys(props.portfolio));
    setTrueKeys(
      Object.keys(props.currentPortfolioParams.model).filter(
        (key) => props.currentPortfolioParams.model[key] === true
      )
    );
  }, [props.portfolio]);

  const keys = ["Date", "Adj Close", "Returns", "Cumulative Returns"];
  const portfolioKeys = ["Date", "Returns", "Cumulative Returns"];

  const frequencyName = (frequency) => {
    if (frequency === "1d") {
      return "Daily";
    } else if (frequency === "1wk") {
      return "Weekly";
    } else if (frequency === "1mo") {
      return "Monthly";
    }
  };

  const modelName = (model) => {
    if (model === "mpt") {
      return "MPT";
    } else if (model === "capm") {
      return "CAPM";
    } else if (model === "ff3") {
      return "Fama French 3-Factor";
    } else if (model === "ff5") {
      return "Fama French 5-Factor";
    }
  };

  async function fetchExcelReport(
    e,
    tickers,
    period,
    frequency,
    startDate,
    endDate
  ) {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await getHistoryExcel(
        tickers,
        period,
        startDate,
        endDate,
        frequency
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "unitrade_report.xlsx");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error:", error);
    }

    setLoading(false);
  }

  const changeTab = (val) => {
    setCurrentTab(val);
    setCurrentTable(Object.keys(props.portfolio[val])[0]);
  };

  return (
    <div
      className={`${PortfolioReportCSS["report"]} ${
        isDarkMode ? PortfolioReportCSS.darkModeContainer : ""
      }`}
    >
      {/* <div className={PortfolioReportCSS["report-container"]}> */}
      {/* <div className={PortfolioReportCSS["report-top"]}> */}
      <h4
        className={`${PortfolioReportCSS["h4-watchlist"]} ${
          isDarkMode ? PortfolioReportCSS.darkModeContainer : ""
        }`}
      >
        Reports
      </h4>
      {/* <button
            className="report-download"
            disabled={loading}
            //   onClick={
            // (e) =>
            // fetchExcelReport(
            //   e,
            //   props.currentReportParams.tickers,
            //   props.currentReportParams.period,
            //   props.currentReportParams.frequency,
            //   props.currentReportParams.startDate,
            //   props.currentReportParams.endDate
            // )
            //   }
          >
            {loading ? (
              <CircularProgress size={30} />
            ) : (
              <DownloadIcon fontSize="large" />
            )}
          </button> */}
      {/* </div> */}
      {/* <div className={PortfolioReportCSS["portfolio-report-content"]}> */}
      <div className={PortfolioReportCSS["analytics-tab-header-container"]}>
        {tabs.map((val) => (
          <button
            className={`${PortfolioReportCSS["analytics-tab-header"]} ${
              isDarkMode ? PortfolioReportCSS.darkModeContainer : ""
            }`}
            key={val}
            onClick={() => changeTab(val)}
            style={{
              boxShadow:
                currentTab === val
                  ? isDarkMode
                    ? "inset 0px -4px 0px 0px #387ADB"
                    : "inset 0px -4px 0px 0px #0B5DD7"
                  : "inset 0px 0px 0px 0px #FFFFFF, inset 0px -1px 0px 0px #F4F9FF",
            }}
          >
            {val}
          </button>
        ))}
      </div>
      <div className={PortfolioReportCSS["portfolio-report-content"]}>
        {currentTab === "Components" && (
          <AnalyticsComponents data={props.portfolio["Components"]} />
        )}
        {currentTab === "Portfolio" && (
          <AnalyticsPortfolio
            data={props.portfolio && props.portfolio["Portfolio"]}
            keys={trueKeys}
          />
        )}
        {currentTab === "Performance" && (
          <AnalyticsPerformance data={props.portfolio["Performance"]} />
        )}
        {currentTab === "More" && (
          <AnalyticsMore data={props.portfolio["More"]} />
        )}
      </div>
      {/* </div> */}
    </div>
  );
}

export default PortfolioReport;
