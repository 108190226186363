import React, { useState } from "react";

import { useDarkMode } from "contexts/DarkModeContext";

import PortfolioChartCSS from "./PortfolioChart.module.css";
import { ChartComponent } from "components/ChartComponent";

function PortfolioChart(props) {
  const { isDarkMode } = useDarkMode();

  const [timespan, setTimespan] = useState("1mo");

  const getDataByTimeRange = (data, range) => {
    const now = Math.floor(Date.now() / 1000); // Current Unix time in seconds
    let startTime;
    switch (range) {
      case "1mo":
        startTime = now - 30 * 24 * 60 * 60; // 1 month in seconds
        break;
      case "3mo":
        startTime = now - 90 * 24 * 60 * 60; // 3 months in seconds
        break;
      case "ytd":
        const currentYear = new Date().getFullYear();
        const januaryFirst = new Date(currentYear, 0, 1);
        startTime = Math.floor(januaryFirst.getTime() / 1000); // Year-to-date in seconds
        break;
      case "max":
        // Use the earliest timestamp in the data
        startTime = Math.min(...data.map((item) => item.time));
        break;
      default:
        startTime = 0;
        break;
    }

    const filteredData = data.filter((item) => item.time >= startTime);
    return filteredData;
  };

  return (
    <div
      className={`${PortfolioChartCSS["portfolio-chart"]} ${
        isDarkMode ? PortfolioChartCSS.darkModeContainer : ""
      }`}
    >
      <div className={PortfolioChartCSS["portfolio-chart-header"]}>
        <h6>Balance History</h6>
      </div>

      <div className={PortfolioChartCSS["portfolio-chart-container"]}>
        <div
          className={`${PortfolioChartCSS["chart-buttons"]} ${
            isDarkMode ? PortfolioChartCSS.darkModeContainer : ""
          }`}
        >
          <button
            onClick={() => setTimespan("1mo")}
            style={{
              color: isDarkMode ? "#F0F0F0" : "#000000",
              boxShadow:
                timespan === "1mo"
                  ? isDarkMode
                    ? "inset 0px -4px 0px 0px #387ADB"
                    : "inset 0px -4px 0px 0px #0B5DD7"
                  : isDarkMode
                  ? "inset 0px 0px 0px 0px #FFFFFF, inset 0px -1px 0px 0px #F4F9FF"
                  : "inset 0px 0px 0px 0px #000000, inset 0px -1px 0px 0px #000000",
            }}
          >
            M
          </button>
          <button
            onClick={() => setTimespan("3mo")}
            style={{
              color: isDarkMode ? "#F0F0F0" : "#000000",
              boxShadow:
                timespan === "3mo"
                  ? isDarkMode
                    ? "inset 0px -4px 0px 0px #387ADB"
                    : "inset 0px -4px 0px 0px #0B5DD7"
                  : isDarkMode
                  ? "inset 0px 0px 0px 0px #FFFFFF, inset 0px -1px 0px 0px #F4F9FF"
                  : "inset 0px 0px 0px 0px #000000, inset 0px -1px 0px 0px #000000",
            }}
          >
            3M
          </button>
          <button
            onClick={() => setTimespan("ytd")}
            style={{
              color: isDarkMode ? "#F0F0F0" : "#000000",
              boxShadow:
                timespan === "ytd"
                  ? isDarkMode
                    ? "inset 0px -4px 0px 0px #387ADB"
                    : "inset 0px -4px 0px 0px #0B5DD7"
                  : isDarkMode
                  ? "inset 0px 0px 0px 0px #FFFFFF, inset 0px -1px 0px 0px #F4F9FF"
                  : "inset 0px 0px 0px 0px #000000, inset 0px -1px 0px 0px #000000",
            }}
          >
            YTD
          </button>
          <button
            onClick={() => setTimespan("max")}
            style={{
              color: isDarkMode ? "#F0F0F0" : "#000000",
              boxShadow:
                timespan === "max"
                  ? isDarkMode
                    ? "inset 0px -4px 0px 0px #387ADB"
                    : "inset 0px -4px 0px 0px #0B5DD7"
                  : isDarkMode
                  ? "inset 0px 0px 0px 0px #FFFFFF, inset 0px -1px 0px 0px #F4F9FF"
                  : "inset 0px 0px 0px 0px #000000, inset 0px -1px 0px 0px #000000",
            }}
          >
            Max
          </button>
        </div>
        <div
          className={`${PortfolioChartCSS["chart-container"]} ${
            isDarkMode ? PortfolioChartCSS.darkModeContainer : ""
          }`}
        >
          <ChartComponent
            data={getDataByTimeRange(props.balanceHistory, timespan)}
            timespan={timespan}
            type={"area"}
          />
        </div>
      </div>
    </div>
  );
}

export default PortfolioChart;
