import React, { useState, useEffect } from "react";

function ErrorBoundary(props) {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const componentDidCatch = (error, info) => {
      setHasError(true);
      setErrorMessage(error.message);
      props.errorval(true);
    };

    window.addEventListener("error", componentDidCatch);

    return () => {
      window.removeEventListener("error", componentDidCatch);
    };
  }, [props]);

  useEffect(() => {
    if (!props.errorValue || props.current !== "technical") {
      // Reset the error state if errorValue becomes false or current is not "technical"
      setHasError(false);
      setErrorMessage("");
    }
  }, [props.errorValue, props.current]);

  if (hasError && props.current !== "technical") {
    return (
      <div>
        <h2>Something went wrong.</h2>
        {errorMessage && <p>{errorMessage}</p>}
      </div>
    );
  }

  return props.children;
}

export default ErrorBoundary;
