import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useMobile } from "contexts/MobileContext";
import { useDarkMode } from "contexts/DarkModeContext";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import CommunitiesCreatePostCSS from "./CommunitiesCreatePost.module.css";

function CommunitiesComment(props) {
  const { isDarkMode } = useDarkMode();
  const { isMobile } = useMobile();

  const {
    handleOpen,
    openComment,
    currentAccount,
    updateLedgerComment,
    userStatus,
  } = props;

  const { history: ledger } = currentAccount.ledger;

  const [title, setTitle] = useState("");
  const [titleStatus, setTitleStatus] = useState("");
  const [description, setDescription] = useState("");
  const [original, setOriginal] = useState("");
  const [descriptionStatus, setDescriptionStatus] = useState(false);

  const create = async (e) => {
    e.preventDefault();
    try {
      if (!title) {
        setTitleStatus("Something went wrong");
      } else if (!description) {
        setDescriptionStatus("Please enter a valid description");
      } else if (description === original) {
        handleOpen();
      } else {
        updateLedgerComment({
          communityId: currentAccount.community._id,
          portfolioId: currentAccount.portfolio._id,
          historyElementId: openComment._id,
          description: description,
        });
        handleOpen();
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          handleOpen();
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const cleanDescription = (value) => {
    setDescriptionStatus(false);
    if (value.length <= 280) {
      setDescription(value);
      if (value !== original) {
        setTitle(
          JSON.parse(localStorage.getItem("tempUser")).firstname +
            " " +
            JSON.parse(localStorage.getItem("tempUser")).lastname
        );
      }
    }
  };

  useEffect(() => {
    if (ledger.length > 0) {
      const matchedElement = ledger.find(
        (item) => item._id === openComment._id
      );
      if (matchedElement) {
        if (matchedElement.hasOwnProperty("comment")) {
          setTitle(
            matchedElement.comment.from.firstname +
              " " +
              matchedElement.comment.from.lastname
          );
          setDescription(matchedElement.comment.description);
          setOriginal(matchedElement.comment.description);
        } else {
          if (userStatus.isMod) {
            setTitle(
              `${JSON.parse(localStorage.getItem("tempUser")).firstname} ${
                JSON.parse(localStorage.getItem("tempUser")).lastname
              }`
            );
          } else {
            setDescription("There are no comments");
          }
        }
      }
    }
  }, [ledger, openComment, userStatus]);

  return (
    <div
      className={`${CommunitiesCreatePostCSS["communities-create"]} ${
        isDarkMode ? CommunitiesCreatePostCSS.darkModeContainer : ""
      }`}
      ref={wrapperRef}
    >
      <h4>{userStatus?.isMod ? "Comment" : "Comments"}</h4>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: "4.55px", width: "328px" },
          "@media screen and (max-width: 480px)": {
            "& .MuiTextField-root": {
              width: "260px", // Width for screens with max-width 900px or less
            },
          },
        }}
        noValidate
        autoComplete="off"
        onSubmit={create}
      >
        <TextField
          error={titleStatus ? true : false}
          id="first-name"
          type="string"
          label="From"
          variant="standard"
          style={{
            height: isMobile ? "54px" : "72px",
          }}
          InputLabelProps={{
            shrink: true,
            sx: {
              color: isDarkMode ? "#9CA9B9" : "", // Label color
            },
          }}
          sx={{
            "& .MuiInput-underline:before": {
              borderColor: isDarkMode ? "#9CA9B9 !important" : "",
            },
            "& .MuiInput-underline:hover:before": {
              borderColor: isDarkMode ? "#F0F0F0 !important" : "",
            },
            ".MuiSvgIcon-root": {
              fill: isDarkMode ? "#9CA9B9" : "",
            },
          }}
          InputProps={{
            sx: {
              color: isDarkMode ? "#F0F0F0" : "", // Text color
            },
          }}
          helperText={titleStatus && !isMobile ? titleStatus : " "}
          value={title}
          disabled={true}
        />

        <TextField
          id="description"
          label={
            <span className={CommunitiesCreatePostCSS["custom-label-text"]}>
              Description
            </span>
          }
          type="string"
          InputLabelProps={{
            shrink: true,
            sx: {
              color: isDarkMode ? "#9CA9B9" : "", // Label color
            },
          }}
          sx={{
            width: 141,
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: isDarkMode ? "#9CA9B9" : "",
            },
            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: isDarkMode ? "#F0F0F0" : "", // Border color on hover
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "#1976d2", // Border color when focused
              },
            "& .MuiOutlinedInput-root:hover": {
              "& .MuiOutlinedInput-input": {
                color: isDarkMode ? "#F0F0F0" : "", // Text color on hover
              },
            },
          }}
          InputProps={{
            style: {
              height: "86px",
            },
            sx: {
              color: isDarkMode ? "#F0F0F0" : "", // Text color
            },
          }}
          variant="outlined"
          multiline
          rows={3}
          value={description}
          onChange={(e) => cleanDescription(e.target.value)}
          error={descriptionStatus ? true : false}
          disabled={userStatus?.isMod ? false : true}
        />
        {userStatus?.isMod && (
          <span className={CommunitiesCreatePostCSS["word-count"]}>
            {description.length} / 280
          </span>
        )}

        {userStatus?.isMod && (
          <div className={CommunitiesCreatePostCSS["signup-bottom"]}>
            <button
              type="submit"
              className={`${CommunitiesCreatePostCSS["signup-button"]} ${
                isDarkMode ? CommunitiesCreatePostCSS.darkModeContainer : ""
              }`}
            >
              Submit
            </button>
          </div>
        )}
      </Box>
    </div>
  );
}

export default CommunitiesComment;
