import React, { createContext, useContext, useState, useEffect } from "react";
import { post } from "services/service";

const ColorPreferenceContext = createContext();

export const ColorPreferenceProvider = ({ children }) => {
  const [isAlternativeColor, setIsAlternativeColor] = useState(false);

  useEffect(() => {
    const tempUser = JSON.parse(localStorage.getItem("tempUser"));
    if (tempUser) {
      const savedAlternativeColor = tempUser.preferences.colorpreference;
      setIsAlternativeColor(savedAlternativeColor);
    }
  }, []);

  const toggleColorPreference = (value) => {
    const newColorPreference = !isAlternativeColor;
    setIsAlternativeColor(newColorPreference);

    const tempUser = JSON.parse(localStorage.getItem("tempUser"));
    if (tempUser) {
      updatePreference(newColorPreference);
      tempUser.preferences.colorpreference = newColorPreference;
      localStorage.setItem("tempUser", JSON.stringify(tempUser));
    }
  };

  const updatePreference = async (preference, value) => {
    try {
      const token = localStorage.getItem("token");
      if (token) {
        const requestData = {
          preferences: {
            colorpreference: value,
          },
        };
        const response = await post("/users/update", requestData);
        if (response.status === 200) {
          // Successfully updated the preference
        } else {
          // Handle errors
          console.error("Failed to update the color preference");
        }
      } else {
        return;
      }
    } catch (err) {
      console.error("Error updating preference:", err);
    }
  };

  return (
    <ColorPreferenceContext.Provider
      value={{
        isAlternativeColor,
        setIsAlternativeColor,
        toggleColorPreference,
      }}
    >
      {children}
    </ColorPreferenceContext.Provider>
  );
};

export const useColorPreference = () => {
  return useContext(ColorPreferenceContext);
};
