import React, { useState } from "react";

import { useDarkMode } from "contexts/DarkModeContext";

import { ImageSearchOutlined } from "@mui/icons-material";

import CommunitiesCSS from "./Communities.module.css";
import CommunitiesHeader from "./CommunitiesHeader";
import CommunitiesDiscussion from "./CommunitiesDiscussion";
import CommunitiesMembers from "./CommunitiesMembers";
import CommunitiesFund from "./CommunitiesFund";

function CommunitiesRight(props) {
  const { isDarkMode } = useDarkMode();

  const {
    currentCommunity = { community: {} },
    searchedCommunity = { community: {} },
    currentTab,
    posts,
    userStatus,
    searchStatus,
    handleEvent,
    updatePost,
    handleReplyEvent,
    loading,
    handleMemberEvent,
    updateReply,
    updatePortfolio,
    removeFromPending,
    updateLedgerComment,
  } = props;

  const [bottomContent, setBottomContent] = useState("discussion");

  const deleteCommunity = (value) => {
    props.deleteCommunity(value);
  };

  const updateCommunity = (value) => {
    props.updateCommunity(value);
  };

  const joinCommunity = (value) => {
    props.joinCommunity(value);
  };

  const newPost = (value) => {
    props.newPost(value);
  };

  const shouldShowCommunitiesHeader = () => {
    if (
      currentTab === "mycommunities" &&
      Object.keys(currentCommunity?.community || {}).length > 0
    ) {
      return true;
    }
    if (
      currentTab === "search" &&
      Object.keys(searchedCommunity?.community || {}).length > 0
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className={CommunitiesCSS["communities-right"]}>
      {shouldShowCommunitiesHeader() && (
        <CommunitiesHeader
          currentCommunity={
            currentTab === "mycommunities"
              ? currentCommunity
              : searchedCommunity
          }
          deleteCommunity={deleteCommunity}
          updateCommunity={updateCommunity}
          joinCommunity={joinCommunity}
          newPost={newPost}
          userStatus={
            currentTab === "mycommunities" ? userStatus : searchStatus
          }
          currentTab={currentTab}
        />
      )}

      {userStatus?.isMember &&
        currentTab === "mycommunities" &&
        Object.keys(currentCommunity.community).length > 0 && (
          <div
            className={`${CommunitiesCSS["communities-br-content-container"]} ${
              isDarkMode ? CommunitiesCSS.darkModeContainer : ""
            }`}
          >
            <div
              className={
                CommunitiesCSS["communities-br-content-button-container"]
              }
            >
              <button
                className={`${
                  CommunitiesCSS["communities-br-content-button"]
                } ${isDarkMode ? CommunitiesCSS.darkModeContainer : ""}`}
                onClick={() => setBottomContent("discussion")}
                style={{
                  borderColor:
                    bottomContent === "discussion"
                      ? isDarkMode
                        ? "#387ADB"
                        : "#0B5DD7"
                      : "#CED5DE",
                }}
              >
                Discussion
              </button>
              <button
                className={`${
                  CommunitiesCSS["communities-br-content-button"]
                } ${isDarkMode ? CommunitiesCSS.darkModeContainer : ""}`}
                onClick={() => setBottomContent("members")}
                style={{
                  borderColor:
                    bottomContent === "members"
                      ? isDarkMode
                        ? "#387ADB"
                        : "#0B5DD7"
                      : "#CED5DE",
                }}
              >
                Members
              </button>
              <button
                className={`${
                  CommunitiesCSS["communities-br-content-button"]
                } ${isDarkMode ? CommunitiesCSS.darkModeContainer : ""}`}
                onClick={() => setBottomContent("fund")}
                style={{
                  borderColor:
                    bottomContent === "fund"
                      ? isDarkMode
                        ? "#387ADB"
                        : "#0B5DD7"
                      : "#CED5DE",
                }}
              >
                {currentCommunity.community.ticker} Fund
              </button>
            </div>
            {bottomContent === "discussion" && (
              <CommunitiesDiscussion
                currentCommunity={currentCommunity}
                posts={posts}
                handleEvent={handleEvent}
                updatePost={updatePost}
                handleReplyEvent={handleReplyEvent}
                userStatus={userStatus}
                loading={loading}
                updateReply={updateReply}
              />
            )}
            {bottomContent === "members" && (
              <CommunitiesMembers
                currentCommunity={currentCommunity}
                userStatus={userStatus}
                handleMemberEvent={handleMemberEvent}
              />
            )}
            {bottomContent === "fund" && (
              <CommunitiesFund
                userStatus={userStatus}
                currentCommunity={currentCommunity}
                updatePortfolio={updatePortfolio}
                loading={loading}
                removeFromPending={removeFromPending}
                updateLedgerComment={updateLedgerComment}
              />
            )}
          </div>
        )}
    </div>
  );
}
export default CommunitiesRight;
