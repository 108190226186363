import {
  createChart,
  ColorType,
  LineStyle,
  // CrosshairMode,
} from "lightweight-charts";
import React, { useEffect, useRef, useState } from "react";
// import ChartComponentCSS from "components/ChartComponent.module.css";
import { useDarkMode } from "contexts/DarkModeContext";
import { useColorPreference } from "contexts/ColorPreferenceContext";
import { useMobile } from "contexts/MobileContext";
import { createCalculatePriceColor } from "utils/colorUtils";
import { formatNumber } from "utils/formatNumber";
// import { Directions } from "@mui/icons-material";
// import { Line } from "react-chartjs-2";

export const ChartComponent = (props) => {
  const { isMobile } = useMobile();
  const { isDarkMode } = useDarkMode();
  const { isAlternativeColor } = useColorPreference();

  const calculateColor = createCalculatePriceColor(
    isDarkMode,
    isAlternativeColor
  );
  const positiveColor = calculateColor(true);
  const negativeColor = calculateColor(false);

  const getPnlColor = (pnl) => {
    if (pnl > 0) {
      return positiveColor;
    } else if (pnl < 0) {
      return negativeColor;
    } else {
      return "#757D87";
    }
  };

  const {
    data,
    timespan,
    colors: {
      backgroundColor = isDarkMode ? "#272835" : "white",
      lineColor = "#2962FF",
      textColor = isDarkMode ? "#F0F0F0" : "#000000",
      areaTopColor = "#2962FF",
      areaBottomColor = "rgba(41, 98, 255, 0.28)",
      boxShadow = isDarkMode
        ? "inset 0px 0px 0px 0px #FFFFFF, inset 0px -1px 0px 0px #F4F9FF, inset 0px 1px 0px 0px #F4F9FF"
        : "inset 0px 0px 0px 0px #000000, inset 0px -1px 0px 0px #000000, inset 0px 1px 0px 0px #000000",
      tooltipBorder = isDarkMode ? "1px solid #F0F0F0" : "1px solid #000000",
    } = {},
  } = props;

  const chartContainerRef = useRef();

  const tooltipRef = useRef(null);
  const backgroundRef = useRef(null);

  const [dat, setDat] = useState(null); // Flag to track pressing state
  const [areaPrice, setAreaPrice] = useState(null); // Flag to track pressing state
  const [currentTime, setCurrentTime] = useState(null); // Flag to track pressing state
  const [linePrice, setLinePrice] = useState(null); // Flag to track pressing state

  useEffect(() => {
    if (data) {
      if (JSON.stringify(dat) !== JSON.stringify(data)) {
        setDat(data);
      }
    }
  }, [data]);

  useEffect(() => {
    const chartContainer = chartContainerRef.current;

    const handleResize = () => {
      if (!chartContainer) {
        return;
      }
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    const chartOptions = {
      layout: {
        background: {
          type: ColorType.Solid,
          color: backgroundColor,
        },
        textColor,
      },
      width: chartContainerRef.current.clientWidth,
      height: isMobile ? 200 : 300,
      grid: {
        vertLines: {
          visible: false,
        },
        horzLines: {
          visible: false,
        },
      },
      timeScale: {
        lockVisibleTimeRange: true,
        fixLeftEdge: true,
        fixRightEdge: true,
      },
    };

    const chart = createChart(chartContainerRef.current, chartOptions);

    chart.applyOptions({
      crosshair: {
        vertLine: {
          visible: true,
          labelVisible: false,
          style: LineStyle.Dashed,
        },
        horzLine: {
          visible: false,
          labelVisible: false,
          style: LineStyle.Dashed,
        },
      },
      timeScale: {
        timeVisible: timespan === "1d",
        secondsVisible: false,
      },
      rightPriceScale: {
        visible: false,
      },
      handleScroll: {
        mouseWheel: false,
        pressedMouseMove: false,
      },
      handleScale: {
        axisPressedMouseMove: false,
        mouseWheel: false,
        pinch: false,
      },
    });

    // const areaSeries = chart.addAreaSeries({
    //   lastValueVisible: false, // hide the last value marker for this series
    //   crosshairMarkerVisible: false, // hide the crosshair marker for this series
    //   lineColor: "transparent", // hide the line
    //   topColor: "rgba(56, 33, 110,0.6)",
    //   bottomColor: "rgba(56, 33, 110, 0.1)",
    // });

    const series = chart.addAreaSeries({
      lineColor,
      lineType: 2,
      topColor: areaTopColor,
      bottomColor: areaBottomColor,
      priceLineVisible: false,
      lastValueVisible: false,
    });

    const filteredData = dat && dat.filter((item) => item.value !== null);

    if (
      filteredData &&
      filteredData.length > 0 &&
      typeof filteredData[0].value === "number"
    ) {
      series.setData(filteredData);
      const lineDataPointValue = filteredData[0].value;
      setLinePrice(lineDataPointValue);

      const DataPointPriceLine = {
        price: lineDataPointValue,
        color: "#9598A1",
        lineWidth: 1,
        lineStyle: 2,
      };

      series.createPriceLine(DataPointPriceLine);
    }

    chart.subscribeCrosshairMove((param) => {
      if (
        param.point === undefined ||
        !param.time ||
        param.point.x < 0 ||
        param.point.x > chartContainerRef.current.clientWidth ||
        param.point.y < 0 ||
        param.point.y > chartContainerRef.current.clientHeight
      ) {
        tooltipRef.current.style.display = "none";
        backgroundRef.current.style.display = "none";
        setAreaPrice(null);
        setCurrentTime(null);
      } else {
        const dateStr = param.time;

        tooltipRef.current.style.display = "block";
        backgroundRef.current.style.display = "block";

        const data2 = param.seriesData.get(series);
        const price = data2.value !== undefined ? data2.value : null;

        setAreaPrice(price);
        setCurrentTime(dateStr);

        let left = param.point.x;
        const timeScaleWidth = chart.timeScale().width();
        const priceScaleWidth = chart.priceScale("left").width();
        const halfTooltipWidth = tooltipRef.current.offsetWidth / 2;
        left += priceScaleWidth - halfTooltipWidth;
        left = Math.min(
          left,
          priceScaleWidth + timeScaleWidth - tooltipRef.current.offsetWidth
        );
        left = Math.max(left, priceScaleWidth);

        tooltipRef.current.style.left = left + "px";
      }
    });

    chart.timeScale().fitContent();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.remove();
    };
  }, [
    dat,
    backgroundColor,
    lineColor,
    textColor,
    areaTopColor,
    areaBottomColor,
    isMobile,
    isDarkMode,
    timespan,
  ]);

  return (
    <div ref={chartContainerRef} style={{ position: "relative" }}>
      <div
        ref={backgroundRef}
        style={{
          position: "absolute",
          width: "100%",
          height: 48,
          backgroundColor: backgroundColor,
          padding: 0,
          margin: 0,
          // boxShadow: boxShadow,
          zIndex: 1,
          top: "-48px",
          display: areaPrice ? "block" : "none",
        }}
      ></div>
      <div
        ref={tooltipRef}
        style={{
          position: "absolute",
          flexDirection: "column",
          width: "auto",
          height: 48,
          padding: "0px 4px",
          color: textColor,
          zIndex: 2,
          top: "-48px",
          display: areaPrice ? "flex" : "none",
          borderRadius: 5,
          border: tooltipBorder,
          background: backgroundColor,
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: 24,
          }}
        >
          <p
            style={{
              color: textColor,
              margin: 0,
              height: "auto",
              width: "100%",
            }}
          >
            {formatNumber(areaPrice)}
          </p>
          <p
            style={{
              color: getPnlColor(areaPrice - linePrice),
              margin: "0px 0px 0px 5px",
              height: "auto",
              width: "100%",
            }}
          >
            {formatNumber(areaPrice - linePrice)}
          </p>
          <p
            style={{
              color: getPnlColor(areaPrice - linePrice),
              margin: "0px 0px 0px 5px",
              height: "auto",
              width: "100%",
            }}
          >
            ({formatNumber(((areaPrice - linePrice) / areaPrice) * 100)})%
          </p>
        </div>
        <p
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            color: textColor,
            margin: 0,
            height: 24,
            width: "100%",
          }}
        >
          {timespan === "1d"
            ? new Date(currentTime * 1000).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })
            : new Date(currentTime * 1000).toLocaleDateString()}
        </p>
      </div>
    </div>
  );
};
