import React from "react";
import HomeCSS from "./Home.module.css";
import { useNavigate } from "react-router-dom";
import { get } from "services/service";
import background from "assets/background.png";
import boy from "assets/boy.png";
import girl from "assets/girl.png";
import Footer from "components/Home/Footer";
import Login from "components/Home/Login";
import Signup from "components/Home/Signup";
import ResetPassword from "components/Home/ResetPassword";
import { useMobile } from "contexts/MobileContext";

function Home(props) {
  const { isMobile } = useMobile();

  const token = localStorage.getItem("token");
  const user = localStorage.getItem("username");
  const [isOpen, setIsOpen] = React.useState(false);
  const [which, setWhich] = React.useState("");
  const [openlogin, setOpenlogin] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const navigate = useNavigate();

  const loginCheck = async () => {
    try {
      let response = await get("/users/login-test");
      navigate("/dashboard/portfolio");
    } catch (err) {
      if (err.response.status === 440 || err.response.status === 400) {
        console.log(err.response.status);
        localStorage.clear();
        navigate("/");
      }
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    loginCheck();
  }, []);

  const openSignup = () => {
    setIsOpen(true);
    setWhich("signup");
  };
  const click = (data) => {
    setIsOpen(data);
    props.closelogin(data);
    if (data === false) {
      setWhich("");
    }
  };

  const clack = (data) => {
    setWhich(data);
  };

  React.useEffect(() => {
    setIsOpen(props.openlogin);
    if (props.openlogin === true) {
      setWhich("login");
    }
  }, [props.openlogin]);

  if (loading === false) {
    return (
      <div className={HomeCSS.home}>
        <h1>Make Smart, Data driven decisions!</h1>
        <h4>Paper trade with the latest Market Data</h4>

        <button onClick={openSignup} className={HomeCSS["signin-button"]}>
          Try Today!
        </button>
        {isOpen && which === "signup" ? (
          <Signup open={click} form={clack} />
        ) : (
          ""
        )}
        {isOpen && which === "login" ? <Login open={click} form={clack} /> : ""}
        {openlogin ? <Login open={click} form={clack} /> : ""}
        {openlogin && which === "signup" ? (
          <Signup open={click} form={clack} />
        ) : which === "reset" ? (
          <ResetPassword open={click} form={clack} />
        ) : (
          ""
        )}
        {isOpen ? <div className="faded-background"></div> : ""}
        {openlogin ? <div className="faded-background"></div> : ""}
        {isMobile ? (
          <div className={HomeCSS["image-container"]}>
            <img
              src={background}
              alt="background"
              className={HomeCSS["background-image"]}
            />
          </div>
        ) : (
          <div className={HomeCSS["image-container"]}>
            <img
              src={background}
              alt="background"
              className={HomeCSS["background-image"]}
            />
            <img src={boy} alt="boy" className={HomeCSS["boy-image"]} />
            <img src={girl} alt="girl" className={HomeCSS["girl-image"]} />
          </div>
        )}
        <div className={HomeCSS["footer-container"]}>
          <Footer />
        </div>
      </div>
    );
  }
}

export default Home;
