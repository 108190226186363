import React, { useEffect, useState, useRef } from "react";
import LoginCSS from "./Login.module.css";
import { post } from "services/service";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useDarkMode } from "contexts/DarkModeContext";
import { useColorPreference } from "contexts/ColorPreferenceContext";
import { useMobile } from "contexts/MobileContext";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const Login = (props) => {
  const { isMobile } = useMobile();

  const [usernameOrEmail, setUsernameOrEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  // const [status, setStatus] = useState("");
  const [userStatus, setUserStatus] = useState("");
  const [passStatus, setPassStatus] = useState("");
  const [loading, setLoading] = useState(false);

  const { setIsDarkMode, toggleDarkMode } = useDarkMode();
  const { setIsAlternativeColor, toggleColorPreference } = useColorPreference();

  const navigate = useNavigate();

  const login = async (e) => {
    e.preventDefault();
    // if (usernameOrEmail !== "miguel" && usernameOrEmail !== "luis") {
    //   setUserStatus("signup is currently limited");
    //   return;
    // }
    let hasError = false;
    if (!usernameOrEmail) {
      setUserStatus("Please include username or email");
      hasError = true;
    }
    if (!password) {
      setPassStatus("Please include password");
      hasError = true;
    }
    if (!hasError) {
      localStorage.clear();
      setLoading(true);
      try {
        let response = await post("/users/login", {
          usernameOrEmail: usernameOrEmail,
          password: password,
        });
        console.log(response);
        if (response.data && response.status === 200) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("tempUser", JSON.stringify(response.data.user));
          props.open(false);
          setIsAlternativeColor(response.data.user.preferences.colorpreference);
          setIsDarkMode(response.data.user.preferences.darkmode);
          navigate("/dashboard/portfolio");
        }
      } catch (err) {
        if (err.response.data.error.toLowerCase().includes("user")) {
          setUserStatus(err.response.data.error);
        } else if (err.response.data.error.toLowerCase().includes("password")) {
          setPassStatus(err.response.data.error);
        } else {
          setUserStatus("Something went wrong");
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          props.open(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const cleanUser = (value) => {
    setUserStatus("");
    setUsernameOrEmail(value.toLowerCase());
  };

  const cleanPass = (value) => {
    setPassStatus("");
    setPassword(value);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className={LoginCSS.login} ref={wrapperRef}>
      <h4>Log In</h4>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: "4.55px", width: "328px" },
          "@media screen and (max-width: 480px)": {
            "& .MuiTextField-root": {
              width: "260px", // Width for screens with max-width 900px or less
            },
          },
        }}
        noValidate
        autoComplete="off"
        onSubmit={login}
      >
        <TextField
          error={userStatus ? true : false}
          id="username-login"
          type="string"
          label="Username or Email"
          variant="standard"
          style={{
            height: isMobile ? "42px" : "42px",
          }}
          value={usernameOrEmail}
          onChange={(e) => cleanUser(e.target.value)}
        />
        <TextField
          error={passStatus ? true : false}
          id="password-login"
          type={showPassword ? "text" : "password"}
          label="Password"
          variant="standard"
          style={{
            height: isMobile ? "42px" : "42px",
          }}
          value={password}
          onChange={(e) => cleanPass(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  onMouseUp={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <p className={LoginCSS["forgot-password"]}>
          <span
            className={LoginCSS["forgot-password-link"]}
            onClick={() => {
              props.form("reset");
            }}
          >
            Forgot Password?
          </span>
        </p>
        <button
          type="submit"
          className={LoginCSS["login-button"]}
          disabled={loading}
        >
          {loading ? <CircularProgress size={20} /> : "Log In"}
        </button>
        <p className={LoginCSS["signup"]}>
          Don't have an account?
          <span
            onClick={() => {
              props.form("signup");
            }}
          >
            {" "}
            Sign up
          </span>
        </p>
      </Box>
    </div>
  );
};

export default Login;
