import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { get, post, remove } from "services/service";

import { useAppUse } from "contexts/AppUseContext";
import { useMobile } from "contexts/MobileContext";
import { useSubTab } from "contexts/SubTabContext";
import { marketHoursCheck } from "utils/marketHoursCheck";

import CommunitiesCSS from "./Communities.module.css";
import PortfolioCSS from "../Portfolio/Portfolio.module.css";
import CommunitiesLeft from "./CommunitiesLeft";
import CommunitiesRight from "./CommunitiesRight";

function Communities() {
  const { isUsingApp } = useAppUse();
  const { isMobile } = useMobile();
  const { subTab, setSubTab } = useSubTab();

  const [hasCrypto, setHasCrypto] = useState(false);
  const [tempCommunities, setTempCommunities] = useState({
    communityId: null,
    communities: [],
    portfolios: [],
    ledgers: [],
    portfolioData: [],
  });
  const [currentCommunity, setCurrentCommunity] = useState({
    community: {},
    portfolio: {},
    ledger: {},
    portfolioData: {},
  });
  const [posts, setPosts] = useState([]);
  const [currentTab, setCurrentTab] = useState("mycommunities");
  const [searchedCommunity, setSearchedCommunity] = useState({
    communityId: null,
    community: {},
  });
  const [userStatus, setUserStatus] = useState({
    isMember: false,
    isMod: false,
  });
  const [searchStatus, setSearchStatus] = useState({
    isMember: false,
    isMod: false,
  });
  const [loading, setLoading] = useState({
    main: true,
    price: false,
    posts: true,
  });

  const navigate = useNavigate();

  let isFetching = false;
  // fetch communities, portfolios and ledgers
  const fetchCommunities = async (communityId) => {
    if (isFetching) {
      return;
    } else {
      isFetching = true;
      try {
        setLoading((prevState) => ({
          ...prevState,
          price: true,
        }));
        const response = await get(
          `/communities/communities-portfolios-ledgers?skip=${false}`
        );
        console.log(response);

        if (!communityId && response.data.communities.length > 0) {
          setTempCommunities((prevState) => ({
            ...prevState,
            communityId:
              response.data.communities[response.data.communities.length - 1]
                ._id,
            communities: response.data.communities,
            portfolios: response.data.portfolios,
            ledgers: response.data.ledgers,
            portfolioData: response.data.portfolioData,
          }));
        } else {
          setTempCommunities((prevState) => ({
            communityId: communityId,
            communities: response.data.communities,
            portfolios: response.data.portfolios,
            ledgers: response.data.ledgers,
            portfolioData: response.data.portfolioData,
          }));
        }
      } catch (err) {
        console.error(err.message);
      } finally {
        if (loading.main) {
          setLoading((prevState) => ({
            ...prevState,
            main: false,
            price: false,
          }));
        } else {
          setLoading((prevState) => ({
            ...prevState,
            price: false,
          }));
        }
      }
    }
  };

  function oneHasCrypto(portfolios) {
    for (const portfolio of portfolios) {
      if (
        portfolio &&
        portfolio.positions &&
        portfolio.positions.some(
          (position) => position.assetType === "CRYPTOCURRENCY"
        )
      ) {
        return true; // At least one portfolio has a "CRYPTOCURRENCY" position
      }
    }
    return false; // No portfolio has "CRYPTOCURRENCY" positions
  }

  async function createCommunity(value) {
    if (!loading.main && tempCommunities.communities.length < 5) {
      setLoading((prevState) => ({
        ...prevState,
        main: true,
        price: true,
      }));
      try {
        const response = await post(`/communities/create`, value);

        setTempCommunities((prevState) => ({
          ...prevState,
          communityId:
            response.data.communities[response.data.communities.length - 1]._id,
          communities: response.data.communities,
          portfolios: response.data.portfolios,
          ledgers: response.data.ledgers,
          portfolioData: response.data.portfolioData,
        }));
      } catch (err) {
        console.log(err.message);
      } finally {
        setLoading((prevState) => ({
          ...prevState,
          main: false,
          price: false,
        }));
      }
    } else {
      handleAlert(true);
    }
  }

  const updateCommunity = async (value) => {
    try {
      const updatedTempCommunities = await post(`/communities/update`, value);
      console.log("updatedTempCommunities", updatedTempCommunities);
      setTempCommunities((prevState) => ({
        ...prevState,
        communities: updatedTempCommunities.data.communities,
      }));
      console.log(updatedTempCommunities.data);
    } catch (err) {
      console.error(err.message);
    }
  };

  const deleteCommunity = async (value) => {
    try {
      const response = await remove(`/communities/delete/${value}`);
      const communityIdNotPresent = !response.data.communities.some(
        (community) => community._id === value
      );

      let newCommunityId;

      if (communityIdNotPresent) {
        if (response.data.communities.length > 0) {
          newCommunityId =
            response.data.communities[response.data.communities.length - 1]._id;
        } else {
          newCommunityId = null;
        }
      } else {
        newCommunityId = value;
      }

      setTempCommunities({
        communityId: newCommunityId,
        communities: response.data.communities,
        portfolios: response.data.portfolios,
        ledgers: response.data.ledgers,
        portfolioData: response.data.portfolioData,
      });
      setSubTab(!subTab);
    } catch (err) {
      console.log(err.message);
    }
  };

  const searchCommunity = async (value) => {
    if (value) {
      try {
        const response = await get(`/communities/search/${value}`);
        if (response.data) {
          setSearchedCommunity({
            communityId: value,
            community: response.data.community,
          });
          setSubTab(!subTab);
        }
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  const joinCommunity = async (value) => {
    try {
      const response = await post(`/communities/join/${value}`);

      setTempCommunities((prevState) => ({
        ...prevState,
        communityId:
          response.data.communities[response.data.communities.length - 1]._id,
        communities: response.data.communities,
        portfolios: response.data.portfolios,
        ledgers: response.data.ledgers,
        portfolioData: response.data.portfolioData,
      }));
      if (currentTab === "search") {
        setCurrentTab("mycommunities");
        setSearchedCommunity({
          communityId: null,
          community: {},
        });
      }
      setSubTab(!subTab);
    } catch (err) {
      console.log(err.message);
    }
  };

  const fetchPosts = async (value) => {
    try {
      setLoading((prevState) => ({
        ...prevState,
        posts: true,
      }));

      const response = await get(`/communities/community-posts/${value}`);

      setPosts(response.data);
      //   setCurrentCommunity((prevState) => ({
      //   ...prevState,
      //   posts: response.data,
      // }));
    } catch (err) {
      console.log(err.message);
      return [];
    } finally {
      setLoading((prevState) => ({
        ...prevState,
        posts: false,
      }));
    }
  };

  const newPost = async (value) => {
    try {
      const response = await post(`/communities/new-post`, value);
      setPosts(response.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const updatePost = async (data) => {
    try {
      const response = await post(`/communities/update-post`, data);
      setPosts(response.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleEvent = async (event) => {
    try {
      const response = await post(`/communities/update-post`, event);
      setPosts(response.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleMemberEvent = async (event) => {
    try {
      const response = await post(`/communities/update-member`, event);
      console.log(response);
      setTempCommunities((prevState) => ({
        ...prevState,
        communities: response.data.communities,
      }));
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleReplyEvent = async (event) => {
    try {
      const response = await post(`/communities/update-reply`, event);
      setPosts(response.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  async function updatePortfolio(tradeData) {
    if (userStatus.isMod) {
      try {
        const response = await post(`/trade/community-update`, { tradeData });
        setTempCommunities((prevState) => ({
          ...prevState,
          communities: response.data.communities,
          portfolios: response.data.portfolios,
          ledgers: response.data.ledgers,
          portfolioData: response.data.portfolioData,
        }));
      } catch (err) {
        console.log(err.message);
      }
    }
  }

  async function removeFromPending(tradeData) {
    if (userStatus.isMember) {
      try {
        const response = await remove(
          `/trade/remove-community-order/${tradeData._id}`
        );
        setTempCommunities((prevState) => ({
          ...prevState,
          ledgers: response.data.ledgers,
        }));
      } catch (err) {
        console.log(err.message);
      }
    }
  }

  async function updateLedgerComment(comment) {
    if (userStatus.isMod) {
      try {
        const response = await post(`/communities/update-comment`, comment);
        setTempCommunities((prevState) => ({
          ...prevState,
          ledgers: response.data.ledgers,
        }));
      } catch (err) {
        console.log(err.message);
      }
    }
  }

  const currentClick = (value) => {
    setCurrentTab(value);
  };

  const communityClick = (value) => {
    setTempCommunities((prevState) => ({
      ...prevState,
      communityId: value,
    }));
  };

  const handleAlert = () => {
    // if (communityStatus === false) {
    //   setCommunityStatus(true);
    //   setTimeout(() => {
    //     setCommunityStatus(false);
    //   }, 5000);
    // }
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    } else {
      try {
        const savedCommunities = JSON.parse(
          localStorage.getItem("tempCommunities")
        );
        let savedTrade = JSON.parse(localStorage.getItem("tempTrade"));
        let bought = true;
        if (
          savedTrade &&
          savedCommunities &&
          savedCommunities.communityId &&
          savedCommunities.portfolioData.length > 0
        ) {
          bought = savedTrade.bought.community;
        } else if (
          !savedTrade &&
          savedCommunities &&
          savedCommunities.communityId &&
          savedCommunities.portfolioData.length > 0
        ) {
          bought = false;
        }
        if (
          savedCommunities &&
          savedCommunities.communityId &&
          savedCommunities.portfolioData.length > 0 &&
          !bought
        ) {
          setTempCommunities({
            communityId: savedCommunities.communityId,
            communities: savedCommunities.communities,
            portfolios: savedCommunities.portfolios,
            ledgers: savedCommunities.ledgers,
            portfolioData: savedCommunities.portfolioData,
          });
          setLoading((prevState) => ({
            ...prevState,
            main: false,
          }));
        } else {
          let defaultAccount = null;
          if (savedCommunities && savedCommunities.communityId) {
            defaultAccount = savedCommunities.communityId;
          }
          fetchCommunities(defaultAccount).then(() => {
            if (savedTrade) {
              savedTrade.bought.community = false;
              localStorage.setItem("tempTrade", JSON.stringify(savedTrade));
            }
          });
        }
      } catch (err) {
        console.log(err.messsage);
        throw err;
      }
      // finally {
      // setLoading((prevState) => ({
      //   ...prevState,
      //   main: false,
      // }));
      // }
    }
  }, []);

  // Interval to fetch communities, portfolios and ledgers
  // Confirm if I need to check tempCommunities or currentCommunity
  useEffect(() => {
    const interval = setInterval(() => {
      if (
        !loading.main &&
        !loading.price &&
        //check for all portfolios, not just for one
        Object.keys(tempCommunities.communities).length > 0 &&
        Object.keys(tempCommunities.portfolios).length > 0 &&
        Object.keys(tempCommunities.portfolioData).length > 0 &&
        isUsingApp &&
        ((currentCommunity && marketHoursCheck().isMarketOpen) ||
          (currentCommunity && hasCrypto)) &&
        currentCommunity.portfolio.positions.length > 0
      ) {
        console.log("fetching");
        fetchCommunities(tempCommunities.communityId);
      }
    }, 20000);

    return () => clearInterval(interval);
  }, [loading, isUsingApp, tempCommunities, hasCrypto]);

  useEffect(() => {
    if (!loading.main) {
      localStorage.setItem("tempCommunities", JSON.stringify(tempCommunities));
    }
  }, [loading.main, tempCommunities]);

  // Filter current community
  useEffect(() => {
    if (
      tempCommunities.communityId &&
      tempCommunities.communities.length > 0 &&
      tempCommunities.portfolios.length > 0 &&
      tempCommunities.ledgers.length > 0 &&
      tempCommunities.portfolioData.length > 0
    ) {
      const foundCommunity = tempCommunities.communities.find(
        (community) => community._id === tempCommunities.communityId
      );
      const foundPortfolio = tempCommunities.portfolios.find(
        (portfolio) => portfolio.communityId._id === tempCommunities.communityId
      );
      const foundLedger = tempCommunities.ledgers.find(
        (ledger) => ledger.portfolioId === foundPortfolio._id
      );
      const foundPortfolioData = tempCommunities.portfolioData.find(
        (data) => data.portfolioId === foundPortfolio._id
      );

      setCurrentCommunity((prevState) => ({
        ...prevState,
        community: foundCommunity ? foundCommunity : {},
        portfolio: foundPortfolio ? foundPortfolio : {},
        ledger: foundLedger ? foundLedger : {},
        portfolioData: foundPortfolioData ? foundPortfolioData : {},
      }));

      const hasCryptocurrencyPortfolio =
        foundPortfolio && oneHasCrypto(tempCommunities.portfolios);
      setHasCrypto(hasCryptocurrencyPortfolio);
    } else if (
      !tempCommunities.communityId &&
      tempCommunities.communities.length === 0 &&
      tempCommunities.portfolios.length === 0 &&
      tempCommunities.ledgers.length === 0 &&
      tempCommunities.portfolioData.length === 0
    ) {
      setCurrentCommunity((prevState) => ({
        ...prevState,
        community: {},
        portfolio: {},
        ledger: {},
        portfolioData: {},
      }));
    }
  }, [tempCommunities.communityId, tempCommunities]);

  // User check
  useEffect(() => {
    if (
      currentCommunity &&
      currentCommunity.community &&
      currentCommunity.community.members
    ) {
      const user = JSON.parse(localStorage.getItem("tempUser"));

      if (user) {
        const userVals = {
          isMember: currentCommunity.community.members.some(
            (member) => member.user._id === user._id
          ),
          isMod: currentCommunity.community.members.some(
            (member) => member.user._id === user._id && member.moderator
          ),
        };

        setUserStatus(userVals);
      }

      fetchPosts(currentCommunity.community._id);
    }
  }, [currentCommunity]);

  useEffect(() => {
    if (
      searchedCommunity &&
      searchedCommunity.community &&
      searchedCommunity.community.members
    ) {
      const user = JSON.parse(localStorage.getItem("tempUser"));
      if (user) {
        const searchVals = {
          isMember: searchedCommunity.community.members.some(
            (member) => member.user._id === user._id
          ),
          isMod: searchedCommunity.community.members.some(
            (member) => member.user._id === user._id && member.moderator
          ),
        };

        setSearchStatus(searchVals);
      }
    }
  }, [searchedCommunity]);

  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    let timer;
    if (loading.main && loading.price) {
      timer = setTimeout(() => {
        setShowLoading(true);
      }, 200); // 0.1 seconds delay
    } else {
      setShowLoading(false);
    }

    return () => clearTimeout(timer); // Cleanup the timer on unmount or before the next effect
  }, [loading.main, loading.price]);

  return (
    <div className={CommunitiesCSS.communities}>
      {!isMobile && !loading.main && (
        <CommunitiesLeft
          tempCommunities={tempCommunities}
          currentCommunity={currentCommunity}
          currentTab={currentTab}
          currentClick={currentClick}
          loading={loading}
          communityClick={communityClick}
          createCommunity={createCommunity}
          searchCommunity={searchCommunity}
          searchedCommunity={searchedCommunity}
        />
      )}
      {!isMobile && !loading.main && (
        <CommunitiesRight
          currentCommunity={currentCommunity}
          searchedCommunity={searchedCommunity}
          currentTab={currentTab}
          posts={posts}
          deleteCommunity={deleteCommunity}
          updateCommunity={updateCommunity}
          joinCommunity={joinCommunity}
          newPost={newPost}
          handleEvent={handleEvent}
          updatePost={updatePost}
          handleReplyEvent={handleReplyEvent}
          userStatus={userStatus}
          searchStatus={searchStatus}
          updatePortfolio={updatePortfolio}
          loading={loading}
          handleMemberEvent={handleMemberEvent}
          updateReply={handleReplyEvent}
          removeFromPending={removeFromPending}
          updateLedgerComment={updateLedgerComment}
        />
      )}
      {isMobile && !loading.main && subTab && (
        <CommunitiesLeft
          tempCommunities={tempCommunities}
          currentCommunity={currentCommunity}
          currentTab={currentTab}
          currentClick={currentClick}
          loading={loading}
          communityClick={communityClick}
          createCommunity={createCommunity}
          searchCommunity={searchCommunity}
          searchedCommunity={searchedCommunity}
        />
      )}
      {isMobile && !loading.main && !subTab && (
        <CommunitiesRight
          currentCommunity={currentCommunity}
          searchedCommunity={searchedCommunity}
          currentTab={currentTab}
          posts={posts}
          deleteCommunity={deleteCommunity}
          updateCommunity={updateCommunity}
          joinCommunity={joinCommunity}
          newPost={newPost}
          handleEvent={handleEvent}
          updatePost={updatePost}
          handleReplyEvent={handleReplyEvent}
          userStatus={userStatus}
          searchStatus={searchStatus}
          updatePortfolio={updatePortfolio}
          loading={loading}
          handleMemberEvent={handleMemberEvent}
          updateReply={handleReplyEvent}
          removeFromPending={removeFromPending}
          updateLedgerComment={updateLedgerComment}
        />
      )}
      {showLoading && (
        <p className={PortfolioCSS["placeholder-text"]}>Loading...</p>
      )}
    </div>
  );
}
export default Communities;
