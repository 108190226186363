import React from "react";
import TradePositionCSS from "./TradePosition.module.css";
import { useDarkMode } from "contexts/DarkModeContext";
import { useColorPreference } from "contexts/ColorPreferenceContext";
import { createCalculatePriceColor } from "utils/colorUtils";
import { formatNumber } from "utils/formatNumber";
import CommunitiesDiscussionCSS from "components/Dashboard/Communities/CommunitiesDiscussion.module.css";
import PortfolioPositionsCSS from "../Portfolio/PortfolioPositions.module.css";

function TradePosition(props) {
  const { positions, price, loading } = props;
  const { isDarkMode } = useDarkMode();
  const { isAlternativeColor } = useColorPreference();
  const calculateColor = createCalculatePriceColor(
    isDarkMode,
    isAlternativeColor
  );
  const positiveColor = calculateColor(true);
  const negativeColor = calculateColor(false);

  const getPnlColor = (pnl) => {
    if (pnl > 0) {
      return positiveColor;
    } else if (pnl < 0) {
      return negativeColor;
    } else {
      return "#757D87";
    }
  };

  return (
    <div
      className={`${PortfolioPositionsCSS["trade-position"]} ${
        isDarkMode ? PortfolioPositionsCSS.darkModeContainer : ""
      }`}
    >
      <div
        className={`${PortfolioPositionsCSS["trade-position-container"]} ${
          isDarkMode ? PortfolioPositionsCSS.darkModeContainer : ""
        }`}
      >
        <table
          className={`${PortfolioPositionsCSS["positions-table"]} ${
            isDarkMode ? PortfolioPositionsCSS.darkModeContainer : ""
          }`}
        >
          <thead>
            <tr>
              <th>Symbol</th>
              <th>Quantity</th>
              <th>Last Price</th>
              <th>Current Value</th>
              <th>Total Gain/Loss</th>
              <th>Cost Basis</th>
            </tr>
          </thead>
          <tbody>
            {positions.length !== 0
              ? positions.map((position) => {
                  const pnl = position.averagePrice
                    ? (
                        (price - position.averagePrice) *
                        position.amount
                      ).toFixed(2)
                    : "-";
                  const pnlPercentage = position.averagePrice
                    ? ((price - position.averagePrice) /
                        position.averagePrice) *
                      100
                    : "-";

                  return (
                    <tr key={position._id}>
                      <td className={PortfolioPositionsCSS["position-name"]}>
                        <span className={PortfolioPositionsCSS["ticker"]}>
                          {position.ticker}
                        </span>
                        <span className={PortfolioPositionsCSS["shortname"]}>
                          {position.name}
                        </span>
                      </td>
                      <td>{formatNumber(position.amount)}</td>
                      <td>{price ? formatNumber(price) : "-"}</td>
                      <td>{formatNumber(price * position.amount)}</td>
                      <td
                        style={{ color: getPnlColor(pnl) }}
                        className={PortfolioPositionsCSS["price"]}
                      >
                        <span>{formatNumber(pnl)}</span>
                        <span>({formatNumber(pnlPercentage)}%)</span>
                      </td>
                      <td>
                        {position.averagePrice !== undefined
                          ? formatNumber(position.averagePrice)
                          : "-"}
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
        {!loading && !positions.length ? (
          <p className={PortfolioPositionsCSS["placeholder-text"]}>
            There are no positions
          </p>
        ) : null}
      </div>
    </div>
  );
}

export default TradePosition;
