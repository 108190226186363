import React, { useState } from "react";
import PositionContainerCSS from "../Portfolio/PositionContainer.module.css";
import TradePosition from "./TradePosition";
import TradeHistory from "./TradeHistory";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useDarkMode } from "contexts/DarkModeContext";

function TradePositionContainer(props) {
  const { loading, positions, price, accountLedger } = props;
  const [transactionHistory, setTransactionHistory] = useState(false);
  const { isDarkMode } = useDarkMode();

  const viewHistory = () => {
    setTransactionHistory(!transactionHistory);
  };

  return (
    <div
      className={`${PositionContainerCSS["position-container"]} ${
        isDarkMode ? PositionContainerCSS.darkModeContainer : ""
      }`}
    >
      <div className={PositionContainerCSS["position-header"]}>
        <h6>{transactionHistory ? "Transactions" : "Position"}</h6>
        <span onClick={() => viewHistory()}>
          View {transactionHistory ? "Position" : "Transactions"}{" "}
          <ArrowRightIcon sx={{ margin: -0.2 }} />
        </span>
      </div>
      {transactionHistory ? (
        <TradeHistory loading={loading} accountLedger={accountLedger} />
      ) : (
        <TradePosition loading={loading} positions={positions} price={price} />
      )}
    </div>
  );
}
export default TradePositionContainer;
