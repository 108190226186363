import React, { useEffect, useState } from "react";

import { useDarkMode } from "contexts/DarkModeContext";

import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import AnalyticsPortfolioCSS from "./AnalyticsPortfolio.module.css";
import SummaryTable from "./SummaryTable";

function AnalyticsPortfolio(props) {
  const { isDarkMode } = useDarkMode();

  const [currentTable, setCurrentTable] = useState(Object.keys(props.data)[0]);
  const [sortOrder, setSortOrder] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [display, setDisplay] = useState(true);

  const histHeaders = ["Date", "Returns", "Cumulative Returns"];

  useEffect(() => {
    if (props.keys.length === 1 && props.keys[0] === "capm") {
      setDisplay(false);
    } else {
      setDisplay(true);
    }
  }, [props]);

  const handleSort = (column) => {
    if (column === "Date") {
      if (sortColumn === "Date") {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      } else {
        setSortColumn("Date");
        setSortOrder("asc");
      }
    } else {
      if (sortColumn === column) {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      } else {
        setSortColumn(column);
        setSortOrder("asc");
      }
    }
  };

  let sortedData = [];

  if (props && props.data && props.data[currentTable]) {
    sortedData = props.data[currentTable]["Historical Data"]["Date"]
      .map((date, index) => ({
        date,
        values: histHeaders.reduce((acc, key) => {
          acc[key] = props.data[currentTable]["Historical Data"][key][index];
          return acc;
        }, {}),
      }))
      .sort((a, b) => {
        if (sortColumn === "Date") {
          const aValue = new Date(a.date);
          const bValue = new Date(b.date);

          if (sortOrder === "asc") {
            return aValue - bValue;
          } else if (sortOrder === "desc") {
            return bValue - aValue;
          }
        } else {
          const aValue = a.values[sortColumn];
          const bValue = b.values[sortColumn];

          if (sortOrder === "asc") {
            return aValue - bValue;
          } else if (sortOrder === "desc") {
            return bValue - aValue;
          }
        }

        return 0;
      });
  }

  if (display) {
    return (
      <div className={AnalyticsPortfolioCSS["analytics-portfolio"]}>
        <div
          className={AnalyticsPortfolioCSS["analytics-stock-header-container"]}
        >
          {Object.keys(props.data).map((ticker) => (
            <button
              className={AnalyticsPortfolioCSS["analytics-stock-header"]}
              onClick={() => setCurrentTable(ticker)}
              key={ticker}
              style={{
                color:
                  currentTable === ticker
                    ? isDarkMode
                      ? "#387ADB"
                      : "#0B5DD7"
                    : "",
              }}
            >
              {ticker}
            </button>
          ))}
        </div>

        <div
          className={`${AnalyticsPortfolioCSS["table-container"]} ${
            isDarkMode ? AnalyticsPortfolioCSS.darkModeContainer : ""
          }`}
        >
          <div
            className={`${AnalyticsPortfolioCSS["table-container-hist"]} ${
              isDarkMode ? AnalyticsPortfolioCSS.darkModeContainer : ""
            }`}
          >
            <table>
              <thead>
                <tr>
                  {histHeaders.map((key) => (
                    <th key={key} onClick={() => handleSort(key)}>
                      <span
                        className={`${AnalyticsPortfolioCSS["report-header"]} ${
                          isDarkMode
                            ? AnalyticsPortfolioCSS.darkModeContainer
                            : ""
                        }`}
                      >
                        {key}
                        {sortColumn === key && sortOrder === "asc" && (
                          <ArrowDropUpIcon />
                        )}
                        {sortColumn === key && sortOrder === "desc" && (
                          <ArrowDropDownIcon />
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {sortedData.map((row) => (
                  <tr
                    key={row.date}
                    className={`${AnalyticsPortfolioCSS["report-table-row"]} ${
                      isDarkMode ? AnalyticsPortfolioCSS.darkModeContainer : ""
                    }`}
                  >
                    <td>{row.date}</td>
                    <td>{(row.values.Returns * 100).toFixed(2)}%</td>
                    <td>
                      {(row.values["Cumulative Returns"] * 100).toFixed(2)}%
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {props.data[currentTable] && (
            <div
              className={`${AnalyticsPortfolioCSS["table-container-summary"]} ${
                isDarkMode ? AnalyticsPortfolioCSS.darkModeContainer : ""
              }`}
            >
              <SummaryTable
                type={"summary"}
                data={props.data[currentTable].Summary}
              />
            </div>
          )}
          {props.data[currentTable] && (
            <div
              className={`${AnalyticsPortfolioCSS["table-container-summary"]} ${
                isDarkMode ? AnalyticsPortfolioCSS.darkModeContainer : ""
              }`}
            >
              <SummaryTable
                type={"weight"}
                data={props.data[currentTable]["Optimal Weights"]}
              />
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className={AnalyticsPortfolioCSS["analytics-portfolio"]}>
        <h1>Please select MPT to construct a portfolio</h1>
      </div>
    );
  }
}

export default AnalyticsPortfolio;
