import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import WatchlistCSS from "./Watchlist.module.css";
import { get, post } from "services/service";
import { marketHoursCheck } from "utils/marketHoursCheck";
import WatchlistLeft from "./WatchlistLeft";
import WatchlistRight from "./WatchlistRight";
import { useSubTab } from "contexts/SubTabContext";
import { useMobile } from "contexts/MobileContext";
import { useAppUse } from "contexts/AppUseContext";
import AlertMessage from "components/Shared/AlertMessage";

function Watchlist() {
  const { isMobile } = useMobile();
  const { isUsingApp } = useAppUse();
  const { subTab, setSubTab } = useSubTab();

  const [loading, setLoading] = useState({
    main: true,
    price: false,
    update: false,
    info: false,
  });
  // const [info, setInfo] = useState("");
  const [timespan, setTimespan] = useState("1d");
  const [historicalData, setHistoricalData] = useState([]);
  const [placeholderStats, setPlaceholderStats] = useState([]);
  // const [news, setNews] = useState([]);
  const [message, setMessage] = useState(null);
  const [tempWatchlist, setTempWatchlist] = useState({
    current: "watchlist",
    placeholder: null,
    watchlist: [],
    recent: [],
  });

  const [currentList, setCurrentList] = useState([]);

  const [placeholderInfo, setplaceholderInfo] = useState({
    beta: null,
    about: {},
    news: [],
  });

  const [allInfo, setAllInfo] = useState({});

  const [hasCrypto, setHasCrypto] = useState(false);

  const navigate = useNavigate();

  // Initial fetch
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    } else {
      try {
        const savedWatchlist = JSON.parse(
          localStorage.getItem("tempWatchlist")
        );
        if (
          savedWatchlist &&
          savedWatchlist.placeholder &&
          (savedWatchlist.watchlist.length > 0 ||
            savedWatchlist.recent.length > 0)
        ) {
          setTempWatchlist({
            current: savedWatchlist.current || "watchlist",
            placeholder: savedWatchlist.placeholder,
            watchlist: savedWatchlist.watchlist || [],
            recent: savedWatchlist.recent || [],
          });
          // setLoading((prevState) => ({
          //   ...prevState,
          //   main: false,
          // }));
        } else {
          fetchWatchlistRecent(true, null);
        }
      } catch (err) {
        console.log(err.messsage);
        throw err;
      }
    }
  }, []);

  // Set placeholderStats
  useEffect(() => {
    if (
      !loading.main &&
      tempWatchlist.placeholder &&
      (tempWatchlist.watchlist.length > 0 || tempWatchlist.recent.length > 0)
    ) {
      const foundPlaceholder =
        tempWatchlist.recent.find(
          (item) => item.symbol === tempWatchlist.placeholder
        ) ||
        tempWatchlist.watchlist.find(
          (item) => item.symbol === tempWatchlist.placeholder
        );
      if (foundPlaceholder) {
        setPlaceholderStats(foundPlaceholder);
      }
    }
  }, [loading.main, tempWatchlist]);

  useEffect(() => {
    if (
      tempWatchlist.current &&
      (tempWatchlist.watchlist.length > 0 || tempWatchlist.recent.length > 0)
    ) {
      if (tempWatchlist.current === "watchlist") {
        setCurrentList(tempWatchlist.watchlist || []);
      } else {
        setCurrentList(tempWatchlist.recent || []);
      }
      setLoading((prevState) => ({
        ...prevState,
        main: false,
      }));
    }
  }, [tempWatchlist]);

  // Click on watchlist item
  const watchlistClick = async (value) => {
    if (tempWatchlist.placeholder !== value) {
      setTempWatchlist((prevState) => ({
        ...prevState,
        placeholder: value.symbol,
      }));
    }
    setSubTab(false);
    return;
  };

  // Fetch watchlist and recent (also for interval fetch)
  let isFetching = false; // Ensure this variable is reset properly

  const fetchWatchlistRecent = async (placeholder, signal) => {
    if (isFetching) {
      return;
    }
    isFetching = true;

    try {
      setLoading((prevState) => ({
        ...prevState,
        price: true,
      }));
      const response = await get(`/data/watchlist-recent`, { signal });

      const watchlist = response.data.watchlist;
      const recent = response.data.recent;

      if (placeholder) {
        setTempWatchlist((prevState) => ({
          ...prevState,
          placeholder:
            watchlist.length > 0
              ? watchlist[watchlist.length - 1].symbol
              : recent[recent.length - 1].symbol,
          watchlist: watchlist,
          recent: recent,
        }));
      } else {
        setTempWatchlist((prevState) => ({
          ...prevState,
          // placeholder: placeholder,
          watchlist: watchlist,
          recent: recent,
        }));
      }
    } catch (err) {
      if (err.name === "AbortError") {
        console.log("Fetch aborted");
      } else {
        console.log(err.message);
      }
    } finally {
      isFetching = false;
      setLoading((prevState) => ({
        ...prevState,
        price: false,
      }));
    }
  };

  // Interval fetch
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    // console.log(tempWatchlist.placeholder);

    const fetchInterval = setInterval(() => {
      if (
        isUsingApp &&
        !loading.main &&
        !loading.update &&
        !loading.info &&
        ((placeholderStats && marketHoursCheck().isMarketOpen) ||
          (placeholderStats.quoteType &&
            placeholderStats.quoteType === "CRYPTOCURRENCY") ||
          currentList.some((item) => item.quoteType === "CRYPTOCURRENCY"))
      ) {
        // console.log("fetching");
        fetchWatchlistRecent(false, signal);
      }
    }, 10000);

    return () => {
      clearInterval(fetchInterval);
      // console.log("aborting");
      controller.abort(); // Abort the ongoing fetch when the component unmounts or dependencies change
    };
  }, [
    loading,
    placeholderStats,
    tempWatchlist.placeholder,
    currentList,
    isUsingApp,
  ]);

  // const controllerRef = React.useRef(null);

  // //test
  // useEffect(() => {
  //   const controller = new AbortController();
  //   controllerRef.current = controller;

  //   const fetchData = async () => {
  //     try {
  //       const response = await get(`/data/watchlist-recent`, {
  //         signal: controller.signal,
  //       });
  //       const data = await response.json();
  //       console.log(data);
  //       // setData(data);
  //     } catch (error) {
  //       if (error.name !== "AbortError") {
  //         console.error("Fetch error:", error);
  //       }
  //     }
  //   };

  //   const intervalId = setInterval(fetchData, 5000); // Fetch every 5 seconds

  //   return () => {
  //     clearInterval(intervalId);
  //     controllerRef.current.abort(); // Abort the fetch when the component unmounts
  //   };
  // }, [tempWatchlist.placeholder]);

  async function getHistory(ticker, timespan) {
    if (ticker) {
      try {
        const response = await get(`/data/${ticker}/${timespan}`);
        setHistoricalData(response.data);
      } catch (err) {
        console.log(err.message);
      }
    }
  }

  // Fetch historical data
  useEffect(() => {
    if (tempWatchlist.placeholder) {
      getHistory(tempWatchlist.placeholder, timespan);
    }
  }, [tempWatchlist.placeholder, timespan]);

  /// Update Local Storage ///
  useEffect(() => {
    if (!loading.main && tempWatchlist.placeholder) {
      localStorage.setItem("tempWatchlist", JSON.stringify(tempWatchlist));
    }
  }, [loading.main, tempWatchlist]);
  //////

  const updateTickerList = async (ticker, isWatchlist) => {
    if (!loading.update) {
      setLoading((prevState) => ({
        ...prevState,
        update: true,
      }));
      try {
        let response = await post("/data/update-ticker-list", {
          ticker: ticker,
          isWatchlist: isWatchlist,
        });

        if (response.data.message) {
          setMessage(response.data.message);
        } else if (response.data) {
          if (isWatchlist) {
            setTempWatchlist((prevstate) => ({
              ...prevstate,
              watchlist: response.data.watchlist || [],
              recent: response.data.recent || [],
            }));
          } else {
            setTempWatchlist((prevstate) => ({
              ...prevstate,
              watchlist: response.data.watchlist || [],
              recent: response.data.recent || [],
            }));
          }
        }
      } catch (err) {
        console.log(err.message);
      } finally {
        setLoading((prevState) => ({
          ...prevState,
          update: false,
        }));
      }
    }
  };

  async function verify(value) {
    setTempWatchlist((prevState) => ({
      ...prevState,
      placeholder: value.symbol,
    }));
    updateTickerList(value.symbol, false);
    // setLoading((prevState) => ({
    //   ...prevState,
    //   quotes: true,
    // }));
  }

  const currentClick = (value) => {
    setTempWatchlist((prevState) => ({
      ...prevState,
      current: value,
    }));
  };

  const handleOpenAlert = () => {
    setMessage(null);
  };

  const handleTimespanChange = (newTimespan) => {
    setTimespan(newTimespan);
  };

  const fetchPlaceholderInfo = async (ticker) => {
    if (ticker) {
      setLoading((prevState) => ({
        ...prevState,
        info: true,
      }));
      try {
        const response2 = await get(`/watchlist/info/${ticker}`);
        setAllInfo((prevState) => ({
          ...prevState,
          [ticker]: {
            beta: response2.data.beta,
            about: response2.data.about,
            news: response2.data.news,
          },
        }));
        // setplaceholderInfo({
        //   beta: response2.data.beta,
        //   about: response2.data.about,
        //   news: response2.data.news,
        // });
      } catch (err) {
        console.log(err.message);
      } finally {
        setLoading((prevState) => ({
          ...prevState,
          info: false,
        }));
      }
    }
  };

  // Set all other info
  useEffect(() => {
    if (tempWatchlist.placeholder) {
      // check if allInfo has key called tempWatchlist.placeholder, if it doesn't fetch it
      const hasKey = Object.hasOwn(allInfo, tempWatchlist.placeholder);
      if (!hasKey) {
        fetchPlaceholderInfo(tempWatchlist.placeholder);
      } else {
        setplaceholderInfo(allInfo[tempWatchlist.placeholder]);
      }
    }
  }, [tempWatchlist.placeholder, allInfo]);

  return (
    <div className={WatchlistCSS.watchlist}>
      {!isMobile && (
        <WatchlistLeft
          verify={verify}
          tempWatchlist={tempWatchlist}
          updateTickerList={updateTickerList}
          currentClick={currentClick}
          watchlistClick={watchlistClick}
          loading={loading}
          placeholderStats={placeholderStats}
          currentList={currentList}
        />
      )}
      {!isMobile && tempWatchlist.placeholder && !loading.main && (
        <WatchlistRight
          placeholderStats={placeholderStats}
          historicalData={historicalData}
          timespan={timespan}
          handleTimespanChange={handleTimespanChange}
          placeholderInfo={placeholderInfo}
          loading={loading}
        />
      )}
      {isMobile && subTab && (
        <WatchlistLeft
          verify={verify}
          tempWatchlist={tempWatchlist}
          updateTickerList={updateTickerList}
          currentClick={currentClick}
          watchlistClick={watchlistClick}
          loading={loading}
          placeholderStats={placeholderStats}
          currentList={currentList}
        />
      )}
      {isMobile && !subTab && tempWatchlist.placeholder && !loading.main && (
        <WatchlistRight
          placeholderStats={placeholderStats}
          historicalData={historicalData}
          timespan={timespan}
          handleTimespanChange={handleTimespanChange}
          placeholderInfo={placeholderInfo}
          loading={loading}
        />
      )}
      {message && <div className="faded-background"></div>}
      {message && (
        <AlertMessage message={message} handleOpenAlert={handleOpenAlert} />
      )}
    </div>
  );
}

export default Watchlist;
