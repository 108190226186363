import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getPortfolio, getHistory } from "services/service";

import { useMobile } from "contexts/MobileContext";
import { useSubTab } from "contexts/SubTabContext";

import AnalyticsCSS from "./Analytics.module.css";
import AnalyticsLeft from "./AnalyticsLeft";
import AnalyticsRight from "./AnalyticsRight";

import ErrorBoundary from "components/__tests__/ErrorBoundary";

function Analytics(props) {
  const { isMobile } = useMobile();
  const { subTab, setSubTab } = useSubTab();

  const [tickers, setTickers] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [frequency, setFrequency] = useState("1d");
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [current, setCurrent] = useState("general");
  const [showFullText, setShowFullText] = useState(false);
  const [period, setPeriod] = useState("");
  const [general, setGeneral] = useState();
  const [currentReportParams, setCurrentReportParams] = useState({});
  const [shorting, setShorting] = useState(false);
  const [model, setModel] = useState({
    mpt: true,
    capm: true,
    ff3: false,
    ff5: false,
    custom: false,
  });
  const [method, setMethod] = useState("slsqp");
  const [benchmark, setBenchmark] = useState("^GSPC");
  const [rf, setRf] = useState("^IRX");
  const [error, setError] = useState(false);
  const [aversion, setAversion] = useState("");
  const [periodOptions, setPeriodOptions] = useState([]);
  const [portfolio, setPortfolio] = useState();
  const [currentPortfolioParams, setCurrentPortfolioParams] = useState({});
  const [initialValuesSet, setInitialValuesSet] = useState(false);
  const [customWeights, setCustomWeights] = useState(null);
  const [weightCheck, setWeightCheck] = useState(false);
  const [clickedSubmit, setClickedSubmit] = useState(false);
  const [errorValue, setErrorValue] = useState(false);
  const [frequencyOptions, setFrequencyOptions] = useState([]);

  useEffect(() => {
    if (current === "portfolio") {
      setPeriodOptions(["3mo", "6mo", "1y", "5y", "custom"]);
      setFrequencyOptions(["1d", "1wk", "1mo"]);
    } else {
      setPeriodOptions(["1d", "5d", "3mo", "6mo", "ytd", "1y", "5y", "custom"]);
      setFrequencyOptions(["1d", "1wk", "1mo"]);
    }
    setPeriod(current === "portfolio" ? "3mo" : "1d");
  }, [current]);

  useEffect(() => {
    if (period === "3mo" || period === "6mo") {
      setFrequencyOptions(["1d", "1wk"]);
      if (frequency === "1mo") {
        setFrequency("1d");
      }
    } else {
      setFrequencyOptions(["1d", "1wk", "1mo"]);
    }
  }, [period]);

  const handleModelChange = (event) => {
    const { name, checked } = event.target;
    // if (name === "ff5" && model.ff3) {
    //   setModel((prevState) => ({
    //     ...prevState,
    //     ff3: false,
    //     ff5: checked,
    //   }));
    // } else if (name === "ff3" && model.ff5) {
    //   setModel((prevState) => ({
    //     ...prevState,
    //     ff5: false,
    //     ff3: checked,
    //   }));
    // } else {
    //   setModel((prevState) => ({ ...prevState, [name]: checked }));
    // }
    setModel((prevState) => ({ ...prevState, [name]: checked }));
  };

  const isAtLeastOneSelected = Object.values(model).some((value) => value);

  useEffect(() => {
    const hasSelection = isAtLeastOneSelected;
    setError(!hasSelection);
  }, [model]);

  const navigate = useNavigate();

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    } else {
      if (localStorage.getItem("analytics")) {
        let savedQuery = JSON.parse(localStorage.getItem("analytics"));
        if (savedQuery.current === "portfolio") {
          setCurrent(savedQuery.current);
          setShowFullText(savedQuery.showFullText);
          setTickers(savedQuery.tickers);
          setPeriod(savedQuery.period);
          setStartDate(savedQuery.startDate);
          setEndDate(savedQuery.endDate);
          setFrequency(savedQuery.frequency);
          setBenchmark(savedQuery.benchmark);
          setRf(savedQuery.rf);
          setModel(savedQuery.model);
          setShorting(savedQuery.shorting);
          setMethod(savedQuery.method);
          setAversion(savedQuery.aversion);
          setMethod(savedQuery.method);
          setCustomWeights(savedQuery.customWeights);
          setWeightCheck(savedQuery.weightCheck);
          setInitialValuesSet(true); // Set the flag to indicate initial values are set
        } else if (savedQuery.current === "general") {
          setCurrent(savedQuery.current);
          setTickers(savedQuery.tickers);
          setPeriod(savedQuery.period);
          setStartDate(savedQuery.startDate);
          setEndDate(savedQuery.endDate);
          setFrequency(savedQuery.frequency);
          setInitialValuesSet(true); // Set the flag to indicate initial values are set
        }
      }
    }
  }, []);

  useEffect(() => {
    if (initialValuesSet) {
      fetchDataWrapper();
    }
  }, [initialValuesSet]);

  const fetchDataWrapper = () => {
    const mockEvent = { preventDefault: () => {} }; // Create a mock event object
    fetchData(mockEvent);
  };

  const aversionCheck = (num) => {
    if (!num) {
      return false;
    } else {
      return num;
    }
  };

  const TIMEOUT = 30000;

  async function fetchData(e) {
    const controller = new AbortController();
    const signal = controller.signal;

    e.preventDefault();
    setLoading(true);
    let string = tickers.join(" ");

    const timeoutPromise = new Promise((resolve) => {
      setTimeout(() => {
        controller.abort();
        resolve({ timedOut: true });
      }, TIMEOUT);
    });
    if (
      tickers.length &&
      (period === "custom" ? startDate && endDate : period) &&
      frequency
    ) {
      if (current === "general") {
        try {
          let historyRequestPromise;
          historyRequestPromise = getHistory(
            string,
            period,
            startDate,
            endDate,
            frequency,
            { signal }
          );

          const response = await Promise.race([
            historyRequestPromise,
            timeoutPromise,
          ]);
          if (response.timedOut) {
            setLoading(false);
            return;
          }
          // try {
          //   const response = await getHistory(
          //     string,
          //     period,
          //     startDate,
          //     endDate,
          //     frequency,
          //     { signal }
          //   );
          if (tickers.length === 1) {
            setGeneral({ [tickers[0]]: response.data });
            setCurrentReportParams({
              tickers: string,
              period: period,
              startDate: startDate,
              endDate: endDate,
              frequency: frequency,
            });
            localStorage.setItem(
              "analytics",
              JSON.stringify({
                current: current,
                tickers: tickers,
                period: period,
                startDate: startDate,
                endDate: endDate,
                frequency: frequency,
              })
            );
          } else {
            setGeneral(response.data);
            setCurrentReportParams({
              tickers: string,
              period: period,
              startDate: startDate,
              endDate: endDate,
              frequency: frequency,
            });
            localStorage.setItem(
              "analytics",
              JSON.stringify({
                current: current,
                tickers: tickers,
                period: period,
                startDate: startDate,
                endDate: endDate,
                frequency: frequency,
              })
            );
          }
          resetErrorState();
        } catch (err) {
          setLoading(false);
          return;
        }
      } else if (current === "portfolio") {
        try {
          const hasTrueValue = Object.values(model).some(
            (value) => value === true
          );
          if (hasTrueValue) {
            if (model.custom && !weightCheck) {
              setLoading(false);
              handleAlert();
              return;
            } else {
              const jsonString = JSON.stringify(model);
              // const response = await getPortfolio(
              //   string,
              //   period,
              //   startDate,
              //   endDate,
              //   frequency,
              //   benchmark,
              //   rf,
              //   jsonString,
              //   shorting,
              //   method,
              //   aversionCheck(aversion),
              //   customWeights
              // );
              let portfolioRequestPromise;
              portfolioRequestPromise = getPortfolio(
                string,
                period,
                startDate,
                endDate,
                frequency,
                benchmark,
                rf,
                jsonString,
                shorting,
                method,
                aversionCheck(aversion),
                customWeights,
                { signal } // Pass the signal to getPortfolio
              );

              const response = await Promise.race([
                portfolioRequestPromise,
                timeoutPromise,
              ]);
              if (response.timedOut) {
                setLoading(false);
                return;
              }
              if (response.status === 200) {
                setPortfolio(response.data);
                setCurrentPortfolioParams({
                  tickers: tickers,
                  period: period,
                  startDate: startDate,
                  endDate: endDate,
                  frequency: frequency,
                  benchmark: benchmark,
                  rf: rf,
                  model: model,
                  shorting: shorting,
                  method: method,
                  aversion: aversionCheck(aversion),
                  customWeights: customWeights,
                  weightCheck: weightCheck,
                });
                localStorage.setItem(
                  "analytics",
                  JSON.stringify({
                    current: current,
                    showFullText: showFullText,
                    tickers: tickers,
                    period: period,
                    startDate: startDate,
                    endDate: endDate,
                    frequency: frequency,
                    benchmark: benchmark,
                    rf: rf,
                    model: model,
                    shorting: shorting,
                    method: method,
                    aversion: aversionCheck(aversion),
                    customWeights: customWeights,
                    weightCheck: weightCheck,
                  })
                );
              } else {
                console.log("Unexpected error");
              }
            }
          } else {
            setLoading(false);
            handleAlert();
            return;
          }
          resetErrorState();
        } catch (err) {
          setLoading(false);
          return;
        }
      }
    } else {
      setLoading(false);
      handleAlert();
      return;
    }
    setLoading(false);
    if (clickedSubmit) {
      setSubTab(!subTab);
      setClickedSubmit(false);
    }
  }

  const handleStartChange = (newValue) => {
    setStartDate(convert(newValue));
  };

  const handleEndChange = (newValue) => {
    setEndDate(convert(newValue));
  };

  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  const handleFrequencyChange = (newValue) => {
    setFrequency(newValue);
  };

  const handlePeriodChange = (newValue) => {
    setPeriod(newValue);
  };

  const handleAlert = () => {
    if (alert === false) {
      setAlert(true);

      setTimeout(() => {
        setAlert(false);
      }, 5000);
    }
  };

  const handleChipChange = (value) => {
    if (tickers.includes(value)) {
      return;
    } else {
      const newList = [...tickers, value];
      if (newList.length <= 20) {
        setTickers(newList);
      } else {
        return;
      }
      // setTickers([...tickers, value]);
    }
  };

  const verify = async (value) => {
    handleChipChange(value.symbol);
    // try {
    // const response = await get(`/data/watchlist/${value}`);
    // const response = await get(`/data/keyStats/${value}`);
    // const response = await get(`/search/validate/${value}`);
    // if (response.data.quoteResponse.result.length !== 0) {
    // if (response.data.chart.result.length !== 0) {
    //   return true;
    // } else {
    //   return false;
    // }
    // } catch (err) {
    //   return false;
    // }
  };

  const handleDelete = (index) => {
    setTickers((prevTickers) => {
      const newTickers = [...prevTickers];
      newTickers.splice(index, 1);
      return newTickers;
    });
  };

  const currentClick = (value) => {
    setCurrent(value);
  };

  const handleWeightChange = (weights) => {
    const weightArray = weights
      .replace(/\s/g, "") // Remove spaces from the weights string
      .split(",")
      .map(Number);

    const sum = weightArray.reduce((a, b) => a + b, 0);

    if (sum === 1 && weightArray.length === tickers.length) {
      setCustomWeights(weights.replace(/\s/g, "")); // Remove spaces in the customWeights state
      setWeightCheck(true);
    } else {
      setCustomWeights(weights.replace(/\s/g, "")); // Remove spaces in the customWeights state
      setWeightCheck(false);
    }
  };

  const clearTickers = () => {
    setTickers([]);
  };

  const handleShowFullText = (value) => {
    setShowFullText(value);
  };

  const handleBenchmark = (value) => {
    setBenchmark(value);
  };

  const handleRf = (value) => {
    setRf(value);
  };

  const handleShorting = (value) => {
    setShorting(value);
  };

  const handleMethod = (value) => {
    setMethod(value);
  };

  const handleAversion = (value) => {
    setAversion(value);
  };

  const resetErrorState = () => {
    setErrorValue(false);
  };

  const errorval = (val) => {
    setErrorValue(val);
  };

  const handleSubmitClick = () => {
    setClickedSubmit(true);
  };

  return (
    <div className={AnalyticsCSS.analytics}>
      {!isMobile && (
        <AnalyticsLeft
          fetchData={fetchData}
          currentClick={currentClick}
          current={current}
          verify={verify}
          clearTickers={clearTickers}
          tickers={tickers}
          handleDelete={handleDelete}
          period={period}
          handlePeriodChange={handlePeriodChange}
          periodOptions={periodOptions}
          frequency={frequency}
          frequencyOptions={frequencyOptions}
          handleFrequencyChange={handleFrequencyChange}
          handleShowFullText={handleShowFullText}
          showFullText={showFullText}
          startDate={startDate}
          handleStartChange={handleStartChange}
          endDate={endDate}
          handleEndChange={handleEndChange}
          benchmark={benchmark}
          handleBenchmark={handleBenchmark}
          rf={rf}
          handleRf={handleRf}
          error={error}
          model={model}
          handleModelChange={handleModelChange}
          shorting={shorting}
          handleShorting={handleShorting}
          method={method}
          handleMethod={handleMethod}
          aversion={aversion}
          handleAversion={handleAversion}
          weightCheck={weightCheck}
          customWeights={customWeights}
          handleWeightChange={handleWeightChange}
          loading={loading}
          alert={alert}
          handleSubmitClick={handleSubmitClick}
          general={general}
          portfolio={portfolio}
        />
      )}
      {!isMobile && (
        <ErrorBoundary
          current={current}
          errorValue={errorValue}
          errorval={errorval}
        >
          <AnalyticsRight
            general={general}
            current={current}
            currentReportParams={currentReportParams}
            portfolio={portfolio}
            currentPortfolioParams={currentPortfolioParams}
          />
        </ErrorBoundary>
      )}
      {isMobile && subTab && (
        <AnalyticsLeft
          fetchData={fetchData}
          currentClick={currentClick}
          current={current}
          verify={verify}
          clearTickers={clearTickers}
          tickers={tickers}
          handleDelete={handleDelete}
          period={period}
          handlePeriodChange={handlePeriodChange}
          periodOptions={periodOptions}
          frequency={frequency}
          frequencyOptions={frequencyOptions}
          handleFrequencyChange={handleFrequencyChange}
          handleShowFullText={handleShowFullText}
          showFullText={showFullText}
          startDate={startDate}
          handleStartChange={handleStartChange}
          endDate={endDate}
          handleEndChange={handleEndChange}
          benchmark={benchmark}
          handleBenchmark={handleBenchmark}
          rf={rf}
          handleRf={handleRf}
          error={error}
          model={model}
          handleModelChange={handleModelChange}
          shorting={shorting}
          handleShorting={handleShorting}
          method={method}
          handleMethod={handleMethod}
          aversion={aversion}
          handleAversion={handleAversion}
          weightCheck={weightCheck}
          customWeights={customWeights}
          handleWeightChange={handleWeightChange}
          loading={loading}
          alert={alert}
          isMobile={isMobile}
          handleSubmitClick={handleSubmitClick}
          general={general}
          portfolio={portfolio}
        />
      )}
      {isMobile && !subTab && (
        <ErrorBoundary
          current={current}
          errorValue={errorValue}
          errorval={errorval}
        >
          <AnalyticsRight
            general={general}
            current={current}
            currentReportParams={currentReportParams}
            portfolio={portfolio}
            currentPortfolioParams={currentPortfolioParams}
          />
        </ErrorBoundary>
      )}
    </div>
  );
}

export default Analytics;
