import React, { useEffect, useState } from "react";

import { useDarkMode } from "contexts/DarkModeContext";

import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import AnalyticsComponentsCSS from "./AnalyticsComponents.module.css";
import SummaryTable from "./SummaryTable";

function AnalyticsComponents(props) {
  const { isDarkMode } = useDarkMode();

  const [currentTable, setCurrentTable] = useState();
  const [sortOrder, setSortOrder] = useState("");
  const [sortColumn, setSortColumn] = useState("");

  useEffect(() => {
    setCurrentTable(Object.keys(props.data)[0]);
  }, [props.data]);

  const histHeaders = ["Date", "Adj Close", "Returns", "Cumulative Returns"];

  const handleSort = (column) => {
    if (column === "Date") {
      if (sortColumn === "Date") {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      } else {
        setSortColumn("Date");
        setSortOrder("asc");
      }
    } else {
      if (sortColumn === column) {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      } else {
        setSortColumn(column);
        setSortOrder("asc");
      }
    }
  };

  let sortedData = [];

  if (props && props.data && props.data[currentTable]) {
    sortedData = props.data[currentTable]["Historical Data"]["Date"]
      .map((date, index) => ({
        date,
        values: histHeaders.reduce((acc, key) => {
          acc[key] = props.data[currentTable]["Historical Data"][key][index];
          return acc;
        }, {}),
      }))
      .sort((a, b) => {
        if (sortColumn === "Date") {
          const aValue = new Date(a.date);
          const bValue = new Date(b.date);

          if (sortOrder === "asc") {
            return aValue - bValue;
          } else if (sortOrder === "desc") {
            return bValue - aValue;
          }
        } else {
          const aValue = a.values[sortColumn];
          const bValue = b.values[sortColumn];

          if (sortOrder === "asc") {
            return aValue - bValue;
          } else if (sortOrder === "desc") {
            return bValue - aValue;
          }
        }

        return 0;
      });
  }

  return (
    props.data[currentTable] && (
      <div className={AnalyticsComponentsCSS["analytics-components"]}>
        <div
          className={AnalyticsComponentsCSS["analytics-stock-header-container"]}
        >
          {Object.keys(props.data).map((ticker) => (
            <button
              className={AnalyticsComponentsCSS["analytics-stock-header"]}
              onClick={() => setCurrentTable(ticker)}
              key={ticker}
              style={{
                color:
                  currentTable === ticker
                    ? isDarkMode
                      ? "#387ADB"
                      : "#0B5DD7"
                    : "",
              }}
            >
              {ticker}
            </button>
          ))}
        </div>
        <div
          className={`${AnalyticsComponentsCSS["table-container"]} ${
            isDarkMode ? AnalyticsComponentsCSS.darkModeContainer : ""
          }`}
        >
          <table
            className={`${AnalyticsComponentsCSS["table-container-hist"]} ${
              isDarkMode ? AnalyticsComponentsCSS.darkModeContainer : ""
            }`}
          >
            <thead>
              <tr>
                {histHeaders.map((key) => (
                  <th key={key} onClick={() => handleSort(key)}>
                    <span
                      className={`${AnalyticsComponentsCSS["report-header"]} ${
                        isDarkMode
                          ? AnalyticsComponentsCSS.darkModeContainer
                          : ""
                      }`}
                    >
                      {key}
                      <div
                        className={AnalyticsComponentsCSS["arrow-container"]}
                      >
                        {sortColumn === key && sortOrder === "asc" && (
                          <ArrowDropUpIcon />
                        )}
                        {sortColumn === key && sortOrder === "desc" && (
                          <ArrowDropDownIcon />
                        )}
                      </div>
                    </span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {sortedData.map((row) => (
                <tr
                  key={row.date}
                  className={`${AnalyticsComponentsCSS["report-table-row"]} ${
                    isDarkMode ? AnalyticsComponentsCSS.darkModeContainer : ""
                  }`}
                >
                  {histHeaders.map((key) => {
                    if (["Adj Close"].includes(key)) {
                      return <td key={key}>{row.values[key].toFixed(2)}</td>;
                    } else if (
                      ["Returns", "Cumulative Returns"].includes(key)
                    ) {
                      return (
                        <td key={key}>{(row.values[key] * 100).toFixed(2)}%</td>
                      );
                    } else {
                      return <td key={key}>{row.values[key]}</td>;
                    }
                  })}
                </tr>
              ))}
            </tbody>
          </table>
          {props.data[currentTable].Summary && (
            <div
              className={`${
                AnalyticsComponentsCSS["table-container-summary"]
              } ${isDarkMode ? AnalyticsComponentsCSS.darkModeContainer : ""}`}
            >
              <SummaryTable
                type={"summary"}
                data={props.data[currentTable].Summary}
              />
            </div>
          )}
        </div>
      </div>
    )
  );
}

export default AnalyticsComponents;
