import React, { useState } from "react";
import FooterCSS from "./Footer.module.css";

function Footer() {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  return (
    <footer>
      <small>&copy;{currentYear}</small>
    </footer>
  );
}

export default Footer;
