import { isYearAndMonthViews } from "@mui/x-date-pickers/DatePicker/shared";

export const marketHoursCheck = (time) => {
  let currentTime;
  if (time) {
    currentTime = new Date(time * 1000);
  } else {
    currentTime = new Date();
  }
  const currentYear = currentTime.getFullYear();
  const currentMonth = currentTime.getMonth() + 1; // Months are 0-based, so add 1
  const currentDayOfMonth = currentTime.getDate();
  const currentHour = currentTime.toLocaleTimeString("en-US", {
    hour: "numeric",
    hour12: false,
    timeZone: "America/New_York",
  });
  const currentHourNumeric = parseInt(currentHour, 10);
  const currentMinutes = currentTime.getMinutes(); // Added to check the minutes
  const currentDay = currentTime.getDay();

  let isMarketOpen = false;
  let remainingTime = {};

  // Define the market hours for the US stock exchange in New York time zone
  const marketHours = {
    startHour: 9, // Regular market opening hour
    startMinutes: 30, //Market opening minutes
    endHour: 16, // Regular market closing hour
    endMinutes: 0,
    daysOfWeek: [1, 2, 3, 4, 5], // Monday to Friday (0: Sunday, 6: Saturday)
  };

  // Determine the market holidays based on the current year
  let marketHolidays = [];

  if (currentYear === 2024) {
    marketHolidays = [
      // Define holidays for 2024
      { date: new Date(currentYear, 0, 1), description: "New Year's Day" },
      {
        date: new Date(currentYear, 0, 15),
        description: "Martin Luther King, Jr. Day",
      },
      {
        date: new Date(currentYear, 1, 19),
        description: "Washington's Birthday",
      },
      { date: new Date(currentYear, 2, 29), description: "Good Friday" },
      { date: new Date(currentYear, 4, 27), description: "Memorial Day" },
      {
        date: new Date(currentYear, 5, 19),
        description: "Juneteenth National Independence Day",
      },
      { date: new Date(currentYear, 6, 4), description: "Independence Day" },
      { date: new Date(currentYear, 8, 2), description: "Labor Day" },
      {
        date: new Date(currentYear, 10, 28),
        description: "Thanksgiving Day",
      },
      { date: new Date(currentYear, 11, 25), description: "Christmas Day" },
    ];
  } else if (currentYear === 2025) {
    marketHolidays = [
      // Define holidays for 2025
      { date: new Date(currentYear, 0, 1), description: "New Year's Day" },
      {
        date: new Date(currentYear, 0, 20),
        description: "Martin Luther King, Jr. Day",
      },
      {
        date: new Date(currentYear, 1, 17),
        description: "Washington's Birthday",
      },
      { date: new Date(currentYear, 3, 18), description: "Good Friday" },
      { date: new Date(currentYear, 4, 25), description: "Memorial Day" },
      {
        date: new Date(currentYear, 5, 19),
        description: "Juneteenth National Independence Day",
      },
      { date: new Date(currentYear, 6, 4), description: "Independence Day" },
      { date: new Date(currentYear, 8, 1), description: "Labor Day" },
      {
        date: new Date(currentYear, 10, 27),
        description: "Thanksgiving Day",
      },
      { date: new Date(currentYear, 11, 25), description: "Christmas Day" },
    ];
  }

  // Check if the current day is a holiday
  const isHoliday = marketHolidays.some((holiday) => {
    return (
      holiday.date.getDate() === currentDayOfMonth &&
      holiday.date.getMonth() === currentMonth - 1 && // Months are 0-based
      holiday.date.getFullYear() === currentYear
    );
  });

  if (isHoliday) {
    // Set the holiday description when the market is closed due to a holiday
    const holiday = marketHolidays.find((holiday) => {
      return (
        holiday.date.getDate() === currentDayOfMonth &&
        holiday.date.getMonth() === currentMonth - 1 && // Months are 0-based
        holiday.date.getFullYear() === currentYear
      );
    });
    remainingTime = `Market is closed for ${holiday.description}`;
    return { isMarketOpen, remainingTime };
  }

  // Check if the current day and hour fall within the market hours
  const isMarketHours =
    marketHours.daysOfWeek.includes(currentDay) &&
    (currentHourNumeric > marketHours.startHour ||
      (currentHourNumeric === marketHours.startHour &&
        currentMinutes >= marketHours.startMinutes)) &&
    currentHourNumeric < marketHours.endHour &&
    !isHoliday; // Check if it's not a holiday

  if (!isMarketHours) {
    // Market is closed, calculate time until it opens again (including holiday handling)
    let nextOpenDay = marketHours.daysOfWeek.find((day) => day > currentDay);

    if (!nextOpenDay) {
      nextOpenDay = marketHours.daysOfWeek[0];
    }

    let daysUntilNextOpen = nextOpenDay - currentDay - 1;
    if (daysUntilNextOpen <= -1) {
      daysUntilNextOpen += 7; // Add the number of days in a week
    }

    let hoursUntilNextOpen = 0;
    let minutesUntilNextOpen = 0;

    if (currentHourNumeric < marketHours.startHour) {
      // Calculate time until market opens today
      hoursUntilNextOpen = marketHours.startHour - currentHourNumeric;
      minutesUntilNextOpen = marketHours.startMinutes - currentMinutes;
    } else {
      // Calculate time until market opens on the next open day
      hoursUntilNextOpen =
        (marketHours.startHour +
          24 * Math.abs(nextOpenDay - currentDay) -
          currentHourNumeric) %
        24;
      minutesUntilNextOpen = marketHours.startMinutes - currentMinutes;
    }

    // Adjust minutes if it's negative or 60
    if (minutesUntilNextOpen < 0) {
      minutesUntilNextOpen += 60;
      hoursUntilNextOpen--;
    } else if (minutesUntilNextOpen === 60) {
      minutesUntilNextOpen = 59;
      hoursUntilNextOpen++;
    }

    // if (hoursUntilNextOpen >= 24) {
    //   hoursUntilNextOpen = hoursUntilNextOpen % 24;
    // }
    let dayNumber = daysUntilNextOpen === 1 ? "day" : "days";
    let hourNumber = hoursUntilNextOpen === 1 ? "hour" : "hours";
    let minuteNumber = minutesUntilNextOpen === 1 ? "minute" : "minutes";

    if (daysUntilNextOpen > 0) {
      remainingTime = `${daysUntilNextOpen} ${dayNumber} ${hoursUntilNextOpen} ${hourNumber} until market open`;
      return { isMarketOpen, remainingTime };
    } else {
      remainingTime = `${hoursUntilNextOpen} ${hourNumber} ${minutesUntilNextOpen} ${minuteNumber} until market open`;
    }

    return { isMarketOpen, remainingTime };
  } else {
    isMarketOpen = true;
    // Market is open, calculate time until it closes
    let hoursUntilClose = marketHours.endHour - currentHourNumeric - 1;
    let minutesUntilClose = marketHours.endMinutes - currentMinutes;
    // Adjust minutes if it's negative or 60
    if (minutesUntilClose < 0) {
      minutesUntilClose += 60;
    } else if (
      minutesUntilClose === 60 ||
      (hoursUntilClose === 0 && minutesUntilClose === 60)
    ) {
      minutesUntilClose = 59;
    }

    let hourNumber = hoursUntilClose === 1 ? "hour" : "hours";
    let minuteNumber = minutesUntilClose === 1 ? "minute" : "minutes";

    remainingTime = `${hoursUntilClose} ${hourNumber} ${minutesUntilClose} ${minuteNumber} until market close`;

    return { isMarketOpen, remainingTime };
  }
};
