import React, { useEffect, useRef } from "react";
import AlertMessageCSS from "./AlertMessage.module.css";
import { useDarkMode } from "contexts/DarkModeContext";

function AlertMessage(props) {
  const { message, handleOpenAlert } = props;
  const { isDarkMode } = useDarkMode();

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          handleOpenAlert();
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div
      className={`${AlertMessageCSS["alert-message"]} ${
        isDarkMode ? AlertMessageCSS.darkModeContainer : ""
      }`}
      ref={wrapperRef}
    >
      <p>{message}</p>
      <button
        className={`${AlertMessageCSS["button-container"]} ${
          isDarkMode ? AlertMessageCSS.darkModeContainer : ""
        }`}
        onClick={handleOpenAlert}
      >
        Dismiss
      </button>
    </div>
  );
}

export default AlertMessage;
