import React, { useState, useEffect, useRef } from "react";

import { useDarkMode } from "contexts/DarkModeContext";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import CommunitiesInviteCSS from "./CommunitiesInvite.module.css";

function CommunitiesInvite(props) {
  const { isDarkMode } = useDarkMode();

  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipText, setTooltipText] = useState("Copy");

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          props.handleOpen(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const showTooltip = () => setTooltipVisible(true);
  const hideTooltip = () => setTooltipVisible(false);

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setTooltipText("Copied!");
      showTooltip();
      setTimeout(() => {
        hideTooltip();
        setTooltipText("Copy"); // Reset tooltip text to "Copy" after hiding
      }, 2000);
    } catch (error) {
      console.error("Error copying to clipboard:", error);
    }
  };

  const onMouseLeaveHandler = () => {
    hideTooltip();
    setTooltipText("Copy"); // Reset tooltip text to "Copy" when mouse leaves
  };

  return (
    <div
      className={`${CommunitiesInviteCSS["communities-invite"]} ${
        isDarkMode ? CommunitiesInviteCSS.darkModeContainer : ""
      }`}
      ref={wrapperRef}
    >
      <p className={CommunitiesInviteCSS["message-container"]}>
        Share the following access code to allow access to your community
      </p>

      <div className={CommunitiesInviteCSS["access-code"]}>
        <p>{props.currentCommunity.community._id}</p>
        <span className={CommunitiesInviteCSS["access-icon"]}>
          <ContentCopyIcon
            onClick={() =>
              copyToClipboard(props.currentCommunity.community._id)
            }
            className={CommunitiesInviteCSS["clipboard"]}
            onMouseEnter={showTooltip}
            onMouseLeave={onMouseLeaveHandler}
          />
          {isTooltipVisible && (
            <div className={CommunitiesInviteCSS.tooltip}>{tooltipText}</div>
          )}
        </span>
      </div>
    </div>
  );
}

export default CommunitiesInvite;
