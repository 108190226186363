import React, { useEffect, useState, useRef } from "react";
import SettingsCSS from "./Settings.module.css";
import { useDarkMode } from "contexts/DarkModeContext";
import { useColorPreference } from "contexts/ColorPreferenceContext";
import { post } from "services/service";
import DarkModeToggle from "components/ui/DarkModeToggle";
import ColorPreferenceToggle from "components/ui/ColorPreferenceToggle";
import { createCalculatePriceColor } from "utils/colorUtils";
import star from "assets/star.png";
import starselected from "assets/starselected.png";
import stardark from "assets/stardark.png";
import starselecteddark from "assets/starselecteddark.png";

function Settings(props) {
  const { isDarkMode } = useDarkMode();
  const { isAlternativeColor } = useColorPreference();
  const [defaultAccount, setDefaultAccount] = useState();

  const calculateColor = createCalculatePriceColor(
    isDarkMode,
    isAlternativeColor
  );
  const positiveColor = calculateColor(true);
  const negativeColor = calculateColor(false);

  const [portfolioNames, setPortfolioNames] = useState([]);

  const useOutsideAlerter = (ref) => {
    React.useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          props.handleOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const updatePreference = async (value) => {
    try {
      const requestData = {
        preferences: {
          defaultaccount: value,
        },
      };
      const response = await post("/users/update", requestData);
      if (response.status === 200) {
        // Successfully updated the preference
        let tempUser = JSON.parse(localStorage.getItem("tempUser"));
        tempUser.preferences.defaultaccount = value;
        localStorage.setItem("tempUser", JSON.stringify(tempUser));
      } else {
        // Handle errors
        console.error("Failed to update account preference");
      }
    } catch (err) {
      console.error("Error updating preference:", err);
    }
  };

  const handleDefault = (account) => {
    if (account !== defaultAccount) {
      setDefaultAccount(account);
      updatePreference(account);
    }
  };

  useEffect(() => {
    let tempPortfolio = JSON.parse(
      localStorage.getItem("tempPortfolio")
    ).portfolios;
    if (tempPortfolio) {
      const portfolios = tempPortfolio.map((portfolio) => ({
        id: portfolio._id,
        name: portfolio.name,
      }));
      setPortfolioNames(portfolios);
      console.log(portfolios);
    }
    let defaultAccount = JSON.parse(localStorage.getItem("tempUser"))
      .preferences.defaultaccount;
    if (defaultAccount) {
      setDefaultAccount(defaultAccount);
    }
  }, []);

  return (
    <div
      className={`${SettingsCSS["settings"]} ${
        isDarkMode ? SettingsCSS.darkModeContainer : ""
      }`}
      ref={wrapperRef}
    >
      <h4>Settings</h4>
      <div className={SettingsCSS["settings-container"]}>
        <div className={SettingsCSS["settings-container-subelement"]}>
          <span className={SettingsCSS["settings-subheader"]}>
            Display
            <hr style={{ color: "black", margin: "2px 0px 0px 0px" }}></hr>
          </span>
          <span className={SettingsCSS["settings-container-option"]}>
            Dark Mode
            <DarkModeToggle />
          </span>
        </div>
        <div
          className={SettingsCSS["settings-container-subelement"]}
          id={SettingsCSS["fixed-height"]}
        >
          <span className={SettingsCSS["settings-subheader"]}>
            Accounts
            <hr style={{ color: "black", margin: "2px 0px 0px 0px" }}></hr>
          </span>
          {portfolioNames.length > 0 &&
            portfolioNames.map((portfolio) => {
              return (
                <span
                  key={portfolio.id}
                  className={SettingsCSS["settings-container-option"]}
                  id={SettingsCSS["settings-star"]}
                >
                  <span className={SettingsCSS["settings-text"]}>
                    <img
                      onClick={() => handleDefault(portfolio.id)}
                      src={
                        defaultAccount === portfolio.id
                          ? isDarkMode
                            ? starselecteddark
                            : starselected
                          : isDarkMode
                          ? stardark
                          : star
                      }
                      height="32.36px"
                      width="33.11px"
                      alt="star"
                      style={{ margin: "0" }}
                    />
                    {portfolio.name}
                  </span>
                  <span
                    className={`${SettingsCSS["default-text"]} ${
                      isDarkMode ? SettingsCSS.darkModeContainer : ""
                    }`}
                  >
                    {defaultAccount === portfolio.id ? "Default" : ""}
                  </span>
                </span>
              );
            })}
        </div>
        <div className={SettingsCSS["settings-container-subelement"]}>
          <span className={SettingsCSS["settings-subheader"]}>
            Color Preference
            <hr style={{ color: "black", margin: "2px 0px 0px 0px" }}></hr>
          </span>
          <div className={SettingsCSS["settings-container-option"]}>
            <div className={SettingsCSS["settings-container-content-left"]}>
              <span
                style={{
                  color: positiveColor,
                  marginBottom: "2px",
                }}
              >
                +1.00 (1.00%)
              </span>
              <span
                style={{
                  color: negativeColor,
                }}
              >
                -1.00 (-1.00%)
              </span>
            </div>
            <ColorPreferenceToggle />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
