import React from "react";
import TradeCSS from "./Trade.module.css";
import Chart from "components/Chart";
import TradePosition from "./TradePosition";
import TradeHistory from "./TradeHistory";
import PortfolioPositions from "../Portfolio/PortfolioPositions";
import TradePositionContainer from "./TradePositionContainer";

function TradeRight(props) {
  const {
    placeholderStats,
    currentAccount,
    // accountLedger,
    // accountPositions,
    tickerData,
    // timespan,
    handleTimespanChange,
    // historicalData,
    loading,
  } = props;

  return (
    <div className={TradeCSS["trade-right"]}>
      <Chart
        placeholderStats={placeholderStats}
        historicalData={tickerData.historicalData}
        timespan={tickerData.timespan}
        handleTimespanChange={handleTimespanChange}
      />
      <TradePositionContainer
        loading={loading.main}
        positions={currentAccount.position}
        price={placeholderStats.regularMarketPrice}
        accountLedger={currentAccount.filteredLedger}
      />
      {/* <TradePosition
        loading={loading.main}
        positions={currentAccount.position}
        price={placeholderStats.regularMarketPrice}
      />
      <TradeHistory
        accountLedger={currentAccount.filteredLedger}
        loading={loading.main}
      /> */}
    </div>
  );
}
export default TradeRight;
