import React from "react";
import { useNavigate } from "react-router-dom";

import { useDarkMode } from "contexts/DarkModeContext";
import { useSubTab } from "contexts/SubTabContext";
import { useMobile } from "contexts/MobileContext";

import watch from "assets/watch.png";
import watchdark from "assets/watchdark.png";
import stock from "assets/stock.png";
import stockdark from "assets/stockdark.png";
import analytics from "assets/analytics.png";
import analyticsdark from "assets/analyticsdark.png";
import trade from "assets/trade.png";
import tradedark from "assets/tradedark.png";
import communities from "assets/communities.png";
import communitiesdark from "assets/communitiesdark.png";
import contact from "assets/contact.png";
import contactdark from "assets/contactdark.png";

import SidebarCSS from "./Sidebar.module.css";

function Sidebar(props) {
  const { isMobile } = useMobile();
  const { isDarkMode } = useDarkMode();
  const { subTab, setSubTab } = useSubTab();

  const navigate = useNavigate();

  const handleSideClick = (tab) => {
    if (props.tab === tab) {
      setSubTab(true);
    } else {
      navigate(`/dashboard/${tab}`);
      setSubTab(true);
    }
  };

  const portfolioIcon = (
    <img
      onClick={(e) => handleSideClick(e.target.id)}
      src={isDarkMode ? stockdark : stock}
      height="42px"
      width="42px"
      alt="portfolio"
      className={
        props.tab === "portfolio"
          ? SidebarCSS["sidebar-button-clicked"]
          : SidebarCSS["sidebar-button"]
      }
      id="portfolio"
    />
  );

  const watchlistIcon = (
    <img
      onClick={(e) => handleSideClick(e.target.id)}
      src={isDarkMode ? watchdark : watch}
      height="42px"
      width="42px"
      alt="watchlist"
      className={
        props.tab === "watchlist"
          ? SidebarCSS["sidebar-button-clicked"]
          : SidebarCSS["sidebar-button"]
      }
      id="watchlist"
    />
  );

  const analyticsIcon = (
    <img
      onClick={(e) => handleSideClick(e.target.id)}
      src={isDarkMode ? analyticsdark : analytics}
      height="42px"
      width="42px"
      alt="analytics"
      className={
        props.tab === "analytics"
          ? SidebarCSS["sidebar-button-clicked"]
          : SidebarCSS["sidebar-button"]
      }
      id="analytics"
    />
  );

  const tradeIcon = (
    <img
      onClick={(e) => handleSideClick(e.target.id)}
      src={isDarkMode ? tradedark : trade}
      height="42px"
      width="42px"
      alt="trade"
      className={
        props.tab === "trade"
          ? SidebarCSS["sidebar-button-clicked"]
          : SidebarCSS["sidebar-button"]
      }
      id="trade"
    />
  );

  const communitiesIcon = (
    <img
      onClick={(e) => handleSideClick(e.target.id)}
      src={isDarkMode ? communitiesdark : communities}
      height="32px"
      width="32px"
      alt="communities"
      className={
        props.tab === "communities"
          ? SidebarCSS["sidebar-button-clicked"]
          : SidebarCSS["sidebar-button"]
      }
      style={{ padding: "5px" }}
      id="communities"
    />
  );

  const contactIcon = (
    <img
      onClick={(e) => props.open(true)}
      src={isDarkMode ? contactdark : contact}
      height="42px"
      width="42px"
      alt="contact"
      className={
        props.tab === "contact"
          ? SidebarCSS["sidebar-button-clicked"]
          : SidebarCSS["sidebar-button"]
      }
      id="contact"
    />
  );

  // const portfolioTab = (
  //   <div className={SidebarCSS["tab-container"]}>
  //     {props.tab === "portfolio" &&
  //       (subTab ? (
  //         <div>
  //           <img
  //             src={isDarkMode ? tabSelectedDark : tabSelected}
  //             height="12px"
  //             width="12px"
  //             alt="tab"
  //           />
  //           <img
  //             src={isDarkMode ? tabDark : tab}
  //             height="12px"
  //             width="12px"
  //             alt="tab"
  //           />
  //         </div>
  //       ) : (
  //         <div>
  //           <img
  //             src={isDarkMode ? tabDark : tab}
  //             height="12px"
  //             width="12px"
  //             alt="tab"
  //           />
  //           <img
  //             src={isDarkMode ? tabSelectedDark : tabSelected}
  //             height="12px"
  //             width="12px"
  //             alt="tab"
  //           />
  //         </div>
  //       ))}
  //   </div>
  // );
  // const watchlistTab = (
  //   <div className={SidebarCSS["tab-container"]}>
  //     {props.tab === "watchlist" &&
  //       (subTab ? (
  //         <div>
  //           <img
  //             src={isDarkMode ? tabSelectedDark : tabSelected}
  //             height="12px"
  //             width="12px"
  //             alt="tab"
  //           />
  //           <img
  //             src={isDarkMode ? tabDark : tab}
  //             height="12px"
  //             width="12px"
  //             alt="tab"
  //           />
  //         </div>
  //       ) : (
  //         <div>
  //           <img
  //             src={isDarkMode ? tabDark : tab}
  //             height="12px"
  //             width="12px"
  //             alt="tab"
  //           />
  //           <img
  //             src={isDarkMode ? tabSelectedDark : tabSelected}
  //             height="12px"
  //             width="12px"
  //             alt="tab"
  //           />
  //         </div>
  //       ))}
  //   </div>
  // );
  // const analyticsTab = (
  //   <div className={SidebarCSS["tab-container"]}>
  //     {props.tab === "analytics" &&
  //       (subTab ? (
  //         <div>
  //           <img
  //             src={isDarkMode ? tabSelectedDark : tabSelected}
  //             height="12px"
  //             width="12px"
  //             alt="tab"
  //           />
  //           <img
  //             src={isDarkMode ? tabDark : tab}
  //             height="12px"
  //             width="12px"
  //             alt="tab"
  //           />
  //         </div>
  //       ) : (
  //         <div>
  //           <img
  //             src={isDarkMode ? tabDark : tab}
  //             height="12px"
  //             width="12px"
  //             alt="tab"
  //           />
  //           <img
  //             src={isDarkMode ? tabSelectedDark : tabSelected}
  //             height="12px"
  //             width="12px"
  //             alt="tab"
  //           />
  //         </div>
  //       ))}
  //   </div>
  // );
  // const tradeTab = (
  //   <div className={SidebarCSS["tab-container"]}>
  //     {props.tab === "trade" &&
  //       (subTab ? (
  //         <div>
  //           <img
  //             src={isDarkMode ? tabSelectedDark : tabSelected}
  //             height="12px"
  //             width="12px"
  //             alt="tab"
  //           />
  //           <img
  //             src={isDarkMode ? tabDark : tab}
  //             height="12px"
  //             width="12px"
  //             alt="tab"
  //           />
  //         </div>
  //       ) : (
  //         <div>
  //           <img
  //             src={isDarkMode ? tabDark : tab}
  //             height="12px"
  //             width="12px"
  //             alt="tab"
  //           />
  //           <img
  //             src={isDarkMode ? tabSelectedDark : tabSelected}
  //             height="12px"
  //             width="12px"
  //             alt="tab"
  //           />
  //         </div>
  //       ))}
  //   </div>
  // );

  // const communitiesTab = (
  //   <div className={SidebarCSS["tab-container"]}>
  //     {props.tab === "communities" &&
  //       (subTab ? (
  //         <div>
  //           <img
  //             src={isDarkMode ? tabSelectedDark : tabSelected}
  //             height="12px"
  //             width="12px"
  //             alt="tab"
  //           />
  //           <img
  //             src={isDarkMode ? tabDark : tab}
  //             height="12px"
  //             width="12px"
  //             alt="tab"
  //           />
  //         </div>
  //       ) : (
  //         <div>
  //           <img
  //             src={isDarkMode ? tabDark : tab}
  //             height="12px"
  //             width="12px"
  //             alt="tab"
  //           />
  //           <img
  //             src={isDarkMode ? tabSelectedDark : tabSelected}
  //             height="12px"
  //             width="12px"
  //             alt="tab"
  //           />
  //         </div>
  //       ))}
  //   </div>
  // );

  return (
    <nav
      className={`${SidebarCSS["sidebar"]} ${
        isDarkMode ? SidebarCSS.darkModeContainer : ""
      }`}
    >
      <div className={SidebarCSS["sidebar-button-group"]}>
        <div className={SidebarCSS["sidebar-button-subgroup"]}>
          <span
            className={
              props.tab === "portfolio"
                ? `${SidebarCSS["button-adornment"]} ${
                    isDarkMode ? SidebarCSS.darkModeContainer : ""
                  }`
                : ""
            }
          ></span>
          <span>{portfolioIcon}</span>
        </div>
        <div className={SidebarCSS["sidebar-button-subgroup"]}>
          <span
            className={
              props.tab === "watchlist"
                ? `${SidebarCSS["button-adornment"]} ${
                    isDarkMode ? SidebarCSS.darkModeContainer : ""
                  }`
                : ""
            }
          ></span>
          <span>{watchlistIcon}</span>
        </div>
        <div className={SidebarCSS["sidebar-button-subgroup"]}>
          <span
            className={
              props.tab === "trade"
                ? `${SidebarCSS["button-adornment"]} ${
                    isDarkMode ? SidebarCSS.darkModeContainer : ""
                  }`
                : ""
            }
          ></span>{" "}
          <span>{tradeIcon}</span>
        </div>
        <div className={SidebarCSS["sidebar-button-subgroup"]}>
          <span
            className={
              props.tab === "analytics"
                ? `${SidebarCSS["button-adornment"]} ${
                    isDarkMode ? SidebarCSS.darkModeContainer : ""
                  }`
                : ""
            }
          ></span>{" "}
          <span>{analyticsIcon}</span>
        </div>
        <div className={SidebarCSS["sidebar-button-subgroup"]}>
          <span
            className={
              props.tab === "communities"
                ? `${SidebarCSS["button-adornment"]} ${
                    isDarkMode ? SidebarCSS.darkModeContainer : ""
                  }`
                : ""
            }
          ></span>{" "}
          <span>{communitiesIcon}</span>
        </div>

        {/* {!isMobile ? (
          portfolioIcon
        ) : subTab ? (
          <div className={SidebarCSS["sidebar-button-subgroup"]}>
            {portfolioIcon}
            {portfolioTab}
          </div>
        ) : (
          <div className={SidebarCSS["sidebar-button-subgroup"]}>
            {portfolioIcon}
            {portfolioTab}
          </div>
        )}
        {!isMobile ? (
          watchlistIcon
        ) : subTab ? (
          <div className={SidebarCSS["sidebar-button-subgroup"]}>
            {watchlistIcon}
            {watchlistTab}
          </div>
        ) : (
          <div className={SidebarCSS["sidebar-button-subgroup"]}>
            {watchlistIcon}
            {watchlistTab}
          </div>
        )}
        {!isMobile ? (
          tradeIcon
        ) : subTab ? (
          <div className={SidebarCSS["sidebar-button-subgroup"]}>
            {tradeIcon}
            {tradeTab}
          </div>
        ) : (
          <div className={SidebarCSS["sidebar-button-subgroup"]}>
            {tradeIcon}
            {tradeTab}
          </div>
        )}
        {!isMobile ? (
          analyticsIcon
        ) : subTab ? (
          <div className={SidebarCSS["sidebar-button-subgroup"]}>
            {analyticsIcon}
            {analyticsTab}
          </div>
        ) : (
          <div className={SidebarCSS["sidebar-button-subgroup"]}>
            {analyticsIcon}
            {analyticsTab}
          </div>
        )}

        {!isMobile ? (
          communitiesIcon
        ) : subTab ? (
          <div className={SidebarCSS["sidebar-button-subgroup"]}>
            {communitiesIcon}
            {communitiesTab}
          </div>
        ) : (
          <div className={SidebarCSS["sidebar-button-subgroup"]}>
            {communitiesIcon}
            {communitiesTab}
          </div>
        )} */}
      </div>
      {!isMobile && contactIcon}
    </nav>
  );
}

export default Sidebar;
