import React, { useEffect, useState } from "react";

import { useDarkMode } from "contexts/DarkModeContext";
import { formatNumber } from "utils/formatNumber";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import AnnouncementIcon from "@mui/icons-material/Announcement";

import PortfolioPositionsCSS from "components/Dashboard/Portfolio/PortfolioPositions.module.css";
import PortfolioHistoryCSS from "./PortfolioHistory.module.css";
import CommunitiesComment from "../Communities/CommunitiesComment";

function TradeHistory(props) {
  const { isDarkMode } = useDarkMode();
  const {
    loading,
    currentAccount,
    isUser,
    userStatus,
    pending,
    updatePortfolio,
    removeFromPending,
    updateLedgerComment,
  } = props;

  const { history: ledger } = currentAccount.ledger;
  const { main: loadingMain } = loading;

  const [openComment, setOpenComment] = useState(null);

  const getActionColor = (action) => {
    if (action === "Buy") {
      return isDarkMode ? "#87CD9D" : "#068B03";
    } else if (action === "Sell") {
      return isDarkMode ? "#C54242" : "#D20B0B";
    }
    return "";
  };

  const calculateHoursLeft = (unixTime) => {
    const currentTime = Math.floor(Date.now() / 1000); // Current time in Unix timestamp
    const hoursElapsed = (currentTime - unixTime) / 3600; // Convert seconds to hours
    return Math.max(0, Math.floor(72 - hoursElapsed)); // Calculate and round hours left, ensure non-negative result
  };

  const handleOpen = () => {
    setOpenComment(null);
  };

  return (
    <div
      className={`${PortfolioHistoryCSS["trade-history"]} ${
        isDarkMode ? PortfolioHistoryCSS.darkModeContainer : ""
      }`}
    >
      <div className={PortfolioHistoryCSS["trade-history-container"]}>
        <table
          className={`${PortfolioHistoryCSS["positions-table"]} ${
            isDarkMode ? PortfolioHistoryCSS.darkModeContainer : ""
          }`}
        >
          <thead>
            <tr>
              <th>Symbol</th>
              <th>Action</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Date</th>
              {!isUser && userStatus?.isMember && <th>Contributor</th>}
              {/* {!isUser && userStatus?.isMod && pending && <th>Review</th>} */}
              {!isUser && userStatus?.isMod && pending && <th>Approve</th>}
              {!isUser && userStatus?.isMember && pending && <th>Dismiss</th>}
              {!isUser && userStatus?.isMember && pending && <th>Comments</th>}
              {!isUser && userStatus?.isMember && pending && <th>Time Left</th>}
            </tr>
          </thead>
          <tbody>
            {ledger.length > 0 &&
              ledger
                .slice()
                .reverse()
                .map((item) => (
                  <tr key={item._id}>
                    <td className={PortfolioHistoryCSS["position-name"]}>
                      <span className={PortfolioHistoryCSS["ticker"]}>
                        {item.ticker}
                      </span>
                      <span className={PortfolioHistoryCSS["shortname"]}>
                        {item.name}
                      </span>
                    </td>
                    <td style={{ color: getActionColor(item.action) }}>
                      {item.action}
                    </td>
                    <td>{formatNumber(item.amount)}</td>
                    <td>{formatNumber(item.price)}</td>
                    <td>
                      <div className={PortfolioHistoryCSS["date"]}>
                        {new Date(item.date * 1000).toLocaleDateString()}
                      </div>
                      <div className={PortfolioHistoryCSS["shortname"]}>
                        {new Date(item.date * 1000).toLocaleTimeString()}
                      </div>
                    </td>
                    {!isUser && userStatus?.isMember && (
                      <td>
                        {item.contributor.firstname} {item.contributor.lastname}
                      </td>
                    )}
                    {!isUser && userStatus?.isMod && pending && (
                      <td>
                        <CheckIcon
                          sx={{
                            color: isDarkMode ? "#387ADB" : "#0B5DD7",
                            "&:hover": {
                              cursor: "pointer",
                              transform: "scale(1.2)",
                            },
                          }}
                          onClick={() =>
                            updatePortfolio({
                              ...item,
                              communityId: currentAccount.community._id,
                              portfolioId: currentAccount.portfolio._id,
                              ledgerVersion: currentAccount.ledger.__v,
                            })
                          }
                        />
                      </td>
                    )}
                    {!isUser && userStatus?.isMember && pending && (
                      <td>
                        <CloseIcon
                          sx={{
                            color: isDarkMode ? "#C54242" : "#F42727",
                            "&:hover": {
                              cursor: "pointer",
                              transform: "scale(1.2)",
                            },
                          }}
                          onClick={() => removeFromPending(item)}
                        />
                      </td>
                    )}
                    {!isUser && userStatus?.isMember && pending && (
                      <td>
                        <ChatBubbleIcon
                          sx={{
                            color: "#757D87",
                            "&:hover": {
                              cursor: "pointer",
                              color: isDarkMode ? "#387ADB" : "#0B5DD7",
                            },
                          }}
                          onClick={() => setOpenComment(item)}
                        />

                        {/* <AnnouncementIcon
                            sx={{
                              color: "#757D87",
                              "&:hover": {
                                cursor: "pointer",
                                color: isDarkMode ? "#387ADB" : "#0B5DD7",
                              },
                            }}
                          /> */}
                      </td>
                    )}

                    {!isUser && userStatus?.isMember && pending && (
                      <td>
                        <p
                          className={`${PortfolioHistoryCSS["action-text"]} ${
                            calculateHoursLeft(item.date) <= 24
                              ? isDarkMode
                                ? PortfolioHistoryCSS["time-red-dark"]
                                : PortfolioHistoryCSS["time-red-light"]
                              : null
                          }`}
                        >
                          {calculateHoursLeft(item.date)}h
                        </p>
                      </td>
                    )}
                  </tr>
                ))}
          </tbody>
        </table>
        {!loadingMain && !ledger.length && (
          <p className={PortfolioPositionsCSS["placeholder-text"]}>
            There are no orders
          </p>
        )}
      </div>
      {openComment && !isUser ? <div className="faded-background"></div> : ""}
      {openComment && !isUser && (
        <CommunitiesComment
          handleOpen={handleOpen}
          openComment={openComment}
          currentAccount={currentAccount}
          updateLedgerComment={updateLedgerComment}
          userStatus={userStatus}
        />
      )}
    </div>
  );
}

export default TradeHistory;
