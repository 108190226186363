import React, { useEffect, useRef } from "react";

import { useDarkMode } from "contexts/DarkModeContext";

import CommunitiesAlertMessageCSS from "./CommunitiesAlertMessage.module.css";

function CommunitiesAlertMessage(props) {
  const { isDarkMode } = useDarkMode();

  const { message, handleOpenAlert, postId, isReply, replyId } = props;

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          props.handleOpenAlert();
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const handleDecision = (decision) => {
    // You can perform further actions based on the user's decision
    if (decision && isReply) {
      props.handleReplyEvent(postId, replyId, decision);
    } else if (decision) {
      props.handleEvent(postId, decision);
    }
    handleOpenAlert();
  };

  return (
    <div
      className={`${CommunitiesAlertMessageCSS["communities-alert-message"]} ${
        isDarkMode ? CommunitiesAlertMessageCSS.darkModeContainer : ""
      }`}
      ref={wrapperRef}
    >
      <p>
        Are you sure you wish to {message} this {isReply ? "reply" : "post"}?
      </p>
      <div className={CommunitiesAlertMessageCSS["button-container"]}>
        <button
          onClick={() => handleDecision(message)}
          className={`${CommunitiesAlertMessageCSS["button-red"]} ${
            isDarkMode ? CommunitiesAlertMessageCSS.darkModeContainer : ""
          }`}
        >
          Yes
        </button>
        <button
          onClick={() => handleDecision("")}
          className={`${CommunitiesAlertMessageCSS["button-blue"]} ${
            isDarkMode ? CommunitiesAlertMessageCSS.darkModeContainer : ""
          }`}
        >
          No
        </button>
      </div>
    </div>
  );
}

export default CommunitiesAlertMessage;
