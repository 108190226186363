import React, { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { get, post } from "services/service";

import { useSubTab } from "contexts/SubTabContext";
import { useMobile } from "contexts/MobileContext";
import { useAppUse } from "contexts/AppUseContext";
import { marketHoursCheck } from "utils/marketHoursCheck";

import TradeCSS from "./Trade.module.css";
import TradeLeft from "./TradeLeft";
import TradeRight from "./TradeRight";
import TradeConfirmation from "./TradeConfirmation";
import AlertMessage from "components/Shared/AlertMessage";

function Trade(props) {
  const { subTab, setSubTab } = useSubTab();
  const { isMobile } = useMobile();
  const { isUsingApp } = useAppUse();

  const [message, setMessage] = useState(null);
  const [marketStatus, setMarketStatus] = useState({
    isMarketOpen: false,
    remainingTime: "",
  });
  const [tempTrade, setTempTrade] = useState({
    placeholder: null,
    name: null,
    action: "Buy",
    orderType: "Market",
    amount: "",
    assetType: null,
    sector: null,
    industry: null,
    accountId: null,
    bought: {
      user: false,
      community: false,
    },
    isUser: null,
  });
  const [loading, setLoading] = useState({
    main: true,
    search: false,
    button: false,
    price: false,
  });
  const [portfoliosLedgers, setPortfoliosLedgers] = useState({
    user: {
      portfolios: [],
      ledgers: [],
    },
    communities: {
      portfolios: [],
      ledgers: [],
    },
  });
  const [currentAccount, setCurrentAccount] = useState({
    portfolio: {},
    ledger: {},
    position: [],
    filteredLedger: [],
  });
  const [tickerData, setTickerData] = useState({
    placeholderStats: {},
    historicalData: [],
    timespan: "1d",
  });

  const navigate = useNavigate();

  /// Initial Load ///
  const updateMarketStatus = () => {
    const marketStatusData = marketHoursCheck();
    setMarketStatus(marketStatusData);
    return marketStatusData;
  };

  let isFetching = false;
  async function fetchPortfoliosLedgers(accountId) {
    if (isFetching) {
      return;
    } else {
      isFetching = true;
      try {
        const response = await get(
          `/portfolio/my-portfolio-ledger?skip=${true}`
        );
        const response2 = await get(
          `/communities/communities-portfolios-ledgers?skip=${true}`
        );
        setPortfoliosLedgers({
          user: {
            portfolios: response.data.portfolios,
            ledgers: response.data.ledgers,
          },
          communities: {
            portfolios: response2.data.portfolios,
            ledgers: response2.data.ledgers,
          },
        });
        if (!accountId) {
          setTempTrade((prevState) => ({
            ...prevState,
            accountId: response.data.portfolios[0]._id,
          }));
        }
      } catch (err) {
        console.log(err.message);
      }
    }
  }

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    } else {
      try {
        updateMarketStatus();
        const savedQuery = JSON.parse(localStorage.getItem("tempTrade"));
        if (savedQuery && savedQuery.placeholder) {
          setTempTrade({
            placeholder: savedQuery.placeholder,
            name: savedQuery.name,
            action: savedQuery.action,
            orderType: savedQuery.orderType,
            amount: savedQuery.amount,
            assetType: savedQuery.assetType,
            sector: savedQuery.sector,
            industry: savedQuery.industry,
            accountId: savedQuery.accountId,
            bought: {
              user: savedQuery.bought.user,
              community: savedQuery.bought.community,
            },
            isUser: savedQuery.isUser,
          });
          fetchPortfoliosLedgers(savedQuery.accountId);
        } else {
          fetchPortfoliosLedgers();
        }
      } catch (err) {
        console.log(err.message);
      } finally {
        setLoading((prevState) => ({
          ...prevState,
          main: false,
        }));
      }
    }
  }, []);
  //////

  /// Fetch data in intervals ///
  // const getLatestPrice = useCallback(async () => {
  //   try {
  //     console.log("weee");
  //     const response = await get(`/data/watchlist/${tempTrade.placeholder}`);
  //     setTickerData((prevState) => ({
  //       ...prevState,
  //       placeholderStats: response.data.body[0],
  //     }));
  //   } catch (err) {
  //     console.log(err.message);
  //     throw err;
  //   }
  // }, [tempTrade.placeholder]);

  // const [isLoading, setIsLoading] = useState(false);

  const getLatestPrice = async (ticker) => {
    try {
      setLoading((prevState) => ({
        ...prevState,
        price: true,
      }));
      const response = await get(`/data/watchlist/${ticker}`);
      setTickerData((prevState) => ({
        ...prevState,
        placeholderStats: response.data.body[0],
      }));
    } catch (err) {
      console.log(err.message);
      throw err;
    } finally {
      setLoading((prevState) => ({
        ...prevState,
        price: false,
      }));
    }
  };

  const prevPlaceholder = useRef(tempTrade.placeholder);

  useEffect(() => {
    if (tempTrade.placeholder !== prevPlaceholder.current) {
      prevPlaceholder.current = tempTrade.placeholder;
      setLoading((prevState) => ({
        ...prevState,
        search: true,
      }));
      getLatestPrice(tempTrade.placeholder)
        .finally(() =>
          setLoading((prevState) => ({
            ...prevState,
            search: false,
          }))
        )
        .catch(() =>
          setLoading((prevState) => ({
            ...prevState,
            search: false,
          }))
        );
    }

    const interval = setInterval(() => {
      if (
        !loading.main &&
        !loading.price &&
        isUsingApp &&
        tempTrade.placeholder &&
        ((tickerData.placeholderStats.quoteType &&
          updateMarketStatus().isMarketOpen) ||
          (tickerData.placeholderStats.quoteType &&
            tickerData.placeholderStats.quoteType === "CRYPTOCURRENCY"))
      ) {
        getLatestPrice(tempTrade.placeholder);
      }
    }, 20000);

    return () => clearInterval(interval);
  }, [
    loading,
    isUsingApp,
    tempTrade.placeholder,
    tickerData.placeholderStats.quoteType,
  ]);

  //////

  /// Update Local Storage ///
  useEffect(() => {
    if (!loading.main && tempTrade.accountId) {
      //Save the current trade query to local storage only if it is different from the previous one
      localStorage.setItem("tempTrade", JSON.stringify(tempTrade));
    }
  }, [loading.main, tempTrade]);
  //////

  async function placeOrder() {
    if (
      !tempTrade.placeholder ||
      !Object.keys(tickerData.placeholderStats).length
    ) {
      setMessage("Invalid ticker");
      return;
    }

    if (
      !marketStatus.isMarketOpen &&
      tickerData.placeholderStats.quoteType !== "CRYPTOCURRENCY"
    ) {
      setMessage("Stock & ETF trading is only available during market hours");
      return;
    }

    if (!tempTrade.amount || Math.round(tempTrade.amount * 100) / 100 === 0) {
      setMessage("Please enter a valid quantity");
      return;
    }
    if (!tempTrade.accountId || tempTrade.isUser === null) {
      setMessage("Something went wrong");
      return;
    }
    try {
      setLoading((prevState) => ({
        ...prevState,
        button: true,
      }));
      const tradeData = {
        ticker: tempTrade.placeholder,
        name: tempTrade.name,
        action: tempTrade.action,
        price: tickerData.placeholderStats.regularMarketPrice,
        amount: tempTrade.amount,
        orderType: tempTrade.orderType,
        assetType: tempTrade.assetType,
        sector: tempTrade.sector,
        industry: tempTrade.industry,
        portfolioId: tempTrade.accountId,
        ledgerVersion: currentAccount.ledger.__v,
      };

      if (tempTrade.isUser === true) {
        const placedOrder = await post(`/trade/place-order`, { tradeData });
        if (placedOrder.status === 200) {
          if (placedOrder.data.message) {
            setMessage(placedOrder.data.message);
          }
          console.log(placedOrder.data);
          if (
            "portfolios" in placedOrder.data &&
            "ledgers" in placedOrder.data
          ) {
            setPortfoliosLedgers((prevState) => ({
              ...prevState,
              user: {
                portfolios: placedOrder.data.portfolios,
                ledgers: placedOrder.data.ledgers,
              },
            }));
            setTempTrade((prevState) => ({
              ...prevState,
              bought: {
                ...prevState.bought,
                user: true,
              },
            }));
          }
        }
      } else if (tempTrade.isUser === false) {
        const communityOrder = await post(`/trade/new-community-order`, {
          tradeData,
        });
        if (communityOrder.status === 200) {
          if (communityOrder.data.message) {
            setMessage(communityOrder.data.message);
          }
          if (
            "portfolios" in communityOrder.data &&
            "ledgers" in communityOrder.data
          ) {
            setPortfoliosLedgers((prevState) => ({
              ...prevState,
              communities: {
                portfolios: communityOrder.data.portfolios,
                ledgers: communityOrder.data.ledgers,
              },
            }));
            setTempTrade((prevState) => ({
              ...prevState,
              bought: {
                ...prevState.bought,
                community: true,
              },
            }));
          }
        }
      }
    } catch (err) {
      console.log(err.message);
    } finally {
      setLoading((prevState) => ({
        ...prevState,
        button: false,
      }));
    }
  }
  //////

  /// Verify Search ///
  async function verify(value) {
    if (value.quoteType === "EQUITY") {
      setTempTrade((prevState) => ({
        ...prevState,
        placeholder: value.symbol,
        name: value.longname,
        assetType: value.quoteType,
        sector: value.sector,
        industry: value.industry,
      }));
    } else {
      setTempTrade((prevState) => ({
        ...prevState,
        placeholder: value.symbol,
        name: value.longname,
        assetType: value.quoteType,
        sector: null,
        industry: null,
      }));
    }
  }
  //////

  /// Filter Positions & History ///
  useEffect(() => {
    if (tempTrade.accountId && portfoliosLedgers.user.portfolios.length) {
      let foundPortfolio = portfoliosLedgers.user.portfolios.find(
        (portfolio) => portfolio._id === tempTrade.accountId
      );

      let portfolioIsUser;
      let foundLedger;
      let filteredPosition;
      let filteredLedger;

      if (!foundPortfolio) {
        portfolioIsUser = false;
        foundPortfolio = portfoliosLedgers.communities.portfolios.find(
          (portfolio) => portfolio._id === tempTrade.accountId
        );
        foundLedger = portfoliosLedgers.communities.ledgers.find(
          (ledger) => ledger.portfolioId === tempTrade.accountId
        );
      } else {
        portfolioIsUser = true;
        foundLedger = portfoliosLedgers.user.ledgers.find(
          (ledger) => ledger.portfolioId === tempTrade.accountId
        );
      }

      if (foundPortfolio && tempTrade.placeholder) {
        filteredPosition = foundPortfolio.positions.filter(
          (position) => position.ticker === tempTrade.placeholder
        );
        filteredLedger = foundLedger.history.filter(
          (entry) => entry.ticker === tempTrade.placeholder
        );
      }

      setTempTrade((prevState) => ({
        ...prevState,
        isUser: portfolioIsUser,
      }));

      setCurrentAccount({
        portfolio: foundPortfolio ? foundPortfolio : {},
        ledger: foundLedger ? foundLedger : {},
        position: filteredPosition ? filteredPosition : [],
        filteredLedger: filteredLedger ? filteredLedger : [],
        // filteredLedger: foundLedger ? foundLedger : [],
      });
    }
  }, [tempTrade.accountId, portfoliosLedgers, tempTrade.placeholder]);
  //////

  /// Fetch Ticker Historical Data ///
  useEffect(() => {
    if (tempTrade.placeholder && tickerData.timespan) {
      getHistory(tempTrade.placeholder, tickerData.timespan);
    }
  }, [tempTrade.placeholder, tickerData.timespan]);

  async function getHistory(ticker, timespan) {
    if (ticker) {
      try {
        const response = await get(`/data/${ticker}/${timespan}`);
        setTickerData((prevState) => ({
          ...prevState,
          historicalData: response.data,
        }));
      } catch (err) {
        console.log(err.message);
      }
    }
  }

  const handleTimespanChange = (newTimespan) => {
    setTickerData((prevState) => ({
      ...prevState,
      timespan: newTimespan,
    }));
  };
  //////

  /// Update User Actions ///
  const handleAmountChange = (value) => {
    // Parse the input value as a float
    const parsedValue = parseFloat(value);

    // Check if the parsed value is a valid number
    if (!isNaN(parsedValue)) {
      // Get the absolute value of the parsed number
      const absValue = Math.abs(parsedValue);

      // Limit the value to 2 decimal places
      const roundedValue = parseFloat(absValue.toFixed(2));
      if (roundedValue <= 1000000000) {
        setTempTrade((prevState) => ({
          ...prevState,
          amount: roundedValue,
        }));
      }
    } else {
      setTempTrade((prevState) => ({
        ...prevState,
        amount: "",
      }));
    }
  };

  const handleActionChange = (value) => {
    setTempTrade((prevState) => ({
      ...prevState,
      action: value,
    }));
  };

  const handleAccountChange = (e) => {
    setTempTrade((prevState) => ({
      ...prevState,
      accountId: e.target.value,
    }));
  };

  const handleOpenAlert = () => {
    setMessage(null);
  };
  //////

  return (
    <div className={TradeCSS.trade}>
      {!isMobile &&
        marketStatus.remainingTime.length > 0 &&
        !loading.main &&
        Object.keys(currentAccount.portfolio).length > 0 &&
        portfoliosLedgers && (
          <TradeLeft
            marketStatus={marketStatus}
            tempTrade={tempTrade}
            placeholderStats={tickerData.placeholderStats}
            verify={verify}
            placeOrder={placeOrder}
            handleAmountChange={handleAmountChange}
            handleActionChange={handleActionChange}
            handleAccountChange={handleAccountChange}
            portfoliosLedgers={portfoliosLedgers}
            currentAccount={currentAccount}
            loading={loading}
          />
        )}
      {!isMobile &&
        !loading.main &&
        !loading.search &&
        Object.keys(tickerData.placeholderStats).length > 0 &&
        Object.keys(currentAccount.portfolio).length > 0 && (
          <TradeRight
            placeholderStats={tickerData.placeholderStats}
            currentAccount={currentAccount}
            tickerData={tickerData}
            handleTimespanChange={handleTimespanChange}
            loading={loading}
          />
        )}

      {isMobile &&
        subTab &&
        marketStatus.remainingTime.length > 0 &&
        !loading.main &&
        Object.keys(currentAccount.portfolio).length > 0 &&
        portfoliosLedgers && (
          <TradeLeft
            marketStatus={marketStatus}
            tempTrade={tempTrade}
            placeholderStats={tickerData.placeholderStats}
            verify={verify}
            placeOrder={placeOrder}
            handleAmountChange={handleAmountChange}
            handleActionChange={handleActionChange}
            handleAccountChange={handleAccountChange}
            portfoliosLedgers={portfoliosLedgers}
            currentAccount={currentAccount}
            loading={loading}
          />
        )}
      {isMobile &&
        !subTab &&
        !loading.main &&
        !loading.search &&
        Object.keys(tickerData.placeholderStats).length > 0 &&
        Object.keys(currentAccount.portfolio).length > 0 && (
          <TradeRight
            placeholderStats={tickerData.placeholderStats}
            currentAccount={currentAccount}
            tickerData={tickerData}
            handleTimespanChange={handleTimespanChange}
            loading={loading}
          />
        )}

      {message && <div className="faded-background"></div>}
      {message && (
        <AlertMessage message={message} handleOpenAlert={handleOpenAlert} />
      )}
    </div>
  );
}

export default Trade;
