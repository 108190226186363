import React, { useEffect, useState, useRef } from "react";
import SearchbarCSS from "./Searchbar.module.css";
import { get } from "services/service";
import { useDarkMode } from "contexts/DarkModeContext";
import { useColorPreference } from "contexts/ColorPreferenceContext";
import { createCalculatePriceColor } from "utils/colorUtils";
import { formatNumber } from "utils/formatNumber";

const useOutsideClick = (callback) => {
  const ref = useRef();

  useEffect(() => {
    const sanitize = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener("click", sanitize);

    return () => {
      document.removeEventListener("click", sanitize);
    };
  }, [ref]);

  return ref;
};

function Searchbar(props) {
  const { loading, verify, placeholder, disablePrice } = props;
  const [text, setText] = useState("");
  const [foundQuotes, setFoundQuotes] = useState({
    symbol: [],
    shortname: [],
  });

  const { isDarkMode } = useDarkMode();
  const { isAlternativeColor } = useColorPreference();
  const calculateColor = createCalculatePriceColor(
    isDarkMode,
    isAlternativeColor
  );
  const positiveColor = calculateColor(true);
  const negativeColor = calculateColor(false);

  const handleClickOutside = () => {
    setText("");
  };

  const ref = useOutsideClick(handleClickOutside);

  function onlySpaces(str) {
    return /^\s*$/.test(str);
  }

  async function findStocks(input) {
    setText(input);
    if (!onlySpaces(input)) {
      try {
        const response = await get(`/search/${input}`);
        setFoundQuotes(response.data);
        return response;
      } catch (err) {
        console.log(err.message);
      }
    }
  }

  const sanitizeEnter = async (event) => {
    if (event.key === "Enter") {
      if (!props.SubmitOnEnter) {
        event.preventDefault();
        if (!onlySpaces(event.target.value)) {
          const upper = event.target.value.toUpperCase();
          if (upper !== placeholder) {
            try {
              const response = await get(`/search/${upper}`);
              if (response.data[0]) {
                verify(response.data[0]);
                setText("");
              }
            } catch (err) {
              console.log(err.message);
            } finally {
              event.target.blur(); // Deselect the input
            }
          } else {
            setText("");
            event.target.blur(); // Deselect the input
          }
        }
      } else {
        if (!onlySpaces(event.target.value)) {
          const upper = event.target.value.toUpperCase();
          if (upper !== placeholder) {
            try {
              const response = await get(`/search/${upper}`);
              if (response.data[0]) {
                verify(response.data[0]);
                setText("");
              }
            } catch (err) {
              console.log(err.message);
            } finally {
              event.target.blur(); // Deselect the input
            }
          } else {
            setText("");
            event.target.blur(); // Deselect the input
          }
        }
      }
    }
  };

  const sanitize = async (value) => {
    if (value !== placeholder) {
      try {
        const response = await get(`/search/${value}`);
        if (response.data[0]) {
          verify(response.data[0]);
          setText("");
        }
      } catch (err) {
        console.log(err.message);
      }
    } else {
      setText("");
    }
  };

  return (
    <div
      className={`${SearchbarCSS["searchbar"]} ${
        isDarkMode ? SearchbarCSS.darkModeContainer : ""
      }`}
    >
      <div className={SearchbarCSS["search-container"]}>
        <div className={SearchbarCSS["search-drop-container"]} ref={ref}>
          <input
            className={`${SearchbarCSS["search"]} ${
              isDarkMode ? SearchbarCSS.darkModeContainer : ""
            }`}
            type="search"
            placeholder={props.placeholder ? props.placeholder : ""}
            value={text}
            onChange={(e) => findStocks(e.target.value)}
            onKeyDown={sanitizeEnter}
            disabled={loading}
          />
          {text && (
            <div
              className={`${SearchbarCSS["dropdown"]} ${
                isDarkMode ? SearchbarCSS.darkModeContainer : ""
              }`}
            >
              {foundQuotes?.length > 0 &&
                foundQuotes.map((index) => {
                  return (
                    <div
                      className={`${SearchbarCSS["dropdown-row"]} ${
                        isDarkMode ? SearchbarCSS.darkModeContainer : ""
                      }`}
                      key={index.symbol}
                      onClick={() => sanitize(index.symbol)}
                    >
                      <div className={SearchbarCSS["dropdown-symbol"]}>
                        {index.symbol}
                      </div>
                      <div className={SearchbarCSS["dropdown-shortname"]}>
                        {index.longname}
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>
      {!loading && props.placeholderStats?.regularMarketChange ? (
        <div className={SearchbarCSS["quick-price"]}>
          <p
            className={`${SearchbarCSS["main-quick-price"]} ${
              isDarkMode ? SearchbarCSS.darkModeContainer : ""
            }`}
          >
            {formatNumber(props.placeholderStats.regularMarketPrice)}
          </p>
          <p
            className={SearchbarCSS["secondary-price"]}
            style={
              props.placeholderStats.regularMarketChange > 0
                ? { color: positiveColor }
                : props.placeholderStats.regularMarketChange < 0
                ? { color: negativeColor }
                : { color: "#757D87" }
            }
          >
            {formatNumber(props.placeholderStats.regularMarketChange)}
          </p>
          <p
            className={SearchbarCSS["secondary-price"]}
            style={
              props.placeholderStats.regularMarketChange > 0
                ? { color: positiveColor }
                : props.placeholderStats.regularMarketChange < 0
                ? { color: negativeColor }
                : { color: "#757D87" }
            }
          >
            ({formatNumber(props.placeholderStats.regularMarketChangePercent)}%)
          </p>
        </div>
      ) : (
        !disablePrice && <div className={SearchbarCSS["quick-price"]}></div>
      )}
    </div>
  );
}

export default Searchbar;
