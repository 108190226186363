import React, { useState, useEffect } from "react";
import SummaryCSS from "./Summary.module.css";
import { get } from "services/service";
import { useDarkMode } from "contexts/DarkModeContext";
import { formatNumber } from "utils/formatNumber";

function Summary(props) {
  const { isDarkMode } = useDarkMode();

  const { quote, beta } = props;

  // const [quote, setQuote] = useState();
  // const [beta, setBeta] = useState();
  // console.log(beta);

  function formatCap(number) {
    if (Math.abs(number) >= 1e12) {
      return (number / 1e12).toFixed(2) + "T";
    } else if (Math.abs(number) >= 1e9) {
      return (number / 1e9).toFixed(2) + "B";
    } else if (Math.abs(number) >= 1e6) {
      return (number / 1e6).toFixed(2) + "M";
    } else {
      return number.toFixed(1);
    }
  }

  function formatDate(unixTimestamp) {
    // Create a new Date object using the Unix timestamp (in milliseconds)
    let date = new Date(unixTimestamp * 1000);

    // Get the month name from the Date object
    let monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let monthName = monthNames[date.getMonth()];

    // Get the day and year components from the Date object
    let day = date.getDate();
    let year = date.getFullYear();

    // Format the date as a string ("Sep 28, 2022")
    let formattedDate = monthName + " " + day + ", " + year;

    return formattedDate;
  }

  // useEffect(() => {
  //   fetchSummary();
  // }, [props.ticker]);

  // useEffect(() => {
  //   setQuote(props.quote);
  // }, [props.quote]);

  return (
    <div
      className={`${SummaryCSS["summary"]} ${
        isDarkMode ? SummaryCSS.darkModeContainer : ""
      }`}
    >
      {quote && (
        <>
          <table className={SummaryCSS["summary-table"]}>
            <tbody>
              <tr>
                <td className={SummaryCSS["td-summary-header"]}>
                  Previous Close
                </td>
                <td className={SummaryCSS["td-summary-data"]}>
                  {quote.regularMarketPreviousClose
                    ? formatNumber(+quote.regularMarketPreviousClose)
                    : "N/A"}
                </td>
              </tr>
              <tr>
                <td className={SummaryCSS["td-summary-header"]}>Open</td>
                <td className={SummaryCSS["td-summary-data"]}>
                  {quote.regularMarketOpen
                    ? formatNumber(+quote.regularMarketOpen)
                    : "N/A"}
                </td>
              </tr>
              <tr>
                <td className={SummaryCSS["td-summary-header"]}>Day Range</td>
                <td className={SummaryCSS["td-summary-data"]}>
                  {quote.regularMarketDayRange
                    ? quote.regularMarketDayRange
                    : "N/A"}
                </td>
              </tr>
              <tr>
                <td className={SummaryCSS["td-summary-header"]}>
                  52 Week Range
                </td>
                <td className={SummaryCSS["td-summary-data"]}>
                  {quote.fiftyTwoWeekRange ? quote.fiftyTwoWeekRange : "N/A"}
                </td>
              </tr>
              <tr>
                <td className={SummaryCSS["td-summary-header"]}>Volume</td>
                <td className={SummaryCSS["td-summary-data"]}>
                  {quote.regularMarketVolume
                    ? quote.regularMarketVolume
                    : "N/A"}
                </td>
              </tr>
              <tr>
                <td className={SummaryCSS["td-summary-header"]}>
                  Average Volume
                </td>
                <td className={SummaryCSS["td-summary-data"]}>
                  {quote.averageDailyVolume3Month
                    ? quote.averageDailyVolume3Month
                    : "N/A"}
                </td>
              </tr>
            </tbody>
          </table>
          <table className={SummaryCSS["summary-table"]}>
            <tbody>
              <tr>
                <td className={SummaryCSS["td-summary-header"]}>Market Cap</td>
                <td className={SummaryCSS["td-summary-data"]}>
                  {quote.marketCap ? formatCap(+quote.marketCap) : "N/A"}
                </td>
              </tr>
              <tr>
                <td className={SummaryCSS["td-summary-header"]}>Beta</td>
                <td className={SummaryCSS["td-summary-data"]}>
                  {beta ? (+beta).toFixed(2) : "N/A"}
                </td>
              </tr>
              <tr>
                <td className={SummaryCSS["td-summary-header"]}>
                  PE Ratio (TTM)
                </td>
                <td className={SummaryCSS["td-summary-data"]}>
                  {quote.trailingPE ? formatNumber(+quote.trailingPE) : "N/A"}
                </td>
              </tr>
              <tr>
                <td className={SummaryCSS["td-summary-header"]}>EPS (TTM)</td>
                <td className={SummaryCSS["td-summary-data"]}>
                  {quote.epsTrailingTwelveMonths
                    ? formatNumber(quote.epsTrailingTwelveMonths)
                    : "N/A"}
                </td>
              </tr>
              <tr>
                <td className={SummaryCSS["td-summary-header"]}>
                  Next Earnings
                </td>
                <td className={SummaryCSS["td-summary-data"]}>
                  {quote.earningsTimestamp
                    ? Math.floor(Date.now() / 1000) > +quote.earningsTimestamp
                      ? `${formatDate(
                          +quote.earningsTimestampStart
                        )} - ${formatDate(+quote.earningsTimestampEnd)}`
                      : formatDate(+quote.earningsTimestamp)
                    : "N/A"}
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}

export default Summary;
