import React, { useState } from "react";

import { useMobile } from "contexts/MobileContext";
import { useDarkMode } from "contexts/DarkModeContext";

import AnalyticsMoreCSS from "./AnalyticsMore.module.css";
import MatrixTable from "./MatrixTable";

function AnalyticsMore(props) {
  const { isMobile } = useMobile();
  const { isDarkMode } = useDarkMode();

  const [currentTable, setCurrentTable] = useState(Object.keys(props.data)[0]);

  const handleTableName = (name) => {
    if (isMobile && name === "Covariance Matrix") {
      return "Cov Matrix";
    } else if (isMobile && name === "Correlation Matrix") {
      return "Corr Matrix";
    } else {
      return name;
    }
  };

  return (
    <div className={AnalyticsMoreCSS["analytics-more"]}>
      <div className={AnalyticsMoreCSS["analytics-stock-header-container"]}>
        {Object.keys(props.data).map((matrix) => (
          <button
            className={AnalyticsMoreCSS["analytics-stock-header"]}
            onClick={() => setCurrentTable(matrix)}
            key={matrix}
            style={{
              color:
                currentTable === matrix
                  ? isDarkMode
                    ? "#387ADB"
                    : "#0B5DD7"
                  : "",
            }}
          >
            {handleTableName(matrix)}
          </button>
        ))}
      </div>
      <div className={AnalyticsMoreCSS["matrix-container"]}>
        {currentTable === "Covariance Matrix" &&
          Object.keys(props.data["Covariance Matrix"]).map((frequency) => (
            <div className={AnalyticsMoreCSS["matrix-individual-container"]}>
              <span
                className={`${AnalyticsMoreCSS["frequency-header"]} ${
                  isDarkMode ? AnalyticsMoreCSS.darkModeContainer : ""
                }`}
              >
                {frequency}
              </span>
              <MatrixTable
                key={frequency}
                data={props.data["Covariance Matrix"][frequency]}
                header={frequency}
              />
            </div>
          ))}
        {currentTable === "Correlation Matrix" && (
          <div className={AnalyticsMoreCSS["matrix-individual-container"]}>
            <MatrixTable data={props.data["Correlation Matrix"]} />
          </div>
        )}
      </div>
    </div>
  );
}

export default AnalyticsMore;
