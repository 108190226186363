import React, { useState } from "react";
import WatchlistCSS from "./Watchlist.module.css";
import Chart from "components/Chart";
import { useDarkMode } from "contexts/DarkModeContext";
import About from "./About";
import Summary from "./Summary";
import News from "./News";

function WatchlistRight(props) {
  const { isDarkMode } = useDarkMode();

  const {
    placeholderStats,
    // placeholder,
    // info,
    // news,
    historicalData,
    timespan,
    handleTimespanChange,
    // loadingA,
    loading,
    placeholderInfo,
  } = props;
  // console.log(placeholderInfo);

  // const { info: loadingInfo } = loading;

  const [bottomContent, setBottomContent] = useState("summary");

  return (
    <div className={WatchlistCSS["watchlist-right"]}>
      <Chart
        placeholderStats={placeholderStats}
        historicalData={historicalData}
        timespan={timespan}
        handleTimespanChange={handleTimespanChange}
      />

      <div
        className={`${WatchlistCSS["watchlist-br-content-container"]} ${
          isDarkMode ? WatchlistCSS.darkModeContainer : ""
        }`}
      >
        <div className={WatchlistCSS["watchlist-br-content-button-container"]}>
          <button
            className={`${WatchlistCSS["watchlist-br-content-button"]} ${
              isDarkMode ? WatchlistCSS.darkModeContainer : ""
            }`}
            onClick={() => setBottomContent("about")}
            style={{
              borderColor:
                bottomContent === "about"
                  ? isDarkMode
                    ? "#387ADB"
                    : "#0B5DD7"
                  : "#CED5DE",
            }}
          >
            About
          </button>
          <button
            className={`${WatchlistCSS["watchlist-br-content-button"]} ${
              isDarkMode ? WatchlistCSS.darkModeContainer : ""
            }`}
            onClick={() => setBottomContent("summary")}
            style={{
              borderColor:
                bottomContent === "summary"
                  ? isDarkMode
                    ? "#387ADB"
                    : "#0B5DD7"
                  : "#CED5DE",
            }}
          >
            Summary
          </button>
          <button
            className={`${WatchlistCSS["watchlist-br-content-button"]} ${
              isDarkMode ? WatchlistCSS.darkModeContainer : ""
            }`}
            onClick={() => setBottomContent("news")}
            style={{
              borderColor:
                bottomContent === "news"
                  ? isDarkMode
                    ? "#387ADB"
                    : "#0B5DD7"
                  : "#CED5DE",
            }}
          >
            News
          </button>
        </div>
        {!loading.info ? (
          <>
            {bottomContent === "about" && (
              <About
                info={placeholderInfo.about}
                header={placeholderStats.longName}
              />
            )}
            {bottomContent === "summary" && (
              <Summary quote={placeholderStats} beta={placeholderInfo.beta} />
            )}
            {bottomContent === "news" && <News news={placeholderInfo.news} />}
          </>
        ) : (
          <span className={WatchlistCSS["placeholder-text"]}>Loading...</span>
        )}
      </div>
    </div>
  );
}
export default WatchlistRight;
