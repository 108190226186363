import React from "react";

import AnalyticsCSS from "./Analytics.module.css";
import GeneralReport from "./GeneralReport";
import PortfolioReport from "./PortfolioReport";
import TradingViewWidget from "./TradingViewWidget";

function AnalyticsRight(props) {
  const {
    general,
    current,
    currentReportParams,
    portfolio,
    currentPortfolioParams,
  } = props;

  return (
    <div className={AnalyticsCSS["analytics-right"]}>
      {general && current === "general" && (
        <GeneralReport
          general={general}
          currentReportParams={currentReportParams}
        />
      )}
      {portfolio && current === "portfolio" && (
        <PortfolioReport
          portfolio={portfolio}
          currentPortfolioParams={currentPortfolioParams}
        />
      )}
      {current === "technical" && <TradingViewWidget />}
    </div>
  );
}
export default AnalyticsRight;
