import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { get } from "services/service";

import { useAppUse } from "contexts/AppUseContext";
import { useMobile } from "contexts/MobileContext";
import { useSubTab } from "contexts/SubTabContext";
import { marketHoursCheck } from "utils/marketHoursCheck";

import PortfolioCSS from "./Portfolio.module.css";
import PortfolioLeft from "./PortfolioLeft";
import PortfolioRight from "./PortfolioRight";

function Portfolio() {
  const { isUsingApp } = useAppUse();
  const { isMobile } = useMobile();
  const { subTab } = useSubTab();

  const [tempPortfolio, setTempPortfolio] = useState({
    accountId: null,
    portfolios: [],
    ledgers: [],
    portfolioData: [],
  });
  const [currentAccount, setCurrentAccount] = useState({
    portfolio: {},
    ledger: {},
    portfolioData: {},
  });
  const [hasCrypto, setHasCrypto] = useState(false);
  const [loading, setLoading] = useState({
    main: true,
    price: false,
  });

  const navigate = useNavigate();

  let isFetching = false;
  async function fetchPortfoliosLedgers(accountId) {
    if (isFetching) {
      return;
    } else {
      isFetching = true;
      try {
        setLoading((prevState) => ({
          ...prevState,
          price: true,
        }));
        const response = await get(
          `/portfolio/my-portfolio-ledger?skip=${false}`
        );
        if (!accountId) {
          setTempPortfolio((prevState) => ({
            ...prevState,
            portfolios: response.data.portfolios,
            ledgers: response.data.ledgers,
            portfolioData: response.data.portfolioData,
          }));
        } else {
          setTempPortfolio({
            accountId: accountId,
            portfolios: response.data.portfolios,
            ledgers: response.data.ledgers,
            portfolioData: response.data.portfolioData,
          });
        }
      } catch (err) {
        console.log(err.message);
      } finally {
        setLoading((prevState) => ({
          ...prevState,
          main: false,
          price: false,
        }));
      }
    }
  }

  function hasCryptocurrencyPositions(portfolio) {
    for (const position of portfolio.positions) {
      if (position.assetType === "CRYPTOCURRENCY") {
        return true; // Portfolio has a "CRYPTOCURRENCY" position
      }
    }
    return false; // Portfolio doesn't have "CRYPTOCURRENCY" positions
  }

  const handleAccountChange = (e) => {
    if (tempPortfolio.accountId !== e.target.value) {
      setTempPortfolio((prevState) => ({
        ...prevState,
        accountId: e.target.value,
      }));
    }
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    } else {
      try {
        const savedPortfolio = JSON.parse(
          localStorage.getItem("tempPortfolio")
        );
        let savedTrade = JSON.parse(localStorage.getItem("tempTrade"));
        let bought = true;
        if (
          savedTrade &&
          savedPortfolio &&
          savedPortfolio.accountId &&
          savedPortfolio.portfolioData.length > 0
        ) {
          bought = savedTrade.bought.user;
        } else if (
          !savedTrade &&
          savedPortfolio &&
          savedPortfolio.accountId &&
          savedPortfolio.portfolioData.length > 0
        ) {
          bought = false;
        }
        if (
          savedPortfolio &&
          savedPortfolio.accountId &&
          savedPortfolio.portfolioData.length > 0 &&
          !bought
        ) {
          setTempPortfolio({
            accountId: savedPortfolio.accountId,
            portfolios: savedPortfolio.portfolios,
            ledgers: savedPortfolio.ledgers,
            portfolioData: savedPortfolio.portfolioData,
          });
          setLoading((prevState) => ({
            ...prevState,
            main: false,
          }));
        } else {
          let defaultAccount = null;
          if (savedPortfolio && savedPortfolio.accountId) {
            defaultAccount = savedPortfolio.accountId;
          } else {
            defaultAccount = JSON.parse(localStorage.getItem("tempUser"))
              .preferences.defaultaccount;
          }
          fetchPortfoliosLedgers(defaultAccount).then(() => {
            if (savedTrade) {
              savedTrade.bought.user = false;
              localStorage.setItem("tempTrade", JSON.stringify(savedTrade));
            }
          });
        }
      } catch (err) {
        console.log(err.messsage);
        throw err;
      }
    }
  }, []);

  useEffect(() => {
    if (tempPortfolio.accountId && tempPortfolio.portfolios.length) {
      const foundPortfolio = tempPortfolio.portfolios.find(
        (portfolio) => portfolio._id === tempPortfolio.accountId
      );

      const foundLedger = tempPortfolio.ledgers.find(
        (ledger) => ledger.portfolioId === tempPortfolio.accountId
      );

      const foundPortfolioData = tempPortfolio.portfolioData.find(
        (data) => data.portfolioId === tempPortfolio.accountId
      );

      setCurrentAccount({
        portfolio: foundPortfolio ? foundPortfolio : {},
        ledger: foundLedger ? foundLedger : {},
        portfolioData: foundPortfolioData ? foundPortfolioData : {},
      });
      const hasCryptocurrencyPortfolio =
        foundPortfolio && hasCryptocurrencyPositions(foundPortfolio);
      setHasCrypto(hasCryptocurrencyPortfolio);
    }
  }, [tempPortfolio.accountId, tempPortfolio]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        !loading.main &&
        !loading.price &&
        Object.keys(currentAccount.portfolio).length > 0 &&
        Object.keys(currentAccount.portfolioData).length > 0 &&
        isUsingApp &&
        ((currentAccount && marketHoursCheck().isMarketOpen) ||
          (currentAccount && hasCrypto)) &&
        currentAccount.portfolio.positions.length > 0
      ) {
        fetchPortfoliosLedgers();
      }
    }, 20000);

    return () => clearInterval(interval);
  }, [loading, isUsingApp, currentAccount, hasCrypto]);

  useEffect(() => {
    if (!loading.main && tempPortfolio.accountId) {
      localStorage.setItem("tempPortfolio", JSON.stringify(tempPortfolio));
    }
  }, [loading.main, tempPortfolio]);

  return (
    <div className={PortfolioCSS.portfolio}>
      {!loading.main &&
        !isMobile &&
        tempPortfolio.portfolios.length > 0 &&
        Object.keys(currentAccount.portfolio).length > 0 &&
        Object.keys(currentAccount.portfolioData).length > 0 && (
          <PortfolioLeft
            tempPortfolio={tempPortfolio}
            currentAccount={currentAccount}
            handleAccountChange={handleAccountChange}
          />
        )}
      {!loading.main &&
        !isMobile &&
        tempPortfolio.portfolios.length > 0 &&
        Object.keys(currentAccount.portfolio).length > 0 &&
        Object.keys(currentAccount.portfolioData).length > 0 && (
          <PortfolioRight loading={loading} currentAccount={currentAccount} />
        )}
      {!loading.main &&
        isMobile &&
        subTab &&
        tempPortfolio.portfolios.length > 0 &&
        Object.keys(currentAccount.portfolio).length > 0 &&
        Object.keys(currentAccount.portfolioData).length > 0 && (
          <PortfolioLeft
            tempPortfolio={tempPortfolio}
            currentAccount={currentAccount}
            handleAccountChange={handleAccountChange}
          />
        )}
      {!loading.main &&
        isMobile &&
        !subTab &&
        tempPortfolio.portfolios.length > 0 &&
        Object.keys(currentAccount.portfolio).length > 0 &&
        Object.keys(currentAccount.portfolioData).length > 0 && (
          <PortfolioRight loading={loading} currentAccount={currentAccount} />
        )}
      {loading.main && loading.price && (
        <p className={PortfolioCSS["placeholder-text"]}>Loading...</p>
      )}
    </div>
  );
}

export default Portfolio;
