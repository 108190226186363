import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useMobile } from "contexts/MobileContext";
import { useDarkMode } from "contexts/DarkModeContext";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import CommunitiesCreatePostCSS from "./CommunitiesCreatePost.module.css";

function CommunitiesCreatePost(props) {
  const { isDarkMode } = useDarkMode();
  const { isMobile } = useMobile();

  const { selectedPost, openEditPost, edit, updatePost, updateReply } = props;

  const [title, setTitle] = useState("");
  const [titleStatus, setTitleStatus] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionStatus, setDescriptionStatus] = useState(false);

  const create = async (e) => {
    e.preventDefault();
    // if (localStorage.getItem("username")) {
    try {
      if (!title && openEditPost?.branch !== "reply") {
        setTitleStatus("Please enter a valid title");
      } else if (!description) {
        setDescriptionStatus("Please enter a valid description");
      } else {
        if (edit && openEditPost?.open) {
          if (openEditPost?.branch === "main") {
            const updatedPost = {
              postId: openEditPost.content._id,
              action: "update",
              title: title,
              description: description,
            };
            updatePost(updatedPost);
            openEditPost.open = false;
          } else if (openEditPost?.branch === "reply") {
            const updatedReply = {
              postId: selectedPost._id,
              replyId: openEditPost.content._id,
              action: "update",
              description: description,
            };
            updateReply(updatedReply);
            openEditPost.open = false;
          }
        } else {
          const newPost = {
            communityId: props.currentCommunity.community._id,
            title: title,
            description: description,
          };
          props.newPost(newPost);
          props.handleOpen(false);
        }
      }
    } catch (err) {
      console.log(err.message);
    }
    // } else {
    //   navigate("/");
    // }
  };

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (openEditPost) {
            openEditPost.open = false;
          } else {
            props.handleOpen(false);
          }
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const cleanTitle = (value) => {
    setTitleStatus("");
    if (value.length <= 50) {
      setTitle(value);
    }
  };

  const cleanDescription = (value) => {
    setDescriptionStatus(false);
    if (value.length <= 280) {
      setDescription(value);
    }
  };

  useEffect(() => {
    if (edit && openEditPost?.open) {
      if (openEditPost?.branch === "main") {
        setTitle(openEditPost.content.title);
        setDescription(openEditPost.content.description);
      } else if (openEditPost?.branch === "reply") {
        setTitle("");
        setDescription(openEditPost.content.description);
      }
    }
  }, [openEditPost, edit]);

  return (
    <div
      className={`${CommunitiesCreatePostCSS["communities-create"]} ${
        isDarkMode ? CommunitiesCreatePostCSS.darkModeContainer : ""
      }`}
      style={{
        height: openEditPost?.branch === "reply" ? "" : "",
      }}
      ref={wrapperRef}
    >
      <h4>
        {edit
          ? openEditPost?.branch === "main"
            ? "Edit Post"
            : "Edit Reply"
          : "Create Post"}
      </h4>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: "4.55px", width: "328px" },
          "@media screen and (max-width: 480px)": {
            "& .MuiTextField-root": {
              width: "260px", // Width for screens with max-width 900px or less
            },
          },
        }}
        noValidate
        autoComplete="off"
        onSubmit={create}
      >
        {edit && openEditPost?.branch === "reply" ? (
          ""
        ) : (
          <TextField
            error={titleStatus ? true : false}
            id="first-name"
            type="string"
            label="Title"
            variant="standard"
            style={{
              height: isMobile ? "54px" : "72px",
            }}
            InputLabelProps={{
              shrink: true,
              sx: {
                color: isDarkMode ? "#9CA9B9" : "", // Label color
              },
            }}
            sx={{
              "& .MuiInput-underline:before": {
                borderColor: isDarkMode ? "#9CA9B9 !important" : "",
              },
              "& .MuiInput-underline:hover:before": {
                borderColor: isDarkMode ? "#F0F0F0 !important" : "",
              },
              ".MuiSvgIcon-root": {
                fill: isDarkMode ? "#9CA9B9" : "",
              },
            }}
            InputProps={{
              sx: {
                color: isDarkMode ? "#F0F0F0" : "", // Text color
              },
            }}
            helperText={titleStatus && !isMobile ? titleStatus : " "}
            value={title}
            onChange={(e) => cleanTitle(e.target.value)}
          />
        )}

        <TextField
          id="description"
          label={
            <span className={CommunitiesCreatePostCSS["custom-label-text"]}>
              Description
            </span>
          }
          type="string"
          InputLabelProps={{
            shrink: true,
            sx: {
              color: isDarkMode ? "#9CA9B9" : "", // Label color
            },
          }}
          sx={{
            width: 141,
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: isDarkMode ? "#9CA9B9" : "",
            },
            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: isDarkMode ? "#F0F0F0" : "", // Border color on hover
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "#1976d2", // Border color when focused
              },
            "& .MuiOutlinedInput-root:hover": {
              "& .MuiOutlinedInput-input": {
                color: isDarkMode ? "#F0F0F0" : "", // Text color on hover
              },
            },
          }}
          InputProps={{
            style: {
              height: "86px",
            },
            sx: {
              color: isDarkMode ? "#F0F0F0" : "", // Text color
            },
          }}
          variant="outlined"
          multiline
          rows={3}
          value={description}
          onChange={(e) => cleanDescription(e.target.value)}
          error={descriptionStatus ? true : false}
        />
        <span className={CommunitiesCreatePostCSS["word-count"]}>
          {description.length} / 280
        </span>

        <div className={CommunitiesCreatePostCSS["signup-bottom"]}>
          <button
            type="submit"
            className={`${CommunitiesCreatePostCSS["signup-button"]} ${
              isDarkMode ? CommunitiesCreatePostCSS.darkModeContainer : ""
            }`}
          >
            {edit ? "Edit" : "Post"}
          </button>
        </div>
      </Box>
    </div>
  );
}

export default CommunitiesCreatePost;
